import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import countries from '../../../../../../public/data/countries.json';
import { addIntakeCountriesOfCitizenship, addAdminIntakeFormData } from '../../../actions/intakeFormActions';

export default function IntakeCountriesOfCitizenship(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Countries of Citizenship',
    heading: 'What are your countries of citizenship?',
    subheading: 'Select all countries of which you are a citizen.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="countries_of_citizenship").required ?? false,
  };

  useEffect(
    () => {
      if(props?.isAdmin) {
        const initialCountries = adminIntakeFormData?.countries_of_citizenship ? countries.filter(country => adminIntakeFormData?.countries_of_citizenship?.includes(country.value)) : [];
        setValue(initialCountries);
      } else {
        const initialCountries = countries.filter(country => intake.countries_of_citizenship.includes(country.value));
        setValue(initialCountries);
      }

      setDisabled(intake.ssoTraveler && intake.readonly.includes('countries_of_citizenship'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    
    setError("");
    if (!disabled && value.length === 0 && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select at least one');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      const countries = value.map(country => country.value.trim());
      dispatch(addAdminIntakeFormData( "countries_of_citizenship", countries));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
      const countries = value.map(country => country.value.trim());

      dispatch(addIntakeCountriesOfCitizenship(userId, { countries_of_citizenship: countries }));
    }
  };

  const handleOnChange = event => {
    if (event) {
      setValue(event);
    } else {
      setValue([]);
    }
  };

  return (
    <TravelerFormQuestionContainer 
      handleBackStep={()=>props.handleAdminBackStep()} 
      isAdmin={props.isAdmin} 
      question={question} 
      handleNext={handleNext} 
      adminIntakeStep={props.adminIntakeStep}
      disabled={disabled} canBeSynced>
      <SelectInput
        value={value}
        defaultValue={value}
        id="countries_of_citizenship"
        label="Countries of Citizenship"
        placeholder="Select country"
        options={countries}
        error={error}
        disabled={disabled}
        isMulti
        required={question.isRequired}
        handleOnChange={handleOnChange}
      />
    </TravelerFormQuestionContainer>
  );
}
