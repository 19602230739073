import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextInput from '../../base/inputs/TextInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import { addIntakeHomeInstitution, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import useMediaQuery from '../../../../../hooks/useMediaQuery';

export default function IntakeHomeInstitution(props) {
  const dispatch = useDispatch();
  const { isMobile, isDesktop, isTablet } = useMediaQuery();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Home Institution',
    heading: 'What institution are you currently enrolled in?',
    subheading: 'Type in your home institution.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="home_institution").required ?? false,
  };
  
  useEffect(
    () => {
      if(props?.isAdmin) {
        setValue(adminIntakeFormData.school_name);
      } else {
        setValue(intake.school_name);
      }
     
      setDisabled(intake.ssoTraveler && intake.readonly.includes('school_name'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    
    setError("");
    if (!disabled && !value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please enter your home campus.');
      },0)  
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData("school_name", value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
      dispatch(addIntakeHomeInstitution(userId, { school_name: value }));
    }
  };

  const customInputStyle = {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "4px",
    border: "1px solid var(--Gray-400, #CED4DA)",
    background: disabled ? "#eeeeee" : "var(--Gray-White, #FFF)",
  }
  const customInputContainerStyle = {
    width : isDesktop ? "100%" : "100%",
    marginTop:"1.375rem",
  }

  return (
    <TravelerFormQuestionContainer 
      isAdmin={props.isAdmin} 
      question={question} 
      handleBackStep={()=>props.handleAdminBackStep()} 
      handleNext={handleNext} 
      adminIntakeStep={props.adminIntakeStep}
      disabled={disabled} 
      canBeSynced>
      <TextInput
        value={value}
        id="school_name"
        label="Home Campus"
        placeholder={"Enter your home campus"}
        error={error}
        disabled={disabled}
        required={question.isRequired}
        handleOnChange={event => {
          setValue(event.target.value);
        }}
        customInputStyle = {customInputStyle}
        customInputContainerStyle = {customInputContainerStyle}
      />
    </TravelerFormQuestionContainer>
  );
}
