import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import phoneInputStyles from 'react-phone-input-2/lib/plain.css';

import InputError from './InputError';
import LockIcon from '../../icons/LockIcon';
import SyncIcon from '../../icons/SyncIcon';
import InputErrorRequired from './InputErrorRequired';

import { buttonFocus } from '../../../utils/shared/focusStyles';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputLabel = styled.label`
  margin-bottom: 0.5rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  line-height: 120%;
  letter-spacing: 0.015em;

  svg {
    margin-top: -2px;
    margin-left: 7px;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .react-tel-input .flag-dropdown {
    border: none !important;
    border-right: 1px solid #bbbbbb !important;
  }

  .react-tel-input .selected-flag {
    ${buttonFocus};
  }

  .form-control {
    &:focus {
      outline: none !important;
      border: 1px solid #5a869b !important;
      box-shadow: 0px 5px 10px rgba(94, 140, 190, 0.25) !important;
    }

    &:disabled {
      background-color: #eeeeee !important;
      cursor: not-allowed !important;
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 0.8rem;
`;

function ProfilePhoneNumberInput({
  id,
  error,
  label,
  value,
  locked,
  synced,
  required,
  placeholder,
  handleOnChange,
  initialCountry,
}) {
  const getInputStyles = () => ({
    boxSizing: 'border-box',
    display: 'block',
    width: '100%',
    height: '2.75rem',
    backgroundColor: '#ffffff',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: error ? '#8b1313' : '#bbbbbb',
    borderRadius: '2px',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '0.875rem',
    color: synced || locked ? '#555555' : '#373737',
    lineHeight: '120%',
    letterSpacing: '0.015em',
  });

  const title = synced ? 'Synced Field' : locked ? 'Locked Field' : null;

  useEffect(() => {
    let element = document?.getElementById(id);
    
    if(element){
      element.setAttribute("autocomplete", "off"); 
    }

  }, []); 


  return (
    <InputContainer>
      <InputLabel htmlFor={id}>
        {label}
        {required && error && <InputErrorRequired />}
        {synced && <SyncIcon />}
      </InputLabel>
      <InputWrapper title={title}>
        <PhoneInput
          inputProps={{ id }}
          value={value}
          name={id}
          placeholder={placeholder}
          country={initialCountry}
          disabled={synced || locked}
          onChange={handleOnChange}
          inputStyle={getInputStyles()}
          
        />
        {locked && (
          <IconContainer>
            <LockIcon />
          </IconContainer>
        )}
      </InputWrapper>
      {error && <InputError message={error} />}
    </InputContainer>
  );
}

ProfilePhoneNumberInput.propTypes = {
  locked: PropTypes.bool,
  synced: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  initialCountry: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

ProfilePhoneNumberInput.defaultProps = {
  error: '',
  locked: false,
  synced: false,
  required: false,
  placeholder: '',
  initialCountry: 'us',
  handleOnChange: () => {},
};

export default withStyles(phoneInputStyles)(ProfilePhoneNumberInput);
