import PropTypes from 'prop-types';
import React, {useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IntakeWelcome from './steps/IntakeWelcome';
import TravelerFormContainer from '../base/TravelerFormContainer';

import userId from '../../../../shared/helpers/userId';
import { fetchIntake } from '../../actions/intakeFormActions';
//import { intakeStepsComponentMap } from '../../utils/stepComponentMaps';
import { getIntakeComponent } from '../../utils/stepComponentMaps';

export default function TravelerIntakeForm({ step, isAdmin }) {
  const dispatch = useDispatch();
  const intakeQuestions = useSelector(state=>state.intakeForm.intakeQuestions);
  const [intakeStepsComponentMap, setIntakeStepsComponentMap] = useState({});
  const [adminIntakeStep, setAdminIntakeStep] = useState(1);
  //const intakeStepsComponentMap = getIntakeComponent(intakeQuestions);
  useEffect(() => {
    if (intakeQuestions && intakeQuestions.length > 0) {
      const intakeQuestionObject = getIntakeComponent(intakeQuestions);
      setIntakeStepsComponentMap(intakeQuestionObject);
    }
  }, [intakeQuestions]);

  useEffect(() => {
    dispatch(fetchIntake(userId));
  }, [dispatch]);
  
  let StepComponent = null;

  if (step && !isAdmin) {
    StepComponent = intakeStepsComponentMap[Number(step)];
  } 

  if(isAdmin) {
    StepComponent = intakeStepsComponentMap[Number(adminIntakeStep)];
  }

  useEffect(()=>{
    if(isAdmin){
      StepComponent = intakeStepsComponentMap[Number(adminIntakeStep)];
    }
  },[adminIntakeStep, intakeStepsComponentMap, isAdmin])

  if (!intakeQuestions || Object.keys(intakeStepsComponentMap).length === 0) {
    return <div></div>;
  }

  return <TravelerFormContainer step={isAdmin ? adminIntakeStep : step} isAdmin={isAdmin}  >{step ? <StepComponent isAdmin={isAdmin} adminIntakeStep={adminIntakeStep} handleAdminBackStep={()=>setAdminIntakeStep(adminIntakeStep-1)}  handleAdminStep={()=>setAdminIntakeStep(adminIntakeStep+1)} /> : <IntakeWelcome />}</TravelerFormContainer>;
}

// TravelerIntakeForm.propTypes = {
//   step: PropTypes.string,
//   isAdmin: PropTypes.bool,
// };

// TravelerIntakeForm.defaultProps = {
//   step: '',
//   isAdmin:false
// };
