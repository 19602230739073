import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../base/inputs/TextInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeProfile, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import useMediaQuery from '../../../../../hooks/useMediaQuery';


export default function IntakeFirstName(props) {
  const dispatch = useDispatch();
  const { isMobile, isDesktop, isTablet } = useMediaQuery();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  
  const question = {
    overline: 'First Name',
    heading: `What is your first name?`,
    subheading: 'Type in your legal first name only.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="first_name").required ?? false,
  };

  useEffect(
    () => {
      if(props?.isAdmin) {
        setValue(adminIntakeFormData.first_name);
      } else {
        setValue(intake.first_name);
      }
      setDisabled(intake.ssoTraveler && intake.readonly.includes('first_name'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    setError("");
    if (!disabled && !value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please enter your first name.'); 
      },0)
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "first_name", value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
      dispatch(addIntakeProfile(userId, { first_name: value, last_name: intake.last_name || '' }));
    }
  };
 
  const customInputStyle = {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "4px",
    border: "1px solid var(--Gray-400, #CED4DA)",
    background: disabled ? "#eeeeee" : "var(--Gray-White, #FFF)",
  }
  const customInputContainerStyle = {
    width : isDesktop ? "100%" : "100%",
    marginTop:"1.375rem",
  }

  return (
    <TravelerFormQuestionContainer
      question={question}
      handleNext={handleNext}
      showBack={false}
      disabled={disabled}
      canBeSynced
      isAdmin={props.isAdmin}
      handleBackStep={()=>props.handleAdminBackStep()}
      adminIntakeStep={props.adminIntakeStep}
      
    >
     
      <TextInput
        value={value}
        id="first_name"
        label="First Name"
        placeholder="Enter your first name"
        error={error}
        disabled={disabled}
        required = {question.isRequired}
        handleOnChange={event => {
          setValue(event.target.value);
        }}
        customInputStyle = {customInputStyle}
        customInputContainerStyle = {customInputContainerStyle}

      />
    </TravelerFormQuestionContainer>
  );
}
