import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextInput from '../../base/inputs/TextInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import { addIntakeProfile, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import useMediaQuery from '../../../../../hooks/useMediaQuery';

export default function IntakeLastName(props) {
  const dispatch = useDispatch();
  const { isMobile, isDesktop, isTablet } = useMediaQuery();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Last Name',
    heading: 'What is your last name?',
    subheading: 'Type in your legal last name / surname only.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="last_name").required ?? false,
  };
  
  useEffect(
    () => {
      if(props?.isAdmin) {
        setValue(adminIntakeFormData.last_name);
      } else {
        setValue(intake.last_name);
      }
      
      setDisabled(intake.ssoTraveler && intake.readonly.includes('last_name'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    
    setError("");
    if (!disabled && !value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please enter your last name.');
      },0)  
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "last_name", value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
      dispatch(addIntakeProfile(userId, { first_name: intake.first_name, last_name: value }));
    }
  };

  const customInputStyle = {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "4px",
    border: "1px solid var(--Gray-400, #CED4DA)",
    background: disabled ? "#eeeeee" : "var(--Gray-White, #FFF)",
  }
  const customInputContainerStyle = {
    width : isDesktop ? "100%" : "100%",
    marginTop:"1.375rem",
  }

  return (
    <TravelerFormQuestionContainer 
      isAdmin={props.isAdmin}
      question={question} 
      handleNext={handleNext} 
      handleBackStep={()=>props.handleAdminBackStep()}
      adminIntakeStep={props.adminIntakeStep}
      disabled={disabled} 
      canBeSynced
    >
      <TextInput
        value={value}
        id="last_name"
        label="Last Name"
        placeholder="Enter your last name"
        error={error}
        disabled={disabled}
        required={question.isRequired}
        handleOnChange={event => {
          setValue(event.target.value);
        }}
        customInputStyle = {customInputStyle}
        customInputContainerStyle = {customInputContainerStyle}
      />
    </TravelerFormQuestionContainer>
  );
}
