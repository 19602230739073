import React, { useState, useEffect,useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

//import Modal from '../../structure/Modal';
import Modal from 'react-responsive-modal';
import modalCss from 'react-responsive-modal/styles.css';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CloseIcon from '../../../../travelerProfile/components/icons/CloseIcon';
import SubmissionSuccess from './SubmissionSuccess';

import { ModalBody, ModalButton, ModalFooter } from '../../structure/Modal.styled';
import { toggleLearnMoreModal } from '../../../../../actions/programBrochure';
import { requestToApply } from '../../../../../actions/programBrochureActions';
import ScreenReaderText from '../../../../travelerProfile/components/base/ScreenReaderText.styled';
const LearnMoreContainer = styled.div`
  text-align: center;

  span {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3125rem;
    line-height: 120%;
    color: #373737;
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 1rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.0025em;
    color: #000000;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  padding: 25px 25px;

`;
const Button = styled.button`
   outline: none;
    border: none;
    padding: 0;
    background: none;
    &:focus-visible {
      outline: 2px solid #255a94;
      outline-offset: 1px;
    }
`;

const ModalContainer = styled.div`
    &:focus-visible {
      outline: none;
      border: none;
    }
`;

export const enhance = compose(withStyles(modalCss));

function LearnMoreModal({modalId}) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const {
    meta: { id },
    showLearnMoreModal: isOpen,
  } = useSelector(state => state.programBrochure);
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const close = () => {
    hasBeenSubmitted ? setHasBeenSubmitted(false) : null;
    dispatch(toggleLearnMoreModal());
  };

  const handleSubmit = () => {
    dispatch(requestToApply(id));
    setHasBeenSubmitted(true);
    modalRef.current.focus();
  };
  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Modal
      blockScroll={false}
      open={isOpen}
      onClose={close}
      center
      closeOnEsc
      closeOnOverlayClick={false}
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '720px',
        },
      }}
    >
    
    <ModalContainer
      ref={modalRef}
      role="dialog"
      aria-labelledby={modalId ? modalId  : "modal-title"}
      tabIndex="-1">
      <Header>
        <ScreenReaderText id={modalId ? modalId  : "modal-title"}>Learn More Request</ScreenReaderText>
        <Button onClick={close} aria-label="close">
          <CloseIcon fillColor={`#9FABAE`} />
        </Button>
      </Header>
      <ModalBody style={{borderRadius:"10px",paddingTop:"0px"}}>
        <LearnMoreContainer tabIndex={0} style={{marginTop:"40px"}}>
          {!hasBeenSubmitted && (
            <>
              <span>Learn More Request</span>
              <p>
                There are currently no open terms for this {programAlias.toLowerCase()}, but it is possible that future
                terms are available.
              </p>
              <p>
                Request to learn more to let this {programAlias.toLowerCase()} provider know you are interested in
                taking the next step.
              </p>
              <p>
                When you request to learn more, a message will be sent letting the contacts for this{' '}
                {programAlias.toLowerCase()} know you are ready to get started.
              </p>
            </>
          )}
          {hasBeenSubmitted && 
            <SubmissionSuccess />
          }
        </LearnMoreContainer>
      </ModalBody>
      </ModalContainer>
      <ModalFooter style={{borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px"}}>
        {!hasBeenSubmitted && (
          <>
            <ModalButton type="button" className="secondary" onClick={close}>
              Cancel
            </ModalButton>
            <ModalButton type="button" className="primary" onClick={handleSubmit}>
              Request to Learn More
            </ModalButton>
          </>
        )}
        {hasBeenSubmitted && (
          <ModalButton type="button" className="primary" onClick={close}>
            Okay
          </ModalButton>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default enhance(LearnMoreModal);