import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeQuestions, addAdminIntakeFormData } from '../../../actions/intakeFormActions';

export default function IntakeHonorStatus(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Honors Program',
    heading: 'Are you a part of an honors program?',
    subheading: 'Select one option.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="honors_program").required ?? false,
  };

  const options = [
    {label:"Yes",value:"yes"},
    {label:"No",value:"no"},
  ]

  useEffect(
    () => {
      if(props?.isAdmin) {
        const initialValue = options.find(status => adminIntakeFormData?.honors_status===status.value);
        setValue(initialValue);
      } else {
        const initialValue = options.find(status => intake.honors_status===status.value);
        setValue(initialValue);
      }
      setDisabled(intake.ssoTraveler && intake.readonly.includes('honors_status'));
    },
    [intake, adminIntakeFormData]
  );

  const handleNext = () => {
   
    setError("");
    if (!disabled && !value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select an option');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "honors_status", value?.value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
       dispatch(addIntakeQuestions(userId, { honors_status: value?.value }, "honors_status"));
    }
  };

  const handleOnChange = event => {
    if (event) {
      setValue(event);
    } else {
      setValue([]);
    }
  };

  return (
    <TravelerFormQuestionContainer adminIntakeStep={props.adminIntakeStep} isAdmin={props.isAdmin} question={question} handleBackStep={()=>props.handleAdminBackStep()} handleNext={handleNext} disabled={disabled} canBeSynced>
      <SelectInput
        value={value}
        defaultValue={value}
        id="honors_status"
        label="Honors Status"
        placeholder="Select honors status"
        options={options}
        error={error}
        disabled={disabled}
        required={question.isRequired}
        handleOnChange={handleOnChange}
      />
    </TravelerFormQuestionContainer>
  );
}
