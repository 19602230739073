import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import Arrow from '../../../public/img/arrow.svg';
import sReactPDF from './reactPDF.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CloseButton from '../CloseButton/CloseButton.js';
import CloseIcon from '../../sites/travelerProfile/components/icons/CloseIcon.js';
import ScreenReaderText from '../../sites/travelerProfile/components/base/ScreenReaderText.styled.js';

function ReactPDF(props) {
  const modalRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(true);
  const background = show ? sReactPDF.backgroundShow : sReactPDF.backgroundNone;
  const showHideClassName = show ? sReactPDF.modalDisplay : sReactPDF.modalDisplayNone;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (props.show && modalRef.current) {
      modalRef.current.focus();
    }
  }, [props.show]); 

  return (
    <div id={background}>
      <div id={showHideClassName} className={sReactPDF.modal}>
      <div
      ref={modalRef}
      role="dialog"
      tabIndex="-1">
        <div className={sReactPDF.header}>
        <ScreenReaderText id="modal-title">Preview Attachment Modal</ScreenReaderText>
          <p>Preview</p>
          {/* <CloseButton
            isOnModal={true}
            clickHandler={() => {
              props.handleClose();
              setShow(false);
            }}
          /> */}
          <button type="button" className={sReactPDF.closeButton} 
            onClick={()=>{
                props.handleClose();
                setShow(false);
              }
            }
          >
            <CloseIcon fillColor={`#9FABAE`} />
          </button>
        </div>
        <div className={sReactPDF['react-pdf']}>
          <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} height={560} className={sReactPDF.pdfPage} />
          </Document>
        </div>
        </div>
        <div className={sReactPDF.footer}>
          <p className={sReactPDF.pageContainer}>
            {pageNumber === 1 || numPages === 1 ? null : (
              <img
                src={Arrow}
                className={sReactPDF.backArrow}
                onClick={() => {
                  setPageNumber(pageNumber - 1);
                }}
              />
            )}
            Page {pageNumber} of {numPages}
            {pageNumber === numPages ? null : (
              <img
                src={Arrow}
                className={sReactPDF.forwardArrow}
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default withStyles(sReactPDF)(ReactPDF);
