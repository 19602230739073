import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../base/inputs/TextInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeQuestions, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
export default function IntakeComulativeGPA(props) {
  const dispatch = useDispatch();
  const { isMobile, isDesktop, isTablet } = useMediaQuery();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState("");
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Cumulative GPA',
    heading: 'What is your cumulative GPA?',
    subheading: 'Enter your cumulative GPA.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="cumulative_gpa").required ?? false
  };

  useEffect(
    () => {
      if(props?.isAdmin) {
        setValue(adminIntakeFormData?.cumulative_gpa);
      } else {
        setValue(intake.cumulative_gpa);
      }
      setDisabled(intake.ssoTraveler && intake.readonly.includes('cumulative_gpa'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
   
    setError("");
    if (!disabled && !value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please enter your cumulative GPA');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    } else if (!disabled && value && !/^\d+(\.\d{1,2})?$/.test(value)) {  
      setTimeout(() => {
        setError('GPA must be in a valid format (e.g. 3.14, 4.0, or 2)');
      }, 0);
      
      if (props.isAdmin) {
        return;
      }
      throw new Error();
    } else if (!disabled && value && parseFloat(value) <= 0) { 
      setTimeout(() => {
        setError('GPA must be a number greater than 0');
      }, 0);
    
      if (props.isAdmin) {
        return;
      }
      throw new Error();
    }

    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData("cumulative_gpa", value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
       dispatch(addIntakeQuestions(userId, { cumulative_gpa: value },"cumulative_gpa"));
    }
  };

  const customInputStyle = {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "4px",
    border: "1px solid var(--Gray-400, #CED4DA)",
    background: disabled ? "#eeeeee" : "var(--Gray-White, #FFF)",
  }
  const customInputContainerStyle = {
    width : isDesktop ? "100%" : "100%",
    marginTop:"1.375rem",
  }

  return (
    <TravelerFormQuestionContainer 
      isAdmin={props.isAdmin} 
      question={question} 
      handleBackStep={()=>props.handleAdminBackStep()} 
      adminIntakeStep={props.adminIntakeStep}
      handleNext={handleNext} 
      disabled={disabled} canBeSynced>
      <TextInput
        value={value}
        id="cumulative_gpa"
        label="Cumulative GPA"
        placeholder="Enter cumulative GPA"
        error={error}
        disabled={disabled}
        required = {question.isRequired}
        handleOnChange={event => {
          setValue(event.target.value);
        }}
        customInputStyle = {customInputStyle}
        customInputContainerStyle = {customInputContainerStyle}
      />
    </TravelerFormQuestionContainer>
  );
}
