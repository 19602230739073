import styled from 'styled-components';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PencilIcon from '../icons/PencilIcon';
import { buttonFocus } from '../../utils/shared/focusStyles';
import ProfileFormContainer from '../base/ProfileFormContainer';
import IntakePreferences from '../preferences/IntakePreferences';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import ProgramMatchPreferences from '../preferences/ProgramMatchPreferences';

import userId from '../../../../shared/helpers/userId';
import { fetchIntake } from '../../../travelerForms/actions/intakeFormActions';
import { getProgramPreferences } from '../../../travelerForms/actions/programMatchFormActions';

const EditLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 0.40625rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #446372;
  letter-spacing: 0.015em;
  text-decoration-line: underline;
  line-height: 120%;

  ${buttonFocus};
`;

export default function PreferencesAndGoalsTab() {
  const dispatch = useDispatch();
  const {
    customAliases: { alias_program: programAlias, alias_traveler: travelerAlias },
  } = useSelector(state => state.currentOrganization);
  const organization = useSelector(state => state.auth.organization);
  
  useEffect(() => {
    dispatch(fetchIntake(userId));
    dispatch(getProgramPreferences(userId));
  }, []);

  return (
    <ProfileFormContainer heading={`${programAlias} Preferences`}>
      <ProfileFormSubheading text={`${programAlias} Match Preferences`}>
        <EditLink to="/traveler/program-match">
          <PencilIcon />
          <span>Edit {programAlias} Match</span>
        </EditLink>
      </ProfileFormSubheading>
      <ProgramMatchPreferences />
      <ProfileFormSubheading text={`${travelerAlias} Intake`}>
        {organization?.info?.show_intake && 
          <EditLink to="/traveler/intake">
            <PencilIcon />
            <span>Edit Intake</span>
          </EditLink>
        }
      </ProfileFormSubheading>
      <IntakePreferences />
    </ProfileFormContainer>
  );
}
