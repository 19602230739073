import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioInput from '../../base/inputs/RadioInput';
import { RadioGrid } from '../../base/inputs/InputGrids.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import travelerTypes from '../../../utils/intake/travelerTypes';
import { addIntakeTravelerType, addAdminIntakeFormData } from '../../../actions/intakeFormActions';

const RadioGridColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column: span 2 / span 2;
  gap: 0.9375rem;

  div {
    grid-column: span 1 / span 1;
  }

  @media ${breakpoints.tablet} {
    grid-template-rows: repeat(4, minmax(0, 1fr));
    grid-column: span 1 / span 1;
  }
`;

export default function IntakeTravelerType(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Traveler Type',
    heading: 'What type of traveler are you?',
    subheading: 'Select one traveler type.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="traveler_type").required ?? false,
  };

  useEffect(
    () => {
      if(props?.isAdmin) {
        setValue(adminIntakeFormData?.traveler_type || '');
      } else {
        setValue(intake.traveler_type || '');
      }
      setDisabled(intake.ssoTraveler && intake.readonly.includes('traveler_type'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    
    setError("");
    if (!disabled && !value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0)  
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "traveler_type", value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
      dispatch(addIntakeTravelerType(userId, { traveler_type: value }, value));
    }
  };

  return (
    <TravelerFormQuestionContainer
      question={question}
      handleNext={handleNext}
      disabled={disabled}
      error={error}
      canBeSynced
      isAdmin={props.isAdmin}
      handleBackStep={()=>props.handleAdminBackStep()}
      adminIntakeStep={props.adminIntakeStep}
    >
      <RadioGrid isAdmin={props.isAdmin} role="radiogroup" aria-labelledby="role-label">
        <label id="role-label" style={{display: "none"}}>Select one traveler type. Required</label>
        <RadioGridColumn>
          {travelerTypes.slice(0, 4).map(travelerType => (
            <RadioInput
              key={`traveler-type-${travelerType.id}`}
              value={travelerType.value}
              label={travelerType.label}
              icon={travelerType.icon}
              selected={value}
              disabled={disabled}
              fullWidth
              handleOnChange={() => {
                if (disabled) return;

                setValue(travelerType.value);
              }}
            />
          ))}
        </RadioGridColumn>
        <RadioGridColumn>
          {travelerTypes.slice(4).map(travelerType => (
            <RadioInput
              key={`traveler-type-${travelerType.id}`}
              value={travelerType.value}
              label={travelerType.label}
              icon={travelerType.icon}
              selected={value}
              disabled={disabled}
              fullWidth
              handleOnChange={() => {
                if (disabled) return;

                setValue(travelerType.value);
              }}
            />
          ))}
        </RadioGridColumn>
      </RadioGrid>
    </TravelerFormQuestionContainer>
  );
}
