import React,{useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Term from './components/Term';
import { TabCard, TabHeading, TabCardPanel, TabContentContainer } from '../TabContent.styled';
import { TermInfoContainer } from './components/Term.styled';
import moment from 'moment-timezone';
import NoDataCard from './components/NoDataCard';
import ScreenReaderText from '../../../../travelerProfile/components/base/ScreenReaderText.styled';

export default function TermInformation({ isAdmin, isTraveler }) {
  const titleRef = useRef();
  const {
    meta: { authorized, provider, id: programId },
    organization: { timezone, name: organization },
    terms,
  } = useSelector(state => state.programBrochure);
  const travelerOrgTimezone = useSelector(state => state.profile.orgTimezone);
  const visitorTimezone = useSelector(state => state.auth.organization.info.org_timezone);
  const userTimezone = !isTraveler && !isAdmin ? visitorTimezone : isAdmin ? timezone : travelerOrgTimezone;

  const updatedTerms = terms.map(term => {
    const nowDate = moment().format('MMMM D, YYYY h:mm a');
    const dateToCheck = moment(new Date(term.range.deadline)).format('MMMM D, YYYY h:mm a');

    if (
      !moment(dateToCheck).isSameOrAfter(nowDate) &&
      term.application !== null &&
      term.range.status !== 'cancelled' &&
      term.range.deadline !== null
    ) {
      term.range.parsed_status = 'closed';
    }

    return term;
  });

  useEffect(()=>{
    if(titleRef.current) {
      titleRef.current.focus();
    }
  },[])

  return (
    <TabContentContainer>
      <ScreenReaderText ref={titleRef} tabIndex={-1}>Term Information</ScreenReaderText>
      <TabHeading level={2}>Term Information</TabHeading>
      <TabCard noTabCardMobile="true">
        <TabCardPanel className="full" >
          {updatedTerms?.length > 0 ? (
            <TermInfoContainer tabIndex={0}>
              {updatedTerms.map(term => (
                <Term
                  programId={programId}
                  timezone={userTimezone}
                  authorized={authorized}
                  key={term.range.id}
                  term={term}
                  provider={provider}
                  isAdmin={isAdmin}
                  isTraveler={isTraveler}
                  organization={organization}
                />
              ))}
            </TermInfoContainer>
          ) : (
            <NoDataCard isAdmin={isAdmin} isTraveler={isTraveler} programId={programId} tabIndex={0} />
          )}
        </TabCardPanel>
      </TabCard>
    </TabContentContainer>
  );
}
