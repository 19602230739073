import React from 'react';
import { filterDefaultMultiOptions } from '../customFilters/agGridFilters';
import { customDateComparator } from './gridDefinitions';
import { stripHtmlString } from '../../../../shared/helpers/General';

const customFields = fields => {
  let customFieldGroup = [];
  if (fields) {
    fields.forEach(key => {
      customFieldGroup.push({
        field: '',
        headerName: `${key?.trim()} (Custom)`,
        colId: key?.trim(),
        wrapText: true,
        autoHeight: true,
        hide: true,
        valueGetter: params => {
          if (params?.data?.custom_fields_raw[key]) {
            return params?.data?.custom_fields_raw[key]?.trim();
          } else {
            return '';
          }
        },
        cellRenderer: params => {
          if (params?.data?.custom_fields_raw[key]) {
            return params?.data?.custom_fields_raw[key]?.trim();
          } else {
            return '';
          }
        },
        keyCreator: params => {
          return !params?.data?.custom_fields_raw[key]?.trim()
            ? ' (Blanks)'
            : params?.data?.custom_fields_raw[key]?.trim();
        },
        width: 300,
      });
    });
  }

  return customFieldGroup;
};

const customQuestionFields = (questions, isForm = false) => {
  let customQuestionGroup = [];
  if (questions) {
    questions.forEach(value => {
      switch (value.type.toLowerCase()) {
        case 'recommendationquestion':
          const urlDomain = new URL(window.location.href).host;
          const protocol = window.location.protocol;

          customQuestionGroup.push({
            field: '',
            headerName: `${value?.label?.trim()} (Response)`,
            colId: value?.label?.trim(),
            wrapText: true,
            autoHeight: true,
            hideContextMenu: true,
            valueGetter: params => {
              const recValues = params.data?.responses[value?.value]?.value;
              let valueHtml = '';
              if (recValues) {
                recValues.forEach(rec => {
                  if (!rec?.cancelled_at) {
                    if (rec?.completed_at && rec?.token) {
                      valueHtml += `${rec?.recommender} (${!rec?.completed_at ? 'sent' : rec?.completed_at})`;
                    } else {
                      valueHtml += `${rec?.recommender} (${!rec?.completed_at ? 'sent' : rec?.completed_at});`;
                    }
                  }
                });

                valueHtml = valueHtml.replace(new RegExp('; </div><br />' + '$'), '');
              }
              return valueHtml;
            },
            cellRenderer: params => {
              const recValues = params.data?.responses[value?.value]?.value;
              let valueHtml = '';
              if (recValues) {
                recValues.forEach(rec => {
                  if (!rec?.cancelled_at) {
                    if (rec?.completed_at && rec?.token) {
                      valueHtml += `<div><a href="/visitor/recommendation_submissions/${rec.token.toString()}?admin=true" target="_blank">${
                        rec?.recommender
                      }</a> (${
                        !rec?.completed_at ? 'sent' : rec?.completed_at
                      })<span style="display: none;">(${protocol}//${urlDomain}/visitor/recommendation_submissions/${rec.token.toString()}?admin=true)</span>; </div><br />`;
                    } else {
                      valueHtml += `<div>${rec?.recommender} (${
                        !rec?.completed_at ? 'sent' : rec?.completed_at
                      }); </div><br />`;
                    }
                  }
                });

                valueHtml = valueHtml.replace(new RegExp('; </div><br />' + '$'), '');
              }
              return (
                <div
                  dangerouslySetInnerHTML={{ __html: valueHtml }}
                  style={{ paddingTop: '10px', paddingBottom: '10px' }}
                />
              );
            },
            keyCreator: params => {
              const paramValue =
                params.data?.responses[value?.value]?.value &&
                params.data?.responses[value?.value]?.value !== undefined &&
                params.data?.responses[value?.value]?.value?.length > 0
                  ? params.data?.responses[value?.value]?.value[0].recommender
                  : ' (Blanks)';

              return paramValue;
            },
            width: 300,
          });
          break;

        case 'dropdownquestion':
        case 'multiplechoicequestion':
          customQuestionGroup.push({
            field: '',
            headerName: `${value?.label?.trim()} (Response)`,
            colId: value?.label?.trim(),
            filter: 'agMultiColumnFilter',
            filterParams: filterDefaultMultiOptions,
            wrapText: true,
            autoHeight: true,
            valueGetter: params => {
              const resValues = params.data?.responses[value?.value]?.value;
              let valueList = [];

              if (resValues?.length > 0) {
                resValues.forEach(res => {
                  valueList.push(res?.parent_value ? res.parent_value : res);
                });
              }
              valueList = valueList.sort().filter((x, i, a) => a.indexOf(x) === i);
              return valueList;
            },
            cellRenderer: params => {
              return params.value.join('; ');
            },
            keyCreator: params => {
              return params.value?.length < 1 ? ' (Blanks)' : params?.value;
            },
            width: 300,
          });
          break;

        case 'singlechoicequestion':
          customQuestionGroup.push({
            field: '',
            headerName: `${value?.label?.trim()} (Response)`,
            colId: value?.label?.trim(),
            filter: 'agMultiColumnFilter',
            filterParams: filterDefaultMultiOptions,
            wrapText: true,
            autoHeight: true,
            valueGetter: params => {
              const paramValue =
                params.data?.responses[value?.value]?.parent_value &&
                params.data?.responses[value?.value]?.parent_value !== undefined &&
                params.data?.responses[value?.value]?.parent_value?.trim()?.length > 0
                  ? params.data?.responses[value?.value]?.parent_value?.trim()
                  : '';
              return paramValue;
            },
            cellRenderer: params => {
              return params.value;
            },
            keyCreator: params => {
              return params.value?.length < 1 ? ' (Blanks)' : params?.value;
            },

            width: 300,
          });
          break;

        case 'datequestion':
          customQuestionGroup.push({
            field: '',
            headerName: `${value?.label?.trim()} (Response)`,
            colId: value?.label?.trim(),
            filter: 'agDateColumnFilter',
            comparator: (date1, date2, nodeA, nodeB, isInverted) => {
              let dateA = new Date('1900-01-01');
              let dateB = new Date('1900-01-01');
              let nodeAValue = nodeA.data?.responses[value?.value]?.value;
              let nodeBValue = nodeB.data?.responses[value?.value]?.value;

              if (nodeAValue !== null && nodeAValue !== undefined) {
                dateA = new Date(nodeAValue);
              }
              if (nodeBValue !== null && nodeBValue !== undefined) {
                dateB = new Date(nodeBValue);
              }

              if (dateA === null && dateB === null) {
                return 0;
              }
              if (dateA === null) {
                return isInverted ? 1 : -1;
              }
              if (dateB === null) {
                return isInverted ? 1 : -1;
              }
              return dateA - dateB;
            },
            filterParams: {
              inRangeInclusive: true,
              comparator: customDateComparator,
            },
            valueGetter: params => {
              return params.data?.responses[value?.value]?.value || '';
            },
            cellRenderer: params => {
              return params.data?.responses[value?.value]?.value || '';
            },
            width: 300,
          });
          break;

        case 'filedownloadquestion':
        case 'videoquestion':
          customQuestionGroup.push({
            field: '',
            headerName: `${value?.label?.trim()} (Response)`,
            colId: value?.label?.trim(),
            hideContextMenu: true,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
              const aResValue = nodeA.data?.responses[value?.value]?.value;
              const bResValue = nodeB.data?.responses[value?.value]?.value;

              const trimA = aResValue !== undefined && aResValue && aResValue?.length > 0 ? aResValue?.toString() : '';
              const trimB = bResValue !== undefined && bResValue && bResValue?.length > 0 ? bResValue?.toString() : '';
              return trimA > trimB ? 1 : trimA < trimB ? -1 : 0;
            },
            cellRenderer: params => {
              return (
                <div>
                  <a href={`/client/form-review/${params.data.submission_id}`} style={{ color: '#007bff' }}>
                    View on {isForm ? 'form' : 'application'}
                  </a>
                </div>
              );
            },
            width: 300,
          });
          break;
        case 'fileuploadquestion':
          customQuestionGroup.push({
            field: '',
            headerName: `${value?.label?.trim()} (Response)`,
            colId: value?.label?.trim(),
            hideContextMenu: true,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
              const aResValue = nodeA.data?.responses[value?.value]?.value;
              const bResValue = nodeB.data?.responses[value?.value]?.value;

              const trimA = aResValue !== undefined && aResValue && aResValue?.length > 0 ? aResValue?.toString() : '';
              const trimB = bResValue !== undefined && bResValue && bResValue?.length > 0 ? bResValue?.toString() : '';
              return trimA > trimB ? 1 : trimA < trimB ? -1 : 0;
            },
            cellRenderer: params => {
              const resValue = params.data?.responses[value?.value]?.value;
              return resValue ? (
                <div>
                  <a href={`/client/form-review/${params.data.submission_id}`} style={{ color: '#007bff' }}>
                    View on {isForm ? 'form' : 'application'}
                  </a>
                </div>
              ) : (
                <div></div>
              );
            },
            width: 300,
          });
          break;
        case 'feequestion':
        case 'cashnetquestion':
        case 'cashnetwithcodequestion':
        case 'flywirequestion':
        case 'payflowquestion':
        case 'touchnetquestion':
          customQuestionGroup.push({
            field: '',
            headerName: `${value?.label?.trim()} (Response)`,
            colId: value?.label?.trim(),
            filter: 'agMultiColumnFilter',
            filterParams: filterDefaultMultiOptions,
            valueGetter: params => {
              const paramValue =
                params.data?.responses[value?.value]?.value &&
                params.data?.responses[value?.value]?.value !== undefined &&
                params.data?.responses[value?.value]?.value?.trim()?.length > 0
                  ? params.data?.responses[value?.value]?.value?.trim()
                  : '';
              return paramValue;
            },
            cellRenderer: params => {
              return params.value || 'Not Paid';
            },
            keyCreator: params => {
              return params.value?.length < 1 ? 'Not Paid' : params?.value;
            },
            width: 300,
          });
          break;

        default:
          customQuestionGroup.push({
            field: '',
            headerName: `${value?.label?.trim()} (Response)`,
            colId: value?.label?.trim(),
            filter: 'agMultiColumnFilter',
            filterParams: filterDefaultMultiOptions,
            valueGetter: params => {
              const paramValue =
                params.data?.responses[value?.value]?.value &&
                params.data?.responses[value?.value]?.value !== undefined &&
                params.data?.responses[value?.value]?.value?.trim()?.length > 0
                  ? params.data?.responses[value?.value]?.value?.trim()
                  : '';
              return stripHtmlString(paramValue);
            },
            cellRenderer: params => {
              return stripHtmlString(params?.value?.trim());
            },
            keyCreator: params => {
              return params.value?.length < 1 ? ' (Blanks)' : stripHtmlString(params?.value);
            },
            width: 300,
          });
          break;
      }
    });
  }
  return customQuestionGroup;
};
export { customFields, customQuestionFields };
