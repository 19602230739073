import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buttonFocus } from '../../utils/shared/focusStyles';
import programFilterItem from '../../types/programFilterItem';
import { toggleProgramsFilter } from '../../actions/programDashboardActions';
import filterDropdownComponentMap from '../../utils/program-dashboard/filterDropdownComponentMap';
import ScreenReaderText from '../base/ScreenReaderText.styled';
const Container = styled.div`
  position: relative;
`;

const Pill = styled.button`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.4375rem;
  background-color: ${({ isSelected }) => (isSelected ? '#D2E4ED' : '#d7d7d7')};
  border: none;
  border-radius: 50px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #373737;
  letter-spacing: 0.015em;
  line-height: 120%;

  svg {
    max-width: 13px;
    max-height: 13px;
    margin-right: 0.9375rem;
  }

  ${buttonFocus};
`;

export default function ProgramFiltersListItem({ filter, openMenu, setOpenMenu }) {
  const dispatch = useDispatch();
  
  const [filterApplied, setFilterApplied] = useState(false);
  const liveRegionRef = useRef(null);

  const { selectedFilters } = useSelector(state => state.programDashboard);

  const [isSelected, setIsSelected] = useState(false);

  const Icon = filter.icon;
  const FilterDropdownComponent = filterDropdownComponentMap[filter.value];

  useEffect(
    () => {
      let isSelected = false;

      if (filter.value === 'locations') {
        isSelected = selectedFilters.cities.length > 0 || selectedFilters.countries.length > 0;
      } else if (filter.value === 'dates') {
        isSelected = selectedFilters.startDate.date || selectedFilters.endDate.date;
      } else if (filter.value === 'more') {
        isSelected =
          selectedFilters.programName.length > 0 ||
          selectedFilters.housingType.length > 0 ||
          selectedFilters.organizationName.length > 0 ||
          selectedFilters.languageOfInstruction.length > 0 ||
          selectedFilters.languageImmersion === 'yes';
      } else {
        isSelected = Array.isArray(selectedFilters[filter.value])
          ? selectedFilters[filter.value].length > 0
          : !!selectedFilters[filter.value];
      }

      setIsSelected(isSelected);
    },
    [selectedFilters],
  );

  const handleClick = event => {
    if (filter.value === 'favorites') {
      setOpenMenu('');

      dispatch(toggleProgramsFilter({ name: 'favorites', value: !selectedFilters.favorites }));

      return;
    }

    const value = openMenu && event.target.innerText === openMenu ? '' : filter.label;

    setOpenMenu(value);
  };

  const handleClose = (isFilterApplied=false) => {
    if(isFilterApplied) {
      setFilterApplied(true);
      setTimeout(() => setFilterApplied(false), 1000);
    }
    setOpenMenu('');
  };

  return (
    <Container>
      <ScreenReaderText ref={liveRegionRef} aria-live="polite">{filterApplied && "Filter Applied"}</ScreenReaderText>
      <Pill aria-label={isSelected ? `${filter.label} Selected` : filter.label} type="button" onClick={handleClick} isSelected={isSelected}>
        {filter.icon && <Icon fillColor="#373737" />}
        {filter.label}
      </Pill>
      {filter.value !== 'favorites' &&
        openMenu === filter.label && <FilterDropdownComponent handleClose={handleClose} />}
    </Container>
  );
}

ProgramFiltersListItem.propTypes = {
  filter: programFilterItem.isRequired,
  openMenu: PropTypes.string.isRequired,
  setOpenMenu: PropTypes.func.isRequired,
};
