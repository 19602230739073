import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioInput from '../../base/inputs/RadioInput';
import { RadioGrid } from '../../base/inputs/InputGrids.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import certaintyOptions from '../../../utils/intake/certaintyOptions';
import { addIntakeParticipationCertainty, addAdminIntakeFormData } from '../../../actions/intakeFormActions';

const RadioGridColumn = styled.div`
  grid-column: span 2 / span 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export default function IntakeParticipationCertainty(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const question = {
    overline: 'Participation Certainty',
    heading: 'How certain are you about going on a program?',
    subheading: 'Select one option.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="particiation_certainty").required ?? false,
  };

  useEffect(
    () => {
      if(props?.isAdmin) {
        const initialValue =
        adminIntakeFormData?.travel_certainty === null || adminIntakeFormData?.travel_certainty === 5
          ? ''
          : certaintyOptions.find(option => [option.numberValue, option.value].includes(adminIntakeFormData?.travel_certainty))?.value;

          setValue(initialValue);
      } else {
        const initialValue =
          intake.travel_certainty === null || intake.travel_certainty === 5
            ? ''
            : certaintyOptions.find(option => [option.numberValue, option.value].includes(intake.travel_certainty))?.value;

        setValue(initialValue);
      }
    },
    [intake.travel_certainty, adminIntakeFormData],
  );

  const handleNext = () => {
   
    setError("");
    if (!value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "travel_certainty", value));
      props.handleAdminStep();
      return;
    }
    dispatch(addIntakeParticipationCertainty(userId, { travel_certainty: value }, true));
  };

  return (
    <TravelerFormQuestionContainer
      question={question}
      handleNext={handleNext}
      error={error}
      moreErrorMargin
      //toProcessing
      isAdmin={props.isAdmin}
      handleBackStep={()=>props.handleAdminBackStep()}
      adminIntakeStep={props.adminIntakeStep}
    >
      <RadioGrid role="radiogroup" aria-labelledby="role-label">
        <label id="role-label" style={{display: "none"}}>Select one option Required</label>
        {certaintyOptions.map(option => (
          <RadioGridColumn key={`certainty-option-${option.id}`}>
            <RadioInput
              fullWidth={true}
              key={`certainty-option-${option.id}`}
              value={option.value}
              label={option.label}
              icon={option.icon}
              selected={value}
              handleOnChange={() => {
                setValue(option.value);
              }}
            />
          </RadioGridColumn>
        ))}
      </RadioGrid>
    </TravelerFormQuestionContainer>
  );
}
