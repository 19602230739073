import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonLargeOpenSans } from '../../../../../styledComponents/styles/Buttons.styled';
import { Col, Row } from '../../../../../styledComponents/styles/FlexGrid.styled';
import { fileType, maxFileSize } from '../../../../../shared/helpers/UploaderMessages';
import ImageViewer from '../../../../../components/ImageViewer';
import ReactPDF from '../../../../../components/ReactPDF';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import { Table, TableBody, TableRow, TableColumn } from '../../../../../styledComponents/styles/Table.styled';
import Tooltip from '../../../../../styledComponents/styles/Tooltip.styled';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import { ErrorXIcon } from '../../../../../styledComponents/styles/IconCustom.styled';
import { uploadFileAdd } from '../../../../../actions/formsActions';
import DeleteUploadedFile from '../../../pages/admin/modals/DeleteUploadedFile.modal';
import { downloadFile } from '../../../../../shared/helpers/General';
import ScreenReaderLabelStyled from '../../../../travelerForms/components/base/inputs/ScreenReaderLabel.styled';

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;
  svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

const ErrorMsg = styled.div`
  background-color: #ffcfcb;
  border-left: 20px solid #ee9999;
  border-radius: 5px;
  color: #4d4d4d;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.005em;
  line-height: 120%;
  margin-top: 17px;
  padding: 20px;
`;

const ErrorTextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
`;

const ErrorTile = styled.div`
  font-weight: 600;
`;

const ErrorText = styled.div`
  font-weight: 400;
`;

const InfoContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.06em;
  text-decoration: underline;
  color: #255a94;
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;
const RowStyle = styled(Row)`
  padding-bottom: 8px;
`;

const DeleteDiv = styled.div`
  color: #a82425;
  cursor: pointer;
  width: 40px; 
  padding-right: 15px; 
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FileItem = styled.div`
  color: #043544;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: anywhere;
`;

const ImageNameSpanUnlink = styled.span`
  padding-left: 0px;
`;

const PreviewIcon = styled.button`
  cursor: pointer;
  justify-self: center;
  border: none;
  background: transparent; 
  padding: 0; 
`;

const DownloadFile = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.06em;
  text-decoration-line: underline;
  color: #446372;
  margin-left: 5px;
`;

const UploadLabel = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
`;

const AttachmentTable = styled(Table)`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid grey;

  th:first-child,
  tr:first-child {
    width: 70%;
  }

  td:first-child {
    width: 70%;
  }

  tr:nth-of-type(even) {
    background-color: #ebebeb;
  }

  tr:nth-of-type(odd) {
    background-color: #ffffff;
  }
`;
const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;
const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

export const PreviewColumn = styled.td`
  width: 20px;
`;

export const DownloadColumn = styled.td`
  width: 150px;
`;

export const DeleteColumn = styled.td`
  width: 30px;
`;

export default function QuestionFileUploadDisplayForm({
  question,
  formId,
  response,
  isReadonly,
  errors = {},
  setValue,
  isVisitor = false,
}) {
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);
  const [showPDFPreview, setShowPDFPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState('');
  const [attachments, setAttachments] = useState([]);
  const uploadAdd = useSelector(state => state.forms.uploadFileAdd);
  const uploadDelete = useSelector(state => state.forms.uploadFileDelete);
  const id = question.question_id.toString();
  const [errorMsg, setErrorMsg] = useState('');
  const validationErrorMsg = _get(errors[id], 'message') || '';

  function handleShowPreview(file) {
    if (!!file.url && file.content_type) {
      if (file.content_type.toLowerCase().indexOf('pdf') > -1) {
        setPreviewFile(file.url);
        setShowPDFPreview(true);
      }
      if (file.content_type.toLowerCase().indexOf('image') > -1) {
        setPreviewFile(file.url);
        setShowPreview(true);
      }
    }
  }

  useEffect(() => {
    if (response && response?.uploaded_files) {
      if (question?.question_id.toString() === response?.question_id.toString()) {
        setAttachments(response.uploaded_files);
        setTimeout(() => {
          setValue(id, response.uploaded_files, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: false,
          });
        }, 1);
      }
    }
    },
    [response],
  );

  useEffect(() => {
    if (!uploadAdd?.error && uploadAdd?.data?.attributes.uploaded_files) {
      if (question?.question_id.toString() === uploadAdd?.data?.attributes?.question_id.toString()) {
        setAttachments(uploadAdd.data.attributes.uploaded_files);
        setErrorMsg('');
        setTimeout(() => {
          setValue(id, uploadAdd.data.attributes.uploaded_files, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: false,
          });
        }, 1);
        refreshTabs();
      }
    }
  }, [uploadAdd]);

  useEffect(() => {
    if (uploadDelete && !uploadDelete.error && uploadDelete?.data) {
      if (question?.question_id.toString() === uploadDelete?.data?.attributes?.question_id?.toString()) {
        setAttachments(uploadDelete.data.attributes.uploaded_files);
        setErrorMsg('');
        setTimeout(() => {
          setValue(id, uploadDelete.data.attributes.uploaded_files, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: false,
          });
        }, 1);
        refreshTabs();
      }
    }
     
  }, [uploadDelete]);
  
  const refreshTabs = () => {

    setTimeout(() => {

      const focusableElements = Array.from(document.querySelectorAll('a, button, input, textarea, select, details, label[tabindex], p[tabindex], div[tabindex], span[tabindex], .ql-editor'));
      let tbIndex = 0; 
  
      focusableElements.forEach((element, index) => {

        if(element?.getAttribute("id") === "saveBtn" || element?.getAttribute("id") === "nextBtn"){
          //skipping the saveBtn and nextBtn
        }
        else{
          element.setAttribute("tabindex", tbIndex);
          tbIndex++;
        }
      }); 

      let saveBtn = document.getElementById("saveBtn"); 
      if(saveBtn) {
        saveBtn.setAttribute("tabindex", tbIndex + 1); 
      }

      let nextBtn = document.getElementById("nextBtn"); 
      if(nextBtn) {
        nextBtn.setAttribute("tabindex", tbIndex + 2); 
      }

  
    }, 500); 
    

  }
  
  const updatedInstruction = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label id={`label-file-upload-${id}`} tabIndex={0} htmlFor={`file-input-${id}`}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          aria-label={question.label}
          role="contentinfo"
          tabIndex={1}
          id="file-upload-instructions"
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstruction,
          }}
        />
        <InfoContainer>
          <Tooltip toolTipText="Compatible File Types: .csv, .doc, .docx, .gif, .jpeg, .jpg, .mov, .mp3, .mp4, .mpeg, .mpg, .msg, .pdf, .png, .ppt, .pptx, .rtf, .svg, .txt, .xls, .xlsx, .wmv, .zip">
            <StyledIcon type="InformationCircle" size="14px" />
            <Underline
              tabIndex={0}
              role="contentinfo"
              aria-label={
                'Compatible File Types: .csv, .doc, .docx, .gif, .jpeg, .jpg, .mov, .mp3, .mp4, .mpeg, .mpg, .msg, .pdf, .png, .ppt, .pptx, .rtf, .svg, .txt, .xls, .xlsx, .wmv, .zip'
              }
            >
              Compatible File Types
            </Underline>
          </Tooltip>
        </InfoContainer>

        <ButtonLargeOpenSans
          disabled={isReadonly}
          id={`file-button-${id}`}
          onClick={() => {
            if (!isReadonly) {
              document.getElementById(`file-input-${id}`).click();
            }
          }}
          aria-describedby={`filebtnlabel-${id}`}
        >
          <StyledIcon type="Add" size="20px" /> <UploadLabel>File Upload</UploadLabel>
        </ButtonLargeOpenSans>
        {!isReadonly && (
          <>
            {/* <ScreenReaderLabelStyled htmlFor={`file-input-${id}`}>{question.label}</ScreenReaderLabelStyled> */}
            <input
              id={`file-input-${id}`}
              type="file"
              style={{ display: 'none' }}
              value=""
              aria-labelledby={`label-file-upload-${id}`}
              onChange={file => {
                let uploadedFile = null;
                uploadedFile = file.target.files[0];

                const reader = new FileReader();
                if (uploadedFile) {
                  reader.onload = () => {
                    const fileAsBinaryString = reader.result;
                    const extension = uploadedFile.name
                      .split('.')
                      .pop()
                      .toLowerCase();
                    const fileSize = uploadedFile.size;
                    const isGoodExtension = fileType.indexOf(extension) > -1;
                    const isGoodSize = fileSize <= maxFileSize;
                    if (!isGoodExtension) {
                      setErrorMsg('Incompatible file type');
                    }
                    if (!isGoodSize) {
                      setErrorMsg('File is too large, max is 10MB');
                    }
                    if (id && formId && uploadedFile && fileAsBinaryString) {
                      const saveFile = {
                        question_id: parseInt(id),
                        submission_id: parseInt(formId),
                        uploaded_files_attributes: [
                          {
                            filename: uploadedFile.name,
                            s3_store: fileAsBinaryString,
                          },
                        ],
                      };
                      dispatch(uploadFileAdd(saveFile, isVisitor));
                    }
                  };
                  reader.onabort = () => setErrorMsg('File uploading was aborted');
                  reader.onerror = () => setErrorMsg('File is corrupted, try again');
                  reader.readAsDataURL(uploadedFile);
                }
              }}
            />
          </>
        )}
      </InputContainer>

      <div id={`filebtnlabel-${id}`}>
        {!!errorMsg && errorMsg.length > 0 && (
          <ErrorMsg aria-live="polite">
            <ErrorXIcon size={'40px'} />
            <ErrorTextContent>
              <ErrorTile>Error Uploading</ErrorTile>
              <ErrorText aria-live="assertive" role="alert">
                {errorMsg}
              </ErrorText>
            </ErrorTextContent>
          </ErrorMsg>
        )}
      </div>

      {attachments?.length === 0 && validationErrorMsg?.length > 0 && (
        <Error id={`filebtnlabel-${id}`}>
          <StyledIcon type="Warning" size="16px" />
          <span aria-live="assertive" role="alert">
            {validationErrorMsg}
          </span>
        </Error>
      )}
      {attachments && attachments.length > 0 && (
        <InputContainer>
          <Col size={4}>
            <RowStyle>
              <FileContainer>
                <AttachmentTable>
                  <TableBody>
                    {attachments.map((file, index) => (
                      <TableRow key={`${file.id}-${file.filename}-${index}`}>
                        <TableColumn>
                          <FileItem>
                            <ImageNameSpanUnlink>{file.filename}</ImageNameSpanUnlink>
                          </FileItem>
                        </TableColumn>
                        <PreviewColumn>
                          {file.content_type &&
                            file.url &&
                            (file.content_type.toLowerCase().indexOf('pdf') > -1 ||
                              file.content_type.toLowerCase().indexOf('image') > -1) && (
                              <PreviewIcon
                                tabIndex={0}
                                type="button"
                                aria-label="Preview"
                                onClick={() => {
                                  handleShowPreview(file);
                                }}
                              >
                                <StyledIcon type="Eye" size="16px" />
                              </PreviewIcon>
                            )}
                        </PreviewColumn>
                        <DownloadColumn>
                          <PreviewIcon
                            type="button"
                            onClick={() => downloadFile(file.url, file.filename)}
                            aria-label={`Download file ${file.filename}`}
                          >
                            <StyledIcon type="File_download" size="16px" />
                            <DownloadFile>Download File</DownloadFile>
                          </PreviewIcon>
                        </DownloadColumn>
                        {!isReadonly && (
                          <DeleteColumn>
                            <DeleteDiv>
                              <DeleteUploadedFile
                                question_id={parseInt(id)}
                                submission_id={parseInt(formId)}
                                id={parseInt(file.id)}
                                file_name={file.filename}
                                is_visitor={isVisitor}
                              />
                            </DeleteDiv>
                          </DeleteColumn>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </AttachmentTable>
              </FileContainer>
            </RowStyle>
          </Col>
        </InputContainer>
      )}
      {showPreview && <ImageViewer file={previewFile} handleClose={() => setShowPreview(false)} show={showPreview} />}
      {showPDFPreview && (
        <ReactPDF file={previewFile} width="300px" show={showPDFPreview} handleClose={() => setShowPDFPreview(false)} />
      )}
    </Container>
  );
}
