export default [
  {
    id: 1,
    label:"Yes",
    value:"yes"
  },
  {
    id: 2,
    label:"No",
    value:"no"
  },
  {
    id: 3,
    label:"I’m Not Certain",
    value:"not_certain"
  },
  {
    id: 4,
    label:"Prefer Not to Answer",
    value:"prefer_not_answer"
  },
]