import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Error from './inputs/Error';
import SyncNotification from './SyncNotification';
import TravelerFormButtons from './TravelerFormButtons';
import TravelerFormQuestion from './TravelerFormQuestion';

import history from '../../../../history';
import { breakpoints } from '../../utils/breakpoints';
import { handleContinue } from '../../utils/handleStepNavigation';
import { getIntakeSteps } from '../../utils/stepComponentMaps';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  display:flex;
  flex-direction:column;
  //margin:auto;
  //width:70%;
  @media ${breakpoints.desktop} {
    width:70%;
    margin:auto;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8125rem;
  margin-bottom: 8.1775rem;
  width:95%;
  @media ${breakpoints.tablet} {
    gap: 4.6875rem;
    margin-bottom: 7rem;
  }

  @media ${breakpoints.desktop} {
    margin-bottom: 2rem;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -1.5rem;

  @media ${breakpoints.tablet} {
    margin-top: -4rem;
    margin-right: 8.10125rem;
    margin-left: 2.9375rem;

    ${props => {
      if (props.moreMargin) {
        return `
          margin-left: 7.5rem;
        `;
      }
    }};
  }

  @media ${breakpoints.desktop} {
    ${props => {
      if (props.moreMargin) {
        return props.desktopMargin
          ? `
            margin-left: 7.5rem;
          `
          : `
            margin-left: 2.9375rem;
          `;
      }
    }};
  }
`;

export default function TravelerFormQuestionContainer({
  error,
  children,
  question,
  disabled,
  showBack,
  handleNext,
  handleBackStep,
  adminIntakeStep,
  canBeSynced,
  toProcessing,
  moreErrorMargin,
  desktopErrorMargin,
  isAdmin,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const step = history.location.pathname.split('/').pop();
  const intakeQuestions = useSelector(state=>state.intakeForm.intakeQuestions);

  useEffect(()=>{
    const intakeQuestionObj = getIntakeSteps(intakeQuestions);
    if(isAdmin) {
      if (Object.keys(intakeQuestionObj).length == adminIntakeStep) {
        setIsProcessing(true);
      }
    } else {
      if (Object.keys(intakeQuestionObj).length == step) {
        setIsProcessing(true);
      }
    }
  },[intakeQuestions])

  return (
    <Form
      method="POST"
      onSubmit={event => {
        handleContinue(event, history, handleNext, isProcessing);
      }}
    >
      <FormContainer>
        <TravelerFormQuestion
          overline={question.overline}
          heading={question.heading}
          subheading={question.subheading}
          isRequired={question.isRequired}
          isSubheadingRead={question?.isSubheadingRead ? true : false}
        />
        <InputContainer>
          {children}
          {error && (
            <ErrorContainer moreMargin={moreErrorMargin} desktopMargin={desktopErrorMargin}>
              <Error message={error} />
            </ErrorContainer>
          )}
          {canBeSynced && disabled && <SyncNotification />}
        </InputContainer>
      </FormContainer>
       { (history.location.pathname.includes('/traveler/intake') || isAdmin) ? 
        <TravelerFormButtons isAdmin={isAdmin} showBack={showBack} adminIntakeStep={adminIntakeStep} handleBackStep={handleBackStep} handleNext={handleNext} toProcessing={isProcessing} />
        :
        <TravelerFormButtons showBack={showBack} handleBackStep={handleBackStep} handleNext={handleNext} toProcessing={toProcessing} />
      } 
    </Form>
  );
}

TravelerFormQuestionContainer.propTypes = {
  error: PropTypes.string,
  disabled: PropTypes.bool,
  showBack: PropTypes.bool,
  canBeSynced: PropTypes.bool,
  toProcessing: PropTypes.bool,
  moreErrorMargin: PropTypes.bool,
  desktopErrorMargin: PropTypes.bool,
  children: PropTypes.node.isRequired,
  handleNext: PropTypes.func.isRequired,
  question: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    overline: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
  }).isRequired,
};

TravelerFormQuestionContainer.defaultProps = {
  error: '',
  showBack: true,
  disabled: false,
  canBeSynced: false,
  toProcessing: false,
  moreErrorMargin: false,
  desktopErrorMargin: false,
};
