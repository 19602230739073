import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import RadioButton from '../../../../../styledComponents/styles/RadioButton.styled';
import _get from 'lodash/get';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import QuestionMultiLineDisplayForm from '../multiLineText/question.multiLine.display.form';
import QuestionShortTextDisplayForm from '../shortText/question.shortText.display.form';
import QuestionFileUploadDisplayForm from '../fileUpload/question.fileUpload.display.form';
import { Controller } from 'react-hook-form';

const Container = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
  flex-direction: column;
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
`;

const ChildInputContainer = styled.div`
  flex: 1;
  margin: 25px 0 25px 0;
  text-align: left;
  display: flex;
`;

const VerticalBar = styled.div`
  background-color: #d9d9d9;
  border-radius: 3px;
  flex: 0 1 10px;
  margin-right: 20px;
`;

const ChildInput = styled.div`
  flex: 0 1 100%;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const RadioContainer = styled.div`
  flex: 1;
  text-align: left;
`;

const RadioButtonContainer = styled.div`
  flex: 1;
  text-align: left;
`;
const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.defaults.errorColor};
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: -15px;
  padding-top: 3px;
  vertical-align: middle;

  svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

export default function QuestionSingleChoiceDisplayForm({
  question,
  formState,
  errors = {},
  setValue,
  value,
  control,
  defaultValue,
  response,
  childResponse,
  formId,
  registerOptions = false,
  isSynced = false,
  isVisitor = false,
  isReadonly = false,
  ...rest
}) {
  const [answer, setAnswer] = useState(null);
  const [showChildQuestion, setShowChildQuestion] = useState(false);
  const id = question.question_id.toString();
  const errorMsg = _get(errors[id], 'message') || '';
  const logicQuestion = question?.single_choice_question_logic;
  const handleShowQuestion = (logic, value) => {
    setShowChildQuestion(false);

    if (
      (value &&
        (logic?.trigger === 'equals' && (logic?.correct_option === 'either' || logic?.correct_option === value))) ||
      (logic?.trigger === 'does_not_equal' && (logic?.correct_option !== 'either' && logic?.correct_option !== value))
    ) {
      setShowChildQuestion(true);
    }
  };

  useEffect(
    () => {
      let showAnswer = null;
      if (answer !== null) {
        const dv = !defaultValue ? null : defaultValue.value === 'yes' ? 0 : 1;
          setTimeout(() => {
            if(dv!==null){
              setValue(question.question_id.toString(), dv, {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              });
            } else {
              setValue(question.question_id.toString(), dv, {
                shouldValidate: false,
                shouldTouch: false,
                shouldDirty: false,
              });
            }
          }, 1);
        setAnswer(dv);
        showAnswer = dv === 0 ? 'yes' : 'no';
      } else {
        const dv2 = !defaultValue ? null : defaultValue.value === 'yes' ? 0 : 1;
        if (defaultValue) {
          if (!defaultValue.value && defaultValue.value === null) {
            showAnswer = null;
            setAnswer(null);
            setTimeout(() => {
              setValue(question.question_id.toString(), null, {
                shouldValidate: false,
                shouldTouch: false,
                shouldDirty: false,
              });
            
          }, 1);
          } else {
            const dv = defaultValue.value === 'yes' ? 0 : 1;
            setAnswer(dv);
            setTimeout(() => {
                setValue(question.question_id.toString(), dv, {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true,
                });
            }, 1);
            showAnswer = defaultValue.value;
          }
        }
        // setTimeout(() => {
        //   if(dv2!==null){
        //     console.log(dv2, "should validate1")
        //     setValue(question.question_id.toString(), dv2, {
        //       shouldValidate: true,
        //       shouldTouch: true,
        //       shouldDirty: true,
        //     });
        //   } else {
        //     console.log(dv2, "should not validate1")
        //     setValue(question.question_id.toString(), dv2, {
        //       shouldValidate: false,
        //       shouldTouch: false,
        //       shouldDirty: false,
        //     });
        //   }
        // }, 1);
      }

      handleShowQuestion(logicQuestion, showAnswer);
    },
    [defaultValue],
  );

  const handleChange = (value)  => {
    let showAnswer = null;
    setAnswer(value);
    if (value !== null) {
        setTimeout(() => {
          setValue(question.question_id.toString(), value, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });
        }, 1);
      showAnswer = value === 0 ? 'yes' : 'no';
    } else {
      const dv2 = !defaultValue ? null : defaultValue.value === 'yes' ? 0 : 1;
      if (defaultValue) {
        if (!defaultValue.value && defaultValue.value === null) {
          showAnswer = null;
          setAnswer(null);
        } else {
          const dv = defaultValue.value === 'yes' ? 0 : 1;
          setAnswer(dv);
          showAnswer = defaultValue.value;
        }
      }
      setTimeout(() => {
        setValue(question.question_id.toString(), dv2, {
          shouldValidate: false,
          shouldTouch: false,
          shouldDirty: false,
        });
      }, 1);
    }
    handleShowQuestion(logicQuestion, showAnswer);
  }  

  const updatedInstructions = !!question.instructions ? question.instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id} tabIndex={0}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          //aria-label={question.label}
          id="single-choice-instructions"
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstructions,
          }}
        />
        <RadioContainer id={question.id.toString()}>
          <RadioButtonContainer>
            <RadioButton
              name={question.id.toString()}
              value="yes"
              defaultChecked={answer === 0}
              onChange={() => handleChange(0)}
              disabled={isReadonly}
            >
              Yes
            </RadioButton>
          </RadioButtonContainer>
          {errorMsg?.length > 0 &&
            errorMsg.match(/You must answer "No"/gi) && (
              <Error>
                <StyledIcon type="Warning" size="14px" />
                <span aria-live="assertive" role="alert">{errorMsg}</span>
              </Error>
            )}
          <RadioButtonContainer>
            <RadioButton
              name={question.id.toString()}
              value="no"
              defaultChecked={answer === 1}
              onChange={() => handleChange(1)}
              disabled={isReadonly}
            >
              No
            </RadioButton>
          </RadioButtonContainer>
          {errorMsg?.length > 0 &&
            errorMsg.match(/You must answer "Yes"/gi) && (
              <Error>
                <StyledIcon type="Warning" size="14px" />
                <span aria-live="assertive" role="alert">{errorMsg}</span>
              </Error>
            )}
        </RadioContainer>
        {errorMsg?.length > 0 &&
          errorMsg.match(/ is required/gi) && (
            <Error>
              <StyledIcon type="Warning" size="14px" />
              <span aria-live="assertive" role="alert">{errorMsg}</span>
            </Error>
          )}
      </InputContainer>
      {showChildQuestion &&
        logicQuestion?.child_question && (
          <ChildInputContainer>
            <VerticalBar />
            {logicQuestion?.child_question?.question_type_identifier === 'long_text' && (
              <ChildInput>
                <Controller
                  key={logicQuestion?.child_question?.question_id}
                  control={control}
                  name={logicQuestion?.child_question?.question_id.toString()}
                  defaultValue={
                    !!logicQuestion?.child_question?.response && logicQuestion?.child_question?.response.value
                      ? logicQuestion?.child_question?.response.value
                      : ''
                  }
                  rules={registerOptions[logicQuestion?.child_question?.question_id.toString()]}
                  render={({ field: { ref, ...rest } }) => (
                    <QuestionMultiLineDisplayForm
                      question={logicQuestion?.child_question}
                      key={logicQuestion?.child_question?.question_id}
                      formState={formState}
                      errors={errors}
                      readOnly={isReadonly}
                      isSynced={isSynced}
                      disabled={isReadonly}
                      icon={isReadonly ? 'LockClosed' : ''}
                      {...rest}
                    />
                  )}
                />
              </ChildInput>
            )}
            {logicQuestion?.child_question?.question_type_identifier === 'short_text' && (
              <ChildInput>
                <Controller
                  key={logicQuestion?.child_question?.question_id}
                  control={control}
                  name={logicQuestion?.child_question?.question_id.toString()}
                  defaultValue={
                    !!logicQuestion?.child_question?.response && logicQuestion?.child_question?.response.value
                      ? logicQuestion?.child_question?.response.value
                      : ''
                  }
                  rules={registerOptions[logicQuestion?.child_question?.question_id.toString()]}
                  render={({ field: { ref, ...rest } }) => (
                    <QuestionShortTextDisplayForm
                      question={logicQuestion?.child_question}
                      key={logicQuestion?.child_question?.question_id}
                      formState={formState}
                      errors={errors}
                      isReadonly={isReadonly}
                      isSynced={isSynced}
                      disabled={isReadonly}
                      {...rest}
                    />
                  )}
                />
              </ChildInput>
            )}
            {logicQuestion?.child_question?.question_type_identifier === 'file_upload' && (
              <ChildInput>
                <Controller
                  key={logicQuestion?.child_question?.question_id}
                  control={control}
                  name={logicQuestion?.child_question?.question_id.toString()}
                  defaultValue={
                    !!logicQuestion?.child_question?.response && logicQuestion?.child_question?.response.value
                      ? logicQuestion?.child_question?.response.value
                      : ''
                  }
                  rules={registerOptions[logicQuestion?.child_question?.question_id.toString()]}
                  render={({ field: { ref, ...rest } }) => (
                    <QuestionFileUploadDisplayForm
                      question={logicQuestion?.child_question}
                      key={logicQuestion?.child_question?.question_id}
                      formState={formState}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={defaultValue}
                      formId={formId}
                      //response={logicQuestion?.child_question?.response}
                      response={childResponse}
                      isReadonly={isReadonly}
                      isVisitor={isVisitor}
                      {...rest}
                    />
                  )}
                />
              </ChildInput>
            )}
          </ChildInputContainer>
        )}
    </Container>
  );
}
