import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import { fetchCurrentUser } from './profileActions';
import devUri from '../config/keys_dev';
import _clone from 'lodash/clone';

import {
  SELECT_ORG_SETTINGS_TAB,
  FETCH_CUSTOM_FIELDS,
  UPDATE_ORGANIZATION_DATA_SAFECHECK_TEXT,
  UPDATE_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_USERS,
  UPDATE_MESSAGE_RECIPIENTS,
  UPDATE_NOTIFICATIONS,
  DELETE_ORGANIZATION_USER,
  FETCH_ORGANIZATION_INFO,
  FETCH_ROLE_SETTINGS,
  UPDATE_ROLE_SETTINGS,
  CHANGE_USER_ROLE,
  ADD_MESSAGE_RECIPIENT,
  REMOVE_MESSAGE_RECIPIENT,
  FETCH_CLIENT_USER_HEADER,
  RESEND_TRAVELER_INVITE,
  RESEND_ADMIN_INVITE,
  CANCEL_ADMIN_INVITE,
  INVITE_ORGANIZATION_ADMIN,
  FETCH_AUTHORIZED_PROGRAM_SETTINGS,
  CANCEL_TRAVELER_INVITE,
  ARCHIVE_ADMIN,
  UPDATE_ADMIN_ACCOUNT,
  RESET_USERS_CODE,
  CLEAR_ORGANIZATION_FLASH_MESSAGE,
  RESET_ARCHIVE_MESSAGE,
  RESET_ADMIN_INVITE_MESSAGE,
  RESET_TRAVELER_INVITE_MESSAGE,
  FETCH_MAILER_NOTIFICATIONS,
  FETCH_MAILER_NOTIFICATIONS_OPT_OUTS,
  UPDATE_MAILER_OPT_OUT_FAILURE,
  UPDATE_MAILER_OPT_OUT_SUCCESS,
  UPDATE_MAILER_OPT_OUT_CLEAR,
  UPDATE_MAILER_OPT_OUT_REQUEST,
  UPDATE_AUTHORIZED_PROGRAM_SETTINGS,
  ORG_DETAILS,
  UPDATE_ORGANIZATION_DATA_REQUEST,
  UPDATE_ORGANIZATION_DATA_SUCCESS,
  UPDATE_ORGANIZATION_DATA_CLEAR,
  UPDATE_ORGANIZATION_DATA_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_CLEAR,
  UPDATE_ADMIN_ACCOUNT_REQUEST,
  UPDATE_ADMIN_ACCOUNT_SUCCESS,
  UPDATE_ADMIN_ACCOUNT_FAILURE,
  UPDATE_ADMIN_ACCOUNT_CLEAR,
  REMOVE_TRANSFER_ADMIN_REQUEST,
  REMOVE_TRANSFER_ADMIN_CLEAR,
  REMOVE_TRANSFER_ADMIN_SUCCESS,
  REMOVE_TRANSFER_ADMIN_FAILURE,
  FETCH_CUSTOM_FIELDS_REQUEST,
  FETCH_CUSTOM_FIELDS_CLEAR,
  FETCH_CUSTOM_FIELDS_SUCCESS,
  FETCH_CUSTOM_FIELDS_FAILURE,
  UPDATE_CUSTOM_FIELDS_CLEAR,
  UPDATE_CUSTOM_FIELDS_REQUEST,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS_FAILURE,
  ADD_CUSTOM_FIELDS_CLEAR,
  ADD_CUSTOM_FIELDS_REQUEST,
  ADD_CUSTOM_FIELDS_SUCCESS,
  ADD_CUSTOM_FIELDS_FAILURE,
  FETCH_ORG_INTAKE_QUESTIONS_REQUEST,
  FETCH_ORG_INTAKE_QUESTIONS_SUCCESS,
  FETCH_ORG_INTAKE_QUESTIONS_FAILURE,
  UPDATE_ORG_INTAKE_QUESTIONS_REQUEST,
  UPDATE_ORG_INTAKE_QUESTIONS_SUCCESS,
  UPDATE_ORG_INTAKE_QUESTIONS_FAILURE
} from './types';

export function selectOrgSettingsTab(tab) {
  return {
    type: SELECT_ORG_SETTINGS_TAB,
    tab,
  };
}

export function fetchCustomFields(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/get_custom_fields`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_CUSTOM_FIELDS,
          payload: data,
          updated: null,
        }),
      );
  };
}

export function updateCustomFields(id, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/update_custom_fields`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    }).then(res => {
      if (res.status === 201) {
        res.json().then(data => {
          dispatch({
            type: FETCH_CUSTOM_FIELDS,
            payload: data,
            updated: res.status,
          });
          dispatch(fetchCustomFieldsOrgSettings());
        });
      } else {
        dispatch({
          type: FETCH_CUSTOM_FIELDS,
          payload: data,
          updated: res.status,
        });
      }
    });
  };
}

export function updateNotifications(id, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/update_notifications`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: UPDATE_NOTIFICATIONS,
          payload: data,
        }),
      );
  };
}

export function fetchRoleSettings(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/show_role_settings`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_ROLE_SETTINGS,
          payload: data,
        }),
      );
  };
}

export function updateRoleSettings(id, role_settings) {
  const formPayLoad = new FormData();

  if (role_settings) {
    formPayLoad.append('client_account_info[role_settings]', JSON.stringify(role_settings));
  }
  let roleToken = _clone(token);
  delete roleToken['Accept'];
  delete roleToken['Content-Type'];

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/update_role_settings`, {
      method: 'PUT',
      headers: roleToken,
      body: formPayLoad,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: UPDATE_ROLE_SETTINGS,
          payload: data,
        }),
      );
  };
}

export function updateOrganizationData(id, data) {
  const formPayLoad = new FormData();
  formPayLoad.append('client_account_info[participants_goal]', data.client_account_info.participants_goal);
  formPayLoad.append('client_account_info[participants_sent]', data.client_account_info.participants_sent);
  formPayLoad.append('client_account_info[phone_country_code]', data.client_account_info.phone_country_code);
  formPayLoad.append('client_account_info[phone_number]', data.client_account_info.phone_number);
  formPayLoad.append('client_account_info[department]', data.client_account_info.department);
  formPayLoad.append('client_account_info[org_type]', data.client_account_info.org_type);
  formPayLoad.append(
    'client_account_info[non_profit]',
    data.client_account_info.non_profit === 'not_sure' ? '' : data.client_account_info.non_profit,
  );
  formPayLoad.append('client_account_info[public_org]', data.client_account_info.public_org);
  formPayLoad.append('client_account_info[institution_type]', data.client_account_info.institution_type);
  formPayLoad.append('client_account_info[theme_color_dark]', data.client_account_info.theme_color_dark);
  formPayLoad.append('client_account_info[theme_color_light]', data.client_account_info.theme_color_light);
  formPayLoad.append('client_account_info[theme_color_accent]', data.client_account_info.theme_color_accent);
  formPayLoad.append('client_account_info[theme_code]', data.client_account_info.theme_code);
  formPayLoad.append('client_account_info[logo]', data.client_account_info.logo);
  formPayLoad.append('client_account_info[description]', data.client_account_info.description);
  formPayLoad.append('client_account_info[alias_enrollment]', data.client_account_info.alias_enrollment);
  formPayLoad.append('client_account_info[alias_program]', data.client_account_info.alias_program);
  formPayLoad.append('client_account_info[alias_programs]', data.client_account_info.alias_programs);
  formPayLoad.append('client_account_info[alias_traveler]', data.client_account_info.alias_traveler);
  formPayLoad.append('client_account_info[alias_travelers]', data.client_account_info.alias_travelers);
  formPayLoad.append('client_account_info[alias_traveling]', data.client_account_info.alias_traveling);
  formPayLoad.append('client_account_info[alias_favorite]', data.client_account_info.alias_favorite);
  formPayLoad.append('client_account_info[org_timezone]', data.client_account_info.org_timezone);
  formPayLoad.append('client_account_info[show_intake]', data.client_account_info.show_intake);
  formPayLoad.append('client_account_info[show_program_match]', data.client_account_info.show_program_match);

  formPayLoad.append('client_account_info[logo]', data.client_account_info.logo);
  formPayLoad.append('address[secondary_street]', data.address.secondary_street);
  formPayLoad.append('address[city]', data.address.city);
  formPayLoad.append('address[state]', data.address.state);
  formPayLoad.append('address[zip]', data.address.zip);
  formPayLoad.append('address[country]', data.address.country);

  let accountToken = _clone(token);
  delete accountToken['Accept'];
  delete accountToken['Content-Type'];

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}`, {
      method: 'PUT',
      headers: accountToken,
      body: formPayLoad,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: UPDATE_ORGANIZATION_DATA,
          payload: data,
        }),
      );
  };
}

export const updateSafeCheckCustomMessage = (id, dataParam) => {
  const formPayLoad = new FormData();
  formPayLoad.append(
    'client_account_info[safe_check_custom_text]',
    dataParam.client_account_info.safe_check_custom_text,
  );

  let accountToken = _clone(token);
  delete accountToken['Accept'];
  delete accountToken['Content-Type'];

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/update_safe_check_custom_text`, {
      method: 'PATCH',
      headers: accountToken,
      body: formPayLoad,
    })
      .then(res => res.json())
      .then(
        response =>
          dispatch({
            type: UPDATE_ORGANIZATION_DATA,
            payload: response,
          }),
        dispatch({
          type: UPDATE_ORGANIZATION_DATA_SAFECHECK_TEXT,
          payload: {
            data: dataParam.client_account_info.safe_check_custom_text,
          },
        }),
      );
  };
};

export const clearOrganizationFlashMessage = () => dispatch => {
  dispatch({
    type: CLEAR_ORGANIZATION_FLASH_MESSAGE,
  });
};

export function updateAdminAccount(id, data) {
  if (!!data.profile.avatar && !!data.profile.avatar.url) {
    delete data.profile.avatar;
    data.profile.avatar = [];
  } else {
    data.profile.avatar;
  }

  const formPayLoad = new FormData();
  formPayLoad.append('profile[first_name]', data.profile.first_name);
  formPayLoad.append('profile[last_name]', data.profile.last_name);
  formPayLoad.append('profile[avatar]', data.profile.avatar);
  formPayLoad.append('client_user_info[title]', data.profile.title);
  formPayLoad.append('client_user_info[suffix]', data.profile.suffix);
  formPayLoad.append('client_user_info[job_title]', data.client_user_info.job_title);
  formPayLoad.append('client_user_info[department]', data.client_user_info.department);

  let infoToken = _clone(token);
  delete infoToken['Accept'];
  delete infoToken['Content-Type'];

  return function(dispatch) {
    dispatch({ type: UPDATE_ADMIN_ACCOUNT_REQUEST });
    fetch(`${keys.baseUri}/api/client/client_account/${id}/update_user_account_info`, {
      method: 'PUT',
      headers: infoToken,
      body: formPayLoad,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATE_ADMIN_ACCOUNT_SUCCESS,
          payload: data,
        });
        dispatch({
          type: UPDATE_ADMIN_ACCOUNT_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_ADMIN_ACCOUNT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateOrganizationUsers(id, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users/${id}/update_roles`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: UPDATE_ORGANIZATION_USERS,
          code: data.status,
          payload: data,
        }),
      );
  };
}

export function updateMessageRecipients(id, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users/${id}/update_recipients`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: UPDATE_MESSAGE_RECIPIENTS,
          code: data.status,
        }),
      );
  };
}

export function deleteOrganizationUser(id, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/update_custom_fields`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: DELETE_ORGANIZATION_USER,
          payload: data,
        }),
      );
  };
}

export function fetchOrganization(id, data) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_ORGANIZATION_INFO,
          payload: data,
        });
      });
  };
}

export function fetchClientUserHeader(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users/${id}/client_user_header`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_CLIENT_USER_HEADER,
          payload: data,
        });
      });
  };
}

export function changeUserRole(userRole) {
  return {
    type: CHANGE_USER_ROLE,
    userRole,
  };
}

export function addMessageRecipient(id) {
  return {
    type: ADD_MESSAGE_RECIPIENT,
    id,
  };
}

export function removeMessageRecipient(id) {
  return {
    type: REMOVE_MESSAGE_RECIPIENT,
    id,
  };
}

export function resendAdminInvite(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users/${id}/resend_invitation`, {
      method: 'PUT',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: RESEND_ADMIN_INVITE,
          code: data.status,
        }),
      );
  };
}

export function cancelAdminInvite(id) {
  let status = null;
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users/${id}/cancel_admin_invitation`, {
      method: 'PUT',
      headers: token,
    })
      .then(res => {
        status = res.status;
        return res.json();
      })
      .then(data =>
        dispatch({
          type: CANCEL_ADMIN_INVITE,
          code: status,
          user_id: data.user_id,
        }),
      );
  };
}

export function resendTravelerInvite(email) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_invitations/resend_invitation`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify({ email }),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: RESEND_TRAVELER_INVITE,
          code: data.status,
        }),
      );
  };
}

export function inviteOrganizationAdmin(data, userId = '') {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify({
        user: { email: data.email, first_name: data.first_name, last_name: data.last_name },
        role: data.role,
      }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: INVITE_ORGANIZATION_ADMIN,
          payload: data,
        });

        // fetch organization info after invitation if userId is passed in argument
        if (userId) {
          dispatch(fetchOrganization(userId));
        }
        //end
      });
  };
}

export function fetchAuthorizedProgramSettings(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/get_authorized_program_settings`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_AUTHORIZED_PROGRAM_SETTINGS,
          payload: data,
        }),
      );
  };
}

export function updateAuthorizedProgramSettings(id, payload) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/client_account/${id}/update_authorized_program_settings`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(payload),
    })
      .then(res => res.json())
      .then(
        data =>
          dispatch({
            type: UPDATE_AUTHORIZED_PROGRAM_SETTINGS,
            payload: data,
          }),
        dispatch(clearOrganizationFlashMessage()),
      );
  };
}

export function cancelTravelerInvite(email) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/traveler_invitations/cancel_invitation`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify({ traveler_invitation: { email } }),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: CANCEL_TRAVELER_INVITE,
          payload: data,
        }),
      );
  };
}

export function archiveAdmin(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/users/${id}/archive_user`, {
      method: 'PUT',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: ARCHIVE_ADMIN,
          payload: data,
        }),
      );
  };
}

export const resetArchiveMessage = () => dispatch => {
  dispatch({
    type: RESET_ARCHIVE_MESSAGE,
  });
};

export function resetUsersCode() {
  return {
    type: RESET_USERS_CODE,
  };
}

export const resetAdminInviteMessage = () => dispatch => {
  dispatch({
    type: RESET_ADMIN_INVITE_MESSAGE,
  });
};

export const resetTravelerInviteMessage = () => dispatch => {
  dispatch({
    type: RESET_TRAVELER_INVITE_MESSAGE,
  });
};

export function fetchMailNotifications() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/send_grid_mailers`, {
      headers: token,
      method: 'GET',
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_MAILER_NOTIFICATIONS,
          payload: data,
        }),
      );
  };
}

export function fetchMailNotificationsOptOuts() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/mailer_opt_outs`, {
      // method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_MAILER_NOTIFICATIONS_OPT_OUTS,
          payload: data,
        }),
      );
  };
}

export function updateMailerOptOut(values) {
  return function(dispatch) {
    dispatch({ type: UPDATE_MAILER_OPT_OUT_REQUEST });
    fetch(`${keys.baseUri}/api/mailer_opt_outs`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATE_MAILER_OPT_OUT_SUCCESS,
          payload: { data: data },
        });
      })
      .then(() => {
        dispatch({
          type: UPDATE_MAILER_OPT_OUT_CLEAR,
        });
        dispatch(fetchMailNotificationsOptOuts());
      })
      .catch(error => {
        dispatch({
          type: UPDATE_MAILER_OPT_OUT_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export const setOrgInfo = values => dispatch => {
  dispatch({
    type: ORG_DETAILS,
    payload: values,
  });
};

export function updateOrganizationSettings(id, data, userId, source) {
  if (!!data.client_account_info.logo && !!data.client_account_info.logo.url) {
    delete data.client_account_info.logo;
    data.client_account_info.logo = [];
  } else {
    data.client_account_info.logo;
  }

  const formPayLoad = new FormData();
  formPayLoad.append('client_account_info[participants_goal]', data.client_account_info.participants_goal);
  formPayLoad.append('client_account_info[participants_sent]', data.client_account_info.participants_sent);
  formPayLoad.append('client_account_info[phone_country_code]', data.client_account_info.phone_country_code);
  formPayLoad.append('client_account_info[phone_number]', data.client_account_info.phone_number);
  formPayLoad.append('client_account_info[department]', data.client_account_info.department);
  formPayLoad.append('client_account_info[org_type]', data.client_account_info.org_type);
  formPayLoad.append(
    'client_account_info[non_profit]',
    data.client_account_info.non_profit === 'not_sure' ? '' : data.client_account_info.non_profit,
  );
  formPayLoad.append('client_account_info[public_org]', data.client_account_info.public_org);
  formPayLoad.append('client_account_info[institution_type]', data.client_account_info.institution_type);
  formPayLoad.append(
    'client_account_info[theme_color_dark]',
    !!data.client_account_info.theme_color_dark.hex
      ? data.client_account_info.theme_color_dark.hex
      : data.client_account_info.theme_color_dark,
  );

  // formPayLoad.append(
  //   'client_account_info[theme_color_light]',
  //   !!data.client_account_info.theme_color_light.hex
  //     ? data.client_account_info.theme_color_light.hex
  //     : data.client_account_info.theme_color_light,
  // );

  formPayLoad.append(
    'client_account_info[theme_color_accent]',
    !!data.client_account_info.theme_color_accent.hex
      ? data.client_account_info.theme_color_accent.hex
      : data.client_account_info.theme_color_accent,
  );
  formPayLoad.append('client_account_info[theme_code]', data.client_account_info.theme_code);
  formPayLoad.append('client_account_info[logo]', data.client_account_info.logo);
  formPayLoad.append('client_account_info[description]', data.client_account_info.description);
  formPayLoad.append('client_account_info[alias_enrollment]', data.client_account_info.alias_enrollment);
  formPayLoad.append('client_account_info[alias_program]', data.client_account_info.alias_program);
  formPayLoad.append('client_account_info[alias_programs]', data.client_account_info.alias_programs);
  formPayLoad.append('client_account_info[alias_traveler]', data.client_account_info.alias_traveler);
  formPayLoad.append('client_account_info[alias_travelers]', data.client_account_info.alias_travelers);
  formPayLoad.append('client_account_info[alias_traveling]', data.client_account_info.alias_traveling);
  formPayLoad.append('client_account_info[alias_favorite]', data.client_account_info.alias_favorite || 'Favorite');
  formPayLoad.append('client_account_info[alias_favorites]', data.client_account_info.alias_favorites || 'Favorites');
  formPayLoad.append(
    'client_account_info[alias_unfavorite]',
    data.client_account_info.alias_unfavorite || 'Unfavorite',
  );
  formPayLoad.append('client_account_info[org_timezone]', data.client_account_info.org_timezone);
  formPayLoad.append('client_account_info[show_intake]', data.client_account_info.show_intake);
  formPayLoad.append('client_account_info[show_program_match]', data.client_account_info.show_program_match);
  formPayLoad.append('client_account_info[use_custom_aliases]', data.client_account_info.use_custom_aliases);
  formPayLoad.append('client_account_info[allow_traveler_deferral]', data.client_account_info.allow_traveler_deferral);
  formPayLoad.append(
    'client_account_info[limit_applications_by_timeframe]',
    data.client_account_info.limit_applications_by_timeframe,
  );
  formPayLoad.append(
    'client_account_info[allow_student_universe_mailer]',
    data.client_account_info.allow_student_universe_mailer,
  );
  formPayLoad.append(
    'client_account_info[enable_active_term_name_filtering]',
    data.client_account_info.enable_active_term_name_filtering,
  );
  formPayLoad.append('client_account_info[allow_traveler_sign_up]', data.client_account_info.allow_traveler_sign_up);
  formPayLoad.append(
    'client_account_info[show_sign_in_instruction]',
    data.client_account_info.show_sign_in_instruction,
  );
  formPayLoad.append('client_account_info[sign_in_instruction]', data.client_account_info.sign_in_instruction);
  formPayLoad.append('address[street]', data.address.street);
  formPayLoad.append('address[secondary_street]', data.address.secondary_street);
  formPayLoad.append('address[city]', data.address.city);
  formPayLoad.append('address[state]', data.address.state);
  formPayLoad.append('address[zip]', data.address.zip);
  formPayLoad.append('address[country]', data.address.country);

  if (data?.request_to_withdraw_setting?.active !== undefined) {
    formPayLoad.append('request_to_withdraw_setting[active]', data?.request_to_withdraw_setting?.active);
    formPayLoad.append('request_to_withdraw_setting[instruction]', data?.request_to_withdraw_setting?.instruction);

    formPayLoad.append(
      'request_to_withdraw_setting[application_statuses]',
      JSON.stringify(data?.request_to_withdraw_setting?.application_statuses),
    );
  }

  let accountToken = _clone(token);
  delete accountToken['Accept'];
  delete accountToken['Content-Type'];

  return function(dispatch) {
    dispatch({ type: UPDATE_ORGANIZATION_DATA_REQUEST });
    fetch(`${keys.baseUri}/api/client/client_account/${id}`, {
      method: 'PUT',
      headers: accountToken,
      body: formPayLoad,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATE_ORGANIZATION_DATA_SUCCESS,
          payload: data,
        });
      })
      .then(() => {
        if (Array.isArray(data.client_account_info.logo)) {
          dispatch(fetchOrganization(userId));
          dispatch(fetchClientUserHeader(userId));
          dispatch(fetchCurrentUser(userId));
        } else {
          dispatch(fetchClientUserHeader(userId));
          dispatch(fetchCurrentUser(userId));
          !!data.client_account_info.logo.url ? null : window.location.reload();
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_ORGANIZATION_DATA_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateOrganizationSettingsClear() {
  return function(dispatch) {
    dispatch({ type: UPDATE_ORGANIZATION_DATA_CLEAR });
  };
}

export function changePassword(password) {
  return function(dispatch) {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });
    fetch(`${keys.baseUri}/api/password/update`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(password),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: data,
        });
        dispatch({
          type: CHANGE_PASSWORD_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: CHANGE_PASSWORD_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function removeAndTransferAdmin(adminId, deletedId, userId) {
  return function(dispatch) {
    dispatch({ type: REMOVE_TRANSFER_ADMIN_REQUEST });
    fetch(`${keys.baseUri}/api/client/users/${deletedId}`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify({ assumed_user_id: adminId }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.type === 'success') {
          dispatch({
            type: REMOVE_TRANSFER_ADMIN_SUCCESS,
            payload: { data: data },
          });
          dispatch({
            type: REMOVE_TRANSFER_ADMIN_CLEAR,
          });
          dispatch(fetchOrganization(userId));
        } else {
          dispatch({
            type: REMOVE_TRANSFER_ADMIN_FAILURE,
            payload: { data: data },
          });
        }
      });
  };
}

export function fetchCustomFieldsOrgSettings() {
  return function(dispatch) {
    dispatch({ type: FETCH_CUSTOM_FIELDS_REQUEST });
    fetch(`${keys.baseUri}/api/custom_fields`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_CUSTOM_FIELDS_SUCCESS,
          payload: { data: data },
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_CUSTOM_FIELDS_FAILURE,
          payload: { data: data },
        });
      });
  };
}

export function updateCustomFieldsOrgSettings(id, data) {
  return function(dispatch) {
    dispatch({ type: UPDATE_CUSTOM_FIELDS_REQUEST });
    fetch(`${keys.baseUri}/api/custom_fields/${data.id}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATE_CUSTOM_FIELDS_SUCCESS,
          payload: { data: data },
        });

        dispatch({
          type: UPDATE_CUSTOM_FIELDS_CLEAR,
          payload: { data: data },
        });
        dispatch(fetchCustomFieldsOrgSettings());
      })
      .catch(error => {
        dispatch({
          type: UPDATE_CUSTOM_FIELDS_FAILURE,
          payload: { data: data },
        });
      });
  };
}

export function addCustomFieldsOrgSettings(data) {
  return function(dispatch) {
    dispatch({ type: UPDATE_CUSTOM_FIELDS_REQUEST });
    fetch(`${keys.baseUri}/api/custom_fields`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: UPDATE_CUSTOM_FIELDS_SUCCESS,
          payload: { data: data },
        });

        dispatch({
          type: UPDATE_CUSTOM_FIELDS_CLEAR,
          payload: { data: data },
        });
        dispatch(fetchCustomFieldsOrgSettings());
      })
      .catch(error => {
        dispatch({
          type: UPDATE_CUSTOM_FIELDS_FAILURE,
          payload: { data: data },
        });
      });
  };
}

export function fetchOrgIntakeQuestions() {
  return async function(dispatch) {
    try {
      dispatch({ type: FETCH_ORG_INTAKE_QUESTIONS_REQUEST });
      let response = await fetch(`${keys.baseUri}/api/client/intake_questions`, {
        method: 'GET',
        headers: token,
      });
      let data = await response.json();
      dispatch({
        type: FETCH_ORG_INTAKE_QUESTIONS_SUCCESS,
        payload: {
          show_intake: data.show_intake,
          intake_questions: data?.intake_questions?.data || []
        },
      });
    } catch (e) {
      dispatch({
        type: FETCH_ORG_INTAKE_QUESTIONS_FAILURE,
        payload: e.message,
      });
    }
  };
}

export function updateOrgIntakeQuestions(payload, callback = null) {
  return async function(dispatch) {
    try {
      dispatch({ type: UPDATE_ORG_INTAKE_QUESTIONS_REQUEST });
      let response = await fetch(`${keys.baseUri}/api/client/intake_questions/update_setting`, {
        method: 'PATCH',
        headers: token,
        body: JSON.stringify(payload)
      });
      let data = await response.json();
      dispatch({
        type: UPDATE_ORG_INTAKE_QUESTIONS_SUCCESS,
        payload: data.message,
      });

      if(callback && typeof callback === "function"){
        callback(true, "Intake settings updated."); 
      }

    } catch (e) {
      dispatch({
        type: UPDATE_ORG_INTAKE_QUESTIONS_FAILURE,
        payload: e.message,
      });

      callback(false, e.message); 
    }
  };
}

