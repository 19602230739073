import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Suitcase from '../../icons/Suitcase';
import { ButtonLink, ButtonPrimary, ButtonSecondary } from '../../base/Buttons.styled';

import history from '../../../../../history';
import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeComplete } from '../../../actions/intakeFormActions';
import ScreenReaderText from '../../../../travelerProfile/components/base/ScreenReaderText.styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media ${breakpoints.desktop} {
    margin-bottom: 5rem;
  }

  svg {
    align-self: center;
    width: 100px;
    height: 100px;
    margin-top: 2.403125rem;
    margin-bottom: 1.4375rem;

    @media ${breakpoints.tablet} {
      width: 150px;
      height: 150px;
      margin-top: 8rem;
    }

    @media ${breakpoints.desktop} {
      margin-top: 5rem;
    }
  }
`;

const Heading = styled.h1`
  margin-bottom: 1.4375rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1.5625rem;
  color: #373737;
  text-align: center;
  line-height: 120%;

  @media ${breakpoints.tablet} {
    margin-bottom: 0.3125rem;
    text-align: left;
  }
`;

const Subheading = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #474747;
  text-align: center;
  line-height: 120%;
  letter-spacing: 0.005em;

  @media ${breakpoints.tablet} {
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.75rem;
  margin-top: 2.0625rem;
  margin-bottom: 3.25rem;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    margin-top: 3.0625rem;
    margin-bottom: 7.125rem;
  }

  @media ${breakpoints.desktop} {
    margin-bottom: 5rem;
  }
`;

export default function IntakeWelcome() {
  const dispatch = useDispatch();
  const titleRef = useRef();
  const { show_program_match: showProgramMatch, program_match_complete: programMatchComplete } = useSelector(
    state => state.profile,
  );

  const handleRedirect = () => {
    if (showProgramMatch && !programMatchComplete) {
      history.push('/traveler/program-match');
    } else {
      history.push('/traveler/program-dashboard');
    }
  };

  const handleStartForm = () => {
    history.push('/traveler/intake/steps/1');
  };

  const handleSkipIntake = () => {
    dispatch(addIntakeComplete(userId, true));

    handleRedirect();
  };

  useEffect(()=>{
    if(titleRef.current) {
      titleRef.current.focus();
    }
  },[])

  return (
    <Container>
      <ScreenReaderText ref={titleRef} tabIndex={-1}>Intake Welcome</ScreenReaderText>
      <Suitcase />
      <span tabIndex={0}>
        <Heading>Your journey has begun! Ready to get started on your new experience?</Heading>
        <Subheading>First we&apos;ll get to know you, so we can help you every step of the way.</Subheading>
      </span>
      <ButtonContainer>
        <ButtonSecondary type="button" onClick={handleRedirect}>
          Remind Me Later
        </ButtonSecondary>
        <ButtonPrimary type="button" onClick={handleStartForm}>
          Let&apos;s Do It
        </ButtonPrimary>
      </ButtonContainer>
      <ButtonLink type="button" onClick={handleSkipIntake}>
        Skip Intake &amp; Don&apos;t Show Again
      </ButtonLink>
    </Container>
  );
}
