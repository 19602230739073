import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import history from '../history';
import { stringify } from 'query-string';
import _clone from 'lodash/clone';

import {
  FETCH_PROGRAM_HASH,
  SET_PROGRAM_SETTINGS_ERROR,
  SET_EXTERNAL_URL,
  REPLACE_UPDATED_PROGRAM_OBJECT,
  ADD_NEW_TERM,
  SETTING_ERRORS,
  CLEAR_PROGRAM_SETTINGS_ERRORS,
  SHOW_PROGRAM_SETTINGS_LOADER,
  TOGGLE_STATUS,
  GET_AVAILABLE_TAGS,
  CLEAR_AVAILABLE_TAGS,
  AVAILABLE_TAGS_LOADING,
  HANDLE_CLEAR_STATE,
  TOGGLE_PROGRAM_PUBLISH,
  ALTERNATE_PROGRAM_COUNT,
  TOGGLE_UPDATE_STATES,
  SEARCH_TAGS,
  SEARCH_FORMS,
  CANCEL_PROGRAM_TERM,
  SAVE_NEW_ADMIN_DOCUMENT,
  DELETE_ADMIN_DOCUMENT,
  UPDATE_ADMIN_DOCUMENT,
  TOGGLE_DATE_CHANGE_WARNING_MODAL,
  UPDATED_DATE_ERROR_OBJECT
} from './types';

const params = {
  settings: true,
};
export const fetchProgramObject = program_id => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${program_id}/get_program_hash?${stringify(params)}`, {
    headers: token,
  }).then(response => {
    if (response.status == 401) {
      response.json().then(data => {
        dispatch({
          type: 'ADD_ALERT',
          text: data.message,
          style: 'danger',
        });
        history.push('/client/program-dashboard/internal');
      });
    } else {
      response.json().then(data => {
        dispatch(handleClearState(false));
        dispatch({
          type: FETCH_PROGRAM_HASH,
          payload: data,
        });
        dispatch({
          type: TOGGLE_UPDATE_STATES,
          payload: false,
        });
      });
    }
  });
};

export const setError = (errorField, hasError) => dispatch => {
  dispatch({
    type: SET_PROGRAM_SETTINGS_ERROR,
    errorField,
    hasError,
  });
};

export const updateExternalUrl = externalUrl => dispatch => {
  dispatch(setError('externalUrl', false));
  dispatch({
    type: SET_EXTERNAL_URL,
    externalUrl,
  });
};

export const replaceUpdatedProgramObject = updatedProgramObject => dispatch => {
  dispatch({
    type: REPLACE_UPDATED_PROGRAM_OBJECT,
    payload: updatedProgramObject,
  });
};

export const updateDateError = errorObject => dispatch => {
  dispatch({
    type: UPDATED_DATE_ERROR_OBJECT,
    payload: errorObject,
  });
};

export const updateProgram = (params, id) => dispatch => {
  let data = {
    program: params,
  };
  dispatch(displayLoader());
  fetch(`${keys.baseUri}/api/client/programs/${id}/update_program_settings?settings=true`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(data => {
      if (data.status === 400) {
        dispatch({
          type: SETTING_ERRORS,
          payload: data.errors,
        });
      } else {
        dispatch({
          type: FETCH_PROGRAM_HASH,
          payload: data,
        });
        dispatch(handleClearState(false));
        dispatch({
          type: TOGGLE_UPDATE_STATES,
          payload: false,
        });
        window.location.reload();
      }
    });
};
export const addNewTerm = programObject => dispatch => {
  dispatch({
    type: ADD_NEW_TERM,
    payload: programObject,
  });
};
export const programDuplicate = program_id => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${program_id}/duplicate`, {
    method: 'POST',
    headers: token,
  })
    .then(response => response.json())
    .then(data => {
      window.open(`/client/programs/${data.id}`, '_blank');
    });
};

export const clearProgramSettingsErrors = () => dispatch => {
  dispatch({
    type: CLEAR_PROGRAM_SETTINGS_ERRORS,
  });
};

export const displayLoader = () => dispatch => {
  dispatch({
    type: SHOW_PROGRAM_SETTINGS_LOADER,
  });
};

export const cancelChanges = program_id => dispatch => {
  dispatch(displayLoader());
  window.location.assign(`/client/programs/${program_id}`);
};

export const toggleStatus = () => dispatch => {
  dispatch({
    type: TOGGLE_STATUS,
  });
};

export const authorizedProgram = id => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${id}/authorize_program`, {
    method: 'PUT',
    headers: token,
  })
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: TOGGLE_STATUS,
      });
    });
};

export const deAuthorizedProgram = id => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${id}/deauthorize_program`, {
    method: 'PUT',
    headers: token,
  })
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: TOGGLE_STATUS,
      });
    });
};

export const toggleAuthorization = (id, checked) => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${id}/toggle_authorization`, {
    method: 'PUT',
    headers: token,
    body: JSON.stringify({
      checked: checked,
    }),
  })
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: TOGGLE_STATUS,
        payload: data,
      });
    });
};

export const inactiveProgram = program_id => dispatch => {
  fetch(`${keys.baseUri}/api/client/programs/${program_id}/inactive`, {
    method: 'PATCH',
    headers: token,
  })
    .then(response => response.json())
    .then(data => window.location.reload(true));
};

export const getAvailableTags = (program_range_id, page = 1, alter) => dispatch => {
  dispatch(availableTagsLoading());
  fetch(
    `${
      keys.baseUri
    }/api/client/tags/get_available_tags?program_range_id=${program_range_id}&page=${page}&alter=${alter}`,
    {
      method: 'GET',
      headers: token,
    },
  )
    .then(response => response.json())
    .then(data =>
      dispatch({
        type: GET_AVAILABLE_TAGS,
        payload: data,
      }),
    );
};

export const clearAvailableTags = () => dispatch => {
  dispatch({
    type: CLEAR_AVAILABLE_TAGS,
  });
};

export const availableTagsLoading = () => dispatch => dispatch({ type: AVAILABLE_TAGS_LOADING });

export const handleClearState = clearState => dispatch => dispatch({ type: HANDLE_CLEAR_STATE, payload: clearState });

export function toggleProgramPublish(id) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/${id}/toggle_program_publish`, {
      method: 'POST',
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: TOGGLE_PROGRAM_PUBLISH,
          payload: data,
        }),
      );
  };
}
export function alternateProgramCounter(params, dummyId = null) {
  let data = JSON.stringify(params);
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/programs/alternate_programs_count?program_count=${data}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: ALTERNATE_PROGRAM_COUNT,
          payload: data,
          dummyId,
        }),
      );
  };
}

export const searchTagsAction = value => dispatch => {
  dispatch({
    type: SEARCH_TAGS,
    payload: value,
  });
};

export const searchFormsAction = value => dispatch => {
  dispatch({
    type: SEARCH_FORMS,
    payload: value,
  });
};

export const cancelProgramTerm = (data, programId) => dispatch => {
  // CancelProgramTerm handles both cancelling and deleting a term.
  // BE will handle logic to see if there are any applications associated
  fetch(`${keys.baseUri}/api/client/programs/cancel_program_range`, {
    method: 'DELETE',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(data => {
      dispatch({
        type: CANCEL_PROGRAM_TERM,
        body: data,
      });
    })
    .then(() => {
      window.location.reload();
    });
};

export const saveNewAdminDocument = data => dispatch => {
  const program_id = data.program_id;
  const formData = new FormData();
  const objectKeys = Object.keys(data);

  let userToken = _clone(token);
  delete userToken['Accept'];
  delete userToken['Content-Type'];

  objectKeys.map(object => {
    formData.append(`admin_program_attachment[${object}]`, data[object]);
  });

  fetch(`${keys.baseUri}/api/client/admin_program_attachments`, {
    method: 'POST',
    headers: userToken,
    body: formData,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: SAVE_NEW_ADMIN_DOCUMENT,
        payload: data,
      });
      dispatch(fetchProgramObject(program_id));
    })
    .catch(error => console.error(error));
};

export const deleteAdminDocument = data => {
  const program_id = data.program_id;

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/admin_program_attachments/${data.id}`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify(data),
    }).then(data => {
      dispatch({
        type: DELETE_ADMIN_DOCUMENT,
        body: data,
      });
      dispatch(fetchProgramObject(program_id));
    });
  };
};

export const updateAdminDocument = data => {
  const program_id = data.program_id;

  return function(dispatch) {
    fetch(`${keys.baseUri}/api/client/admin_program_attachments/${data.id}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(data),
    }).then(data => {
      dispatch({
        type: UPDATE_ADMIN_DOCUMENT,
        body: data,
      });
      dispatch(fetchProgramObject(program_id));
    });
  };
};

export const toggleDateChangeWarningModal = (flag) => {
  return {
    type: TOGGLE_DATE_CHANGE_WARNING_MODAL,
    payload: flag
  }  
}
