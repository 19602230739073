import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LocationsFilterInputs from './LocationsFilterInputs';
import ProgramFilterDropdown from '../ProgramFilterDropdown';

import useLocationsOptions from '../../../../hooks/useLocationsOptions';
import { toggleProgramsFilter } from '../../../../actions/programDashboardActions';

export default function DesktopLocationsFilter({ handleClose }) {
  const dispatch = useDispatch();

  const [selectedCities, setSelectedCities, selectedCountries, setSelectedCountries] = useLocationsOptions();

  const handleCountriesChange = event => {
    if (event) {
      setSelectedCountries(event);
    } else {
      setSelectedCountries([]);
    }
  };

  const handleCitiesChange = event => {
    if (event) {
      setSelectedCities(event);
    } else {
      setSelectedCities([]);
    }
  };

  const handleClearAll = () => {
    setSelectedCities([]);
    setSelectedCountries([]);

    dispatch(toggleProgramsFilter({ name: 'cities', value: [] }));
    dispatch(toggleProgramsFilter({ name: 'countries', value: [] }));
  };

  const handleFilter = () => {
    dispatch(toggleProgramsFilter({ name: 'cities', value: selectedCities }));
    dispatch(toggleProgramsFilter({ name: 'countries', value: selectedCountries }));

    handleClose(true);
  };

  useEffect(()=>{
    document.getElementById("filterHeading").focus();
  },[]);

  return (
    <ProgramFilterDropdown handleFilter={handleFilter} handleClearAll={handleClearAll} handleClose={handleClose} readerLabel={'Location filter dialogue box'}>
      <LocationsFilterInputs
        selectedCities={selectedCities}
        selectedCountries={selectedCountries}
        handleCitiesChange={handleCitiesChange}
        handleCountriesChange={handleCountriesChange}
      />
    </ProgramFilterDropdown>
  );
}

DesktopLocationsFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
