import React from 'react';
import PropTypes from 'prop-types';

import LocationIcon from '../../icons/LocationIcon';
import { CardDetailItem } from './ProgramCard.styled';

export default function CardLocations({ locations }) {
  return (
    <CardDetailItem>
      <LocationIcon />
      <span>{locations}</span>
    </CardDetailItem>
  );
}

CardLocations.propTypes = {
  locations: PropTypes.string.isRequired,
};
