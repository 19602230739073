import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import LoadingScreen from  '../../../styledComponents/styles/LoadingScreen';
import token from '../../../shared/helpers/token';
import userId from '../../../shared/helpers/userId';
import loadedFonts from '../../../shared/fonts/loadFonts.css';
import { themeVia } from '../../../styledComponents/settings/theme';
import { useThemeColors } from '../../../shared/hooks/useThemeColors';
import GlobalStyles from '../../../styledComponents/styles/GlobalStyles';
import { getCurrentOrganization } from '../actions/currentOrganizationActions';
import { getCurrentUser, getTravelerProfile, getInboundOrganization } from '../actions/travelerProfileActions';
import { getTravelerPrograms } from '../actions/programDashboardActions';
import { fetchTravelerSubmission, clearSubmission } from '../../../actions/formsActions';

function Layout({ children, submissionId }) {
  const dispatch = useDispatch();
  const isBrandingSet = useThemeColors();
  const { travelerProfileLoading } = useSelector(state => state.travelerProfile);
  const { currentOrganizationLoading } = useSelector(state => state.currentOrganization);
  const { clientFeatureList } = useSelector(state => state.profile);
  //const { applicationSubmissionLoading } = useSelector(state => state.travelerApplicationSubmissions);
  const { travelerSubmission } = useSelector(state => state.forms);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(clearSubmission());

    if (!userId) {
      window.location.assign('/clear_cookies');
    }

    if (token && userId) {
      dispatch(getTravelerProfile(userId, 'traveler'));
      dispatch(getCurrentOrganization());
      dispatch(getCurrentUser(userId));
      dispatch(getTravelerPrograms());
      dispatch(fetchTravelerSubmission(submissionId));
    }
  }, []);

  useEffect(()=>{
    let unmounted = false;
    if(!unmounted && token && userId && clientFeatureList?.inbound) {
      dispatch(getInboundOrganization(userId));
    }
    return () => {
      unmounted = true;
    };
  },[clientFeatureList]) 

  useEffect(() => {
    const authLoadingCheck = token && userId;
    const baseInfoLoadingCheck = travelerProfileLoading || currentOrganizationLoading || (travelerSubmission?.loading ?? true);

    setLoading(authLoadingCheck ? baseInfoLoadingCheck : false);
  }, [travelerProfileLoading, currentOrganizationLoading, travelerSubmission]);

  return (
    <ThemeProvider theme={themeVia}>
      <GlobalStyles />
      <div>
        {isBrandingSet && !loading ? children : (
          <LoadingScreen 
            displayText='Loading...'
            width='100%'
            height='50vh'
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export default compose(withStyles(loadedFonts))(Layout);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  submissionId: PropTypes.string.isRequired,
};
