import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../base/inputs/TextInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeQuestions, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import useMediaQuery from '../../../../../hooks/useMediaQuery';

export default function IntakeAcedemicMajor(props) {
  const dispatch = useDispatch();
  const { isMobile, isDesktop, isTablet } = useMediaQuery();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState("");
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Academic Major',
    heading: 'What is your primary major?',
    subheading: 'Enter your primary major.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="academic_major").required ?? false,
  };


  useEffect(
    () => {
      if(props?.isAdmin) {
        setValue(adminIntakeFormData?.primary_major);
      } else {
        setValue(intake.primary_major);
      }
      setDisabled(intake.ssoTraveler && intake.readonly.includes('primary_major'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    
    setError("");
    if (!disabled && !value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please enter your primary major');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "primary_major", value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
      dispatch(addIntakeQuestions(userId, { primary_major: value },"primary_major"));
    }
  };

  const customInputStyle = {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "4px",
    border: "1px solid var(--Gray-400, #CED4DA)",
    background: disabled ? "#eeeeee" : "var(--Gray-White, #FFF)",
  }
  const customInputContainerStyle = {
    width : isDesktop ? "100%" : "100%",
    marginTop:"1.375rem",
  }

  return (
    <TravelerFormQuestionContainer 
      isAdmin={props.isAdmin} 
      question={question} 
      handleBackStep={()=>props.handleAdminBackStep()} 
      handleNext={handleNext} 
      adminIntakeStep={props.adminIntakeStep}
      disabled={disabled} canBeSynced>
      <TextInput
        value={value}
        id="academic_major"
        label="Major - Primary"
        placeholder="Enter primary major"
        error={error}
        disabled={disabled}
        required={question.isRequired}
        handleOnChange={event => {
          setValue(event.target.value);
        }}
        customInputStyle = {customInputStyle}
        customInputContainerStyle = {customInputContainerStyle}
      />
    </TravelerFormQuestionContainer>
  );
}
