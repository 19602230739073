import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../../utils/breakpoints';

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.53125rem 0.75rem;
  background-color: #eeeeee;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #373737;
  cursor: pointer;

  @media ${breakpoints.mobile} {
    overflow-wrap: anywhere;
  }

  @media ${breakpoints.tablet} {
    ${props => {
      if (props.tabletWidth) {
        return `
          width: ${props.tabletWidth};
        `;
      }
    }};
  }

  @media ${breakpoints.desktop} {
    ${props => {
      if (props.desktopWidth) {
        return `
          width: ${props.desktopWidth};
        `;
      }
    }};
  }

  ${props => {
    if (props.noBackground) {
      return `
        background-color: #ffffff;
      `;
    }

    if (props.checked) {
      return `
        background-color: #D2E4ED;
        color: #446372;
      `;
    }
  }};
`;

const InputLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.015em;
  cursor: pointer;
  user-select: none;
`;

const Input = styled.input`
  width: 18px;
  height: 18px;
  border: 2px solid #446372;
  border-radius: 1px;
  accent-color: #446372;
  transform: translateY(-1px);

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #255a94;
    outline-offset: 1px;
  }
`;

export default function CheckboxInput({
  value,
  id,
  label,
  checked,
  tabletWidth,
  desktopWidth,
  noBackground,
  handleOnChange,
}) {
  return (
    <InputContainer checked={checked} noBackground={noBackground} desktopWidth={desktopWidth} tabletWidth={tabletWidth}>
      <InputLabel htmlFor={id}>
        <Input id={id} value={value} checked={checked} type="checkbox" name={id} onChange={handleOnChange} />
        {label}
      </InputLabel>
    </InputContainer>
  );
}

CheckboxInput.propTypes = {
  noBackground: PropTypes.bool,
  tabletWidth: PropTypes.string,
  desktopWidth: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
};

CheckboxInput.defaultProps = {
  tabletWidth: '',
  desktopWidth: '',
  noBackground: false,
};
