import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckboxInput from '../../base/inputs/CheckboxInput';
import { CheckboxGrid } from '../../base/inputs/InputGrids.styled';
import ScreenReaderLabel from '../../base/inputs/ScreenReaderLabel.styled';
import NoBorderTextInput from '../../base/inputs/NoBorderTextInput.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import paymentOptions from '../../../utils/intake/paymentOptions';
import { addIntakeFinancialInfo, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import ScreenReaderLegend from '../../../../travelerProfile/components/base/ScreenReaderLegend.styled';

const CheckboxGridColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column: span 2 / span 2;
  gap: 0.5rem;

  div,
  input[type='text'] {
    grid-column: span 1 / span 1;
  }

  @media ${breakpoints.tablet} {
    grid-template-rows: repeat(4, minmax(0, 1fr));
    grid-column: span 1 / span 1;

    ${props => {
      if (props.otherSelected) {
        return `
          grid-template-rows: repeat(5, minmax(0, 1fr));
        `;
      }
    }};
  }
`;

export default function IntakeFinancialInfo(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [otherOption, setOtherOption] = useState('');

  const question = {
    overline: 'Financial Info',
    heading: 'How do you plan to pay for your program?',
    subheading: 'Select one or all that apply.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="financial_information").required ?? false,
  };

  useEffect(
    () => {
      if (intake.financial_info) {
        if(props?.isAdmin) {
          const selectedPaymentOptions = [];
          if(adminIntakeFormData?.financial_info) {
            for (const [key, value] of Object.entries(adminIntakeFormData.financial_info)) {
              if (key === 'other_financial_aid_text') {
                setOtherOption(value || '');
              }

              if (value === true || value === 't') {
                selectedPaymentOptions.push(key);
              }
            }
          }
          setValue(selectedPaymentOptions);
        } else {
          const selectedPaymentOptions = [];
          for (const [key, value] of Object.entries(intake.financial_info)) {
            if (key === 'other_financial_aid_text') {
              setOtherOption(value || '');
            }

            if (value === true || value === 't') {
              selectedPaymentOptions.push(key);
            }
          }
          setValue(selectedPaymentOptions);
        }
      }
    },
    [intake, adminIntakeFormData],
  );

  const handleOnChange = paymentOption => {
    if (value.includes(paymentOption)) {
      setValue(value.filter(element => element !== paymentOption));

      if (paymentOption === 'other') {
        setOtherOption('');
      }
    } else {
      setValue([...value, paymentOption]);
    }
  };

  const handleNext = () => {
    
    setError("");
    if (value.length === 0 && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select at least one');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }

    if (value.includes('other') && !otherOption) {
      setTimeout(()=>{
        setError('Please enter response for the other option');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    
    let options = {};

    paymentOptions.forEach(option => {
      options[option.value] = value.includes(option.value);
    });

    options['other_financial_aid_text'] = otherOption;
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "financial_info", options));
      props.handleAdminStep();
      return;
    }
    dispatch(addIntakeFinancialInfo(userId, options));
  };

  return (
    <TravelerFormQuestionContainer isAdmin={props.isAdmin} question={question} 
      handleBackStep={()=>props.handleAdminBackStep()} 
      adminIntakeStep={props.adminIntakeStep}
      handleNext={handleNext} error={error}>
      <CheckboxGrid>
        <fieldset>
          <ScreenReaderLegend>Select all Financial Info that apply Required</ScreenReaderLegend>
        <CheckboxGridColumn otherSelected={value.includes('other')}>
          {paymentOptions.slice(0, 4).map(paymentOption => (
            <CheckboxInput
              key={`payment-option-${paymentOption.id}`}
              id={`payment_option_${paymentOption.id}`}
              value={paymentOption.value}
              label={paymentOption.label}
              checked={value.includes(paymentOption.value)}
              handleOnChange={() => {
                handleOnChange(paymentOption.value);
              }}
            />
          ))}
        </CheckboxGridColumn>
        </fieldset>
        <fieldset>
        <ScreenReaderLegend>Select all Financial Info that apply</ScreenReaderLegend>
        <CheckboxGridColumn otherSelected={value.includes('other')}>
          {paymentOptions.slice(4).map(paymentOption => (
            <CheckboxInput
              key={`payment-option-${paymentOption.id}`}
              id={`payment_option_${paymentOption.id}`}
              value={paymentOption.value}
              label={paymentOption.label}
              checked={value.includes(paymentOption.value)}
              handleOnChange={() => {
                handleOnChange(paymentOption.value);
              }}
            />
          ))}
          {value.includes('other') && (
            <>
              <ScreenReaderLabel htmlFor="other_option_text">Other Option</ScreenReaderLabel>
              <NoBorderTextInput
                id="other_option_text"
                type="text"
                placeholder="Enter other option"
                value={otherOption}
                onChange={event => {
                  setOtherOption(event.target.value);
                }}
              />
            </>
          )}
        </CheckboxGridColumn>
        </fieldset>
      </CheckboxGrid>
    </TravelerFormQuestionContainer>
  );
}
