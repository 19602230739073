import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeQuestions, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import { getYearArrayOptions } from '../../../../../helpers/CommonHelper';

export default function IntakeAnticipatedGradYear(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'Anticipated Graduation Year',
    heading: 'What is your anticipated graduation year?',
    subheading: 'Select the year you plan to graduate.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="anticipated_graduation_year").required ?? false
  };

  const options = getYearArrayOptions(20,20);

  useEffect(
    () => {
      
      if(props?.isAdmin) {
        const initialValue = options.filter(item => adminIntakeFormData?.grad_year==item.value);
        setValue(initialValue);
      } else {
        const initialValue = options.filter(item => intake.grad_year==item.value);
        setValue(initialValue);
      }
      
      setDisabled(intake.ssoTraveler && intake.readonly.includes('grad_year'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
   
    setError("");
    if (!disabled && value.length === 0 && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select your anticipated graduation year');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "grad_year", Array.isArray(value) ? value[0]?.value : value?.value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
       dispatch(addIntakeQuestions(userId, { grad_year: Array.isArray(value) ? value[0]?.value : value?.value },"grad_year"));
    }
  };

  const handleOnChange = event => {
    if (event) {
      setValue(event);
    } else {
      setValue([]);
    }
  };

  return (
    <TravelerFormQuestionContainer 
      isAdmin={props.isAdmin} 
      question={question} 
      handleBackStep={()=>props.handleAdminBackStep()} 
      handleNext={handleNext} 
      adminIntakeStep={props.adminIntakeStep}
      disabled={disabled} canBeSynced>
      <SelectInput
        value={value}
        defaultValue={value}
        id="anticipated_graduation_year"
        label="Anticipated Graduation Year"
        placeholder="Select anticipated graduation year"
        options={options}
        error={error}
        disabled={disabled}
        required ={question.isRequired}
        handleOnChange={handleOnChange}
      />
    </TravelerFormQuestionContainer>
  );
}
