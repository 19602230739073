import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import No from '../../icons/No';
import Yes from '../../icons/Yes';
import Error from '../../base/inputs/Error';
import RadioInput from '../../base/inputs/RadioInput';
import CheckboxInput from '../../base/inputs/CheckboxInput';
import TravelerFormButtons from '../../base/TravelerFormButtons';
import TravelerFormQuestion from '../../base/TravelerFormQuestion';
import { CheckboxGrid } from '../../base/inputs/InputGrids.styled';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import visitedRegions from '../../../utils/intake/visitedRegions';
import { addIntakeTravelExperience, addAdminIntakeFormData } from '../../../actions/intakeFormActions';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.9375rem;
  margin-top: 1.3125rem;
  margin-bottom: 8.1775rem;

  @media ${breakpoints.tablet} {
    margin-top: 2.875rem;
    margin-bottom: 16.40625rem;
  }

  @media ${breakpoints.desktop} {
    margin-bottom: 4rem;
  }
`;

const CheckboxGridColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column: span 2 / span 2;
  gap: 0.5rem;

  div,
  input[type='text'] {
    grid-column: span 1 / span 1;
  }

  @media ${breakpoints.tablet} {
    grid-template-rows: repeat(4, minmax(0, 1fr));
    grid-column: span 1 / span 1;
  }
`;

const RegionsFieldset = styled.fieldset`
  width: 100%;

  .region-checkboxes {
    margin-top: 0;
  }

  @media ${breakpoints.tablet} {
    margin: 1.25rem 0;
  }
`;

const RegionsText = styled.legend`
  margin-bottom: 1.25rem;

  font-size: 1rem;
  color: #446372;
  text-align: center;
  line-height: 120%;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.08px;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;

  @media ${breakpoints.tablet} {
    ${props => {
      if (props.forCheckboxes) {
        return `
          margin-top: -1.25rem;
          margin-bottom: 1.25rem;
        `;
      }
    }};
  }
`;

const FormContainer = styled.div`
  display:flex;
  flex-direction:column;
  //margin:auto;
  //width:70%;
  @media ${breakpoints.desktop} {
    width:70%;
    margin:auto;
  }
`;

export default function IntakePreviousExperience(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [regions, setRegions] = useState([]);

  useEffect(
    () => {
      if(props?.isAdmin) {
        const initialValue = adminIntakeFormData?.travel_experience
          ? [true, 'yes'].includes(adminIntakeFormData?.travel_experience?.has_left_home_country)
            ? 'yes'
            : 'no'
          : null;

        setValue(initialValue);

        if (initialValue === 'yes') {
          const selectedRegions = [];
          if(adminIntakeFormData?.travel_experience) {
            for (const [key, value] of Object.entries(adminIntakeFormData.travel_experience)) {
              if (key === 'has_left_home_country') continue;

              if (value) {
                selectedRegions.push(key);
              }
            }
          }
          setRegions(selectedRegions);
        }
      } else {
        const initialValue = intake.travel_experience
          ? [true, 'yes'].includes(intake.travel_experience.has_left_home_country)
            ? 'yes'
            : 'no'
          : null;

        setValue(initialValue);

        if (initialValue === 'yes') {
          const selectedRegions = [];

          for (const [key, value] of Object.entries(intake.travel_experience)) {
            if (key === 'has_left_home_country') continue;

            if (value) {
              selectedRegions.push(key);
            }
          }

          setRegions(selectedRegions);
        }
      }
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    
    setError("");
    if (!value && intake?.intakeQuestions?.find(item=>item.identifier=="previous_travel_experience").required) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0)  
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if((value === 'yes' && regions.length === 0) && intake?.intakeQuestions?.find(item=>item.identifier=="previous_travel_experience").required) {
      setTimeout(()=>{
        setError('Required, please select a region.');
      },0)  
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    
    let experience = {
      has_left_home_country: value === 'yes' ? true : false,
    };

    visitedRegions.forEach(visitedRegion => {
      experience[visitedRegion.value] = regions.includes(visitedRegion.value);
    });
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "travel_experience", experience));
      props.handleAdminStep();
      return;
    }
    dispatch(addIntakeTravelExperience(userId, experience));
  };

  const handleOnChange = region => {
    if (regions.includes(region)) {
      setRegions(regions.filter(element => element !== region));
    } else {
      setRegions([...regions, region]);
    }
  };

  return (
    <form method="POST" onSubmit={handleNext}>
      <FormContainer>
        <TravelerFormQuestion
          isAdmin={props.isAdmin}
          overline="Previous Travel Experience"
          heading="Have you ever been outside of your home country?"
          subheading="Select one option."
          isRequired={intake?.intakeQuestions?.find(item=>item.identifier=="previous_travel_experience").required ?? false}
          handleBackStep={()=>props.handleAdminBackStep()}
          adminIntakeStep={props.adminIntakeStep}
        />
        <InputContainer role="radiogroup" aria-labelledby="role-label">
          <label id="role-label" style={{display: "none"}}>Select one option. Required</label>
          <RadioInput
            value="yes"
            label="Yes"
            icon={Yes}
            selected={value}
            handleOnChange={() => {
              setValue('yes');
            }}
          />
          {value === 'yes' && (
            <RegionsFieldset>
              <RegionsText>What regions have you visited?</RegionsText>
              <CheckboxGrid className="region-checkboxes">
                <CheckboxGridColumn>
                  {visitedRegions.slice(0, 4).map(visitedRegion => (
                    <CheckboxInput
                      key={`visited-region-${visitedRegion.id}`}
                      id={`visited_region_${visitedRegion.id}`}
                      value={visitedRegion.value}
                      label={visitedRegion.label}
                      checked={regions.includes(visitedRegion.value)}
                      handleOnChange={() => {
                        handleOnChange(visitedRegion.value);
                      }}
                    />
                  ))}
                </CheckboxGridColumn>
                <CheckboxGridColumn>
                  {visitedRegions.slice(4).map(visitedRegion => (
                    <CheckboxInput
                      key={`visited-region-${visitedRegion.id}`}
                      id={`visited_region_${visitedRegion.id}`}
                      value={visitedRegion.value}
                      label={visitedRegion.label}
                      checked={regions.includes(visitedRegion.value)}
                      handleOnChange={() => {
                        handleOnChange(visitedRegion.value);
                      }}
                    />
                  ))}
                </CheckboxGridColumn>
              </CheckboxGrid>
            </RegionsFieldset>
          )}
          {error &&
            value === 'yes' && (
              <ErrorContainer forCheckboxes={value === 'yes'}>
                <Error message={error} />
              </ErrorContainer>
            )}
          <RadioInput
            value="no"
            label="No"
            icon={No}
            selected={value}
            handleOnChange={() => {
              setValue('no');
            }}
          />
          {error &&
            value === null && (
              <ErrorContainer>
                <Error message={error} />
              </ErrorContainer>
            )}
        </InputContainer>
      </FormContainer>
      <TravelerFormButtons handleNext={handleNext} adminIntakeStep={props.adminIntakeStep} isAdmin={props.isAdmin} handleBackStep={()=>props.handleAdminBackStep()} />
    </form>
  );
}
