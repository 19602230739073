import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInternalPrograms } from '../../../../../actions/reportsActions';
import { getTermNamesActive } from '../../../../../actions/termNamesActions';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Headline from '../../../../components/library/headline';
import history from '../../../../../history';
import Button from '../../../../components/library/button';
import GlobalSubHeader from '../../globalSubHeader';
import { viaMuiTheme } from '../../../../../shared/helpers/muiTableNewTheme';
import {
  emptyStringOrValue,
  emptyIntOrValue,
  stripHtmlString,
  buildCountyAndCityArray,
} from '../../../../../shared/helpers/General';
import { calcTiming } from '../../../../../shared/helpers/plans';
import GenericActionsSelect from '../../../../../sites/plans/components/selects/genericActionsSelect';
import FilterNav from '../../filterNav';
import LeftNavFilters from './leftNavFilters';
import BatchUpdateProgramState from '../../modals/batchUpdateProgramState';
import BatchAddTerm from '../../modals/batchAddTerm';
import ViaIcon from '../../../../plans/components/ViaIcon';
import Paragraph from '../../../../components/library/paragraph';
import {
  DEBOUNCE_VALUE,
  debounceSearchRenderDashboard,
} from '../../../../../shared/helpers/DebounceSearchRenderDashboard';
import { createNewProgramModify, createProgramModifyClear } from '../../../../../actions/programModifyActions';
// import TryNew from './modals/tryNew';
import sInternalPrograms from './internalPrograms.scss';
import UserPilotScript from '../../../../../components/UserPilotScript';

export const enhance = compose(withStyles(sInternalPrograms), withRouter);

function InternalPrograms() {
  const FILTER_TYPE_NAME = 'program_internal_dashboard_state';
  const tableState = JSON.parse(localStorage.getItem(FILTER_TYPE_NAME));
  const dispatch = useDispatch();
  const programs = useSelector(state => state.reports.fetchInternalPrograms);
  const publishInternalPrograms = useSelector(state => state.reports.publishInternalProgram);
  const unpublishInternalPrograms = useSelector(state => state.reports.unpublishInternalProgram);
  const customAliases = useSelector(state => state.profile.customAliases);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filterPrograms, setFilterPrograms] = useState([]);
  const [loadingMsg, setLoadingMsg] = useState(`Loading internal ${customAliases.alias_programs.toLowerCase()}...`);
  const [selectDefault, setSelectDefault] = useState();
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [programIds, setProgramIds] = useState([]);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [source, setSource] = useState('');
  const [showBatchAddTerm, setShowBatchAddTerm] = useState(false);
  const [appCount, setAppCount] = useState(0);
  const [cols, setCols] = useState();
  const [savedFilters, setSavedFilters] = useState({});
  const customBranding = useSelector(state => state.profile.customBranding.themes);
  const [selectOptions, setSelectOptions] = useState([]);
  const permissions = useSelector(state => state.profile.permissions.program_settings);
  const [tableSort, setTableSort] = useState({ name: 'program_name', direction: 'asc' });
  const [showTryNew, setShowTryNew] = useState(false);
  const newProgram = useSelector(state => state.programModify.programCreate);
  const [saving, setSaving] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    dispatch(getTermNamesActive());
  }, []);

  useEffect(() => {
    if (newProgram && newProgram.error) {
      setErrMsg(`Error creating new ${props.customAliases.alias_program.toLowerCase()}`);
      setSaving(false);
    }

    if (newProgram && newProgram.data && newProgram.data.id) {
      dispatch(createProgramModifyClear());
      setSaving(false);
      history.push(`/client/programs/${newProgram.data.id}/modify`);
    }
  }, [newProgram]);

  const handleTryNewClick = () => {
    setSaving(true);
    setErrMsg('');
    dispatch(createNewProgramModify());
  };
  useEffect(() => {
    if (permissions.includes('publish')) {
      selectOptions.push({ label: 'Publish', value: '2' }, { label: 'Unpublish', value: '3' });
    }
    if (permissions.includes('create_slash_edit_slash_duplicate') && permissions.includes('edit_published')) {
      selectOptions.push({ label: 'Add Term', value: '4' });
    }
  }, [selectOptions]);

  const selectedAction = action => {
    const findPrograms = [];
    selectedPrograms.forEach(program => {
      findPrograms.push(tableData[program.dataIndex].program_id);
    });

    setProgramIds(findPrograms);
    setSelectDefault({ label: `Actions`, value: '0' });

    switch (action.toString()) {
      case '1':
        break;
      case '2':
        setShowPublishModal(true);
        setSource('publish');
        setSelectDefault({ label: `Actions`, value: '0' });
        break;
      case '3':
        setShowPublishModal(true);
        setSource('unpublish');

        setSelectDefault({ label: `Actions`, value: '0' });
        break;
      case '4':
        setShowBatchAddTerm(true);

        setSelectDefault({ label: `Actions`, value: '0' });
        break;
    }
  };

  const onFilter = (filter, value) => {
    const setFilter = [
      {
        name: filter,
        filterList: value,
      },
    ];
    applyFilterToTableData(setFilter);
  };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (permissions.includes('view_as_admin')) {
        dispatch(fetchInternalPrograms());
      } else {
        setFilterPrograms([]);
        setTableData([]);
        setMessage([]);
      }
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    if (!!programs && !!programs.data && !programs.loading) {
      setLoading(false);
      if (!programs.data || programs.data.length < 1) {
        setLoadingMsg(`No internal ${customAliases.alias_programs.toLowerCase()} found`);
      }

      let formatData = _map(programs.data, attr => {
        const locations = emptyStringOrValue(attr.locations);
        const locArray = buildCountyAndCityArray(locations);
        const termTimings = buildTermTimings(attr.program_term_dates);

        const localtz = moment.tz.guess();
        const formatSyncAt = attr.sync_required ? 'Row Data Updating...' : 'Row Data Updated';

        const buildData = {
          program_id: attr.program_id,
          program_name: emptyStringOrValue(attr.program_name),
          locations: emptyStringOrValue(attr.locations),
          program_contact: emptyStringOrValue(attr.program_contact),
          program_contact_email: emptyStringOrValue(attr.program_contact_email),
          program_administrators: emptyStringOrValue(attr.program_administrators),
          program_administrator_emails: emptyStringOrValue(attr.program_administrator_emails),
          types: emptyStringOrValue(attr.types),
          status: emptyStringOrValue(attr.status.toLowerCase() === 'inactive' ? 'Archived' : attr.status),
          term_names: emptyStringOrValue(attr.program_term_names),
          term_dates: emptyStringOrValue(attr.program_term_dates),
          term_timing: emptyStringOrValue(termTimings),
          term_tags: emptyStringOrValue(attr.program_term_tags),
          application_total: emptyIntOrValue(attr?.application_total),
          program_notes: emptyStringOrValue(attr.program_notes_admin_only),
          authorizable: emptyStringOrValue(attr.authorizable),
          program_review: emptyStringOrValue(attr.program_review),
          program_reviewer_details: emptyStringOrValue(attr.program_reviewer_details),
          program_reviewer_name: emptyStringOrValue(attr.program_reviewer_name),
          language_immersion: emptyStringOrValue(attr.language_immersion),
          instructional_languages: emptyStringOrValue(attr.instructional_languages),
          language_and_location_information: emptyStringOrValue(
            stripHtmlString(attr.language_and_location_information),
          ),
          program_housing_types: emptyStringOrValue(attr.program_housing_types),
          housing_information: emptyStringOrValue(stripHtmlString(attr.housing_information)),
          program_subject_areas: emptyStringOrValue(attr.program_subject_areas),
          program_courses: emptyStringOrValue(stripHtmlString(attr.program_courses)),
          academics_information: emptyStringOrValue(stripHtmlString(attr.academics_information)),
          host_organization: emptyStringOrValue(stripHtmlString(attr.host_organization)),
          host_organization_information: emptyStringOrValue(stripHtmlString(attr.host_organization_information)),
          activities: emptyStringOrValue(stripHtmlString(attr.activities)),
          excursions: emptyStringOrValue(stripHtmlString(attr.excursions)),
          add_ons: emptyStringOrValue(stripHtmlString(attr.add_ons)),
          activities_information: emptyStringOrValue(stripHtmlString(attr.activities_information)),
          amenities: emptyStringOrValue(attr.not_displayed_on_brochure),
          not_displayed_on_brochure: emptyStringOrValue(attr.whats_not_included),
          whats_included: emptyStringOrValue(attr.whats_included),
          eligibility_requirements: emptyStringOrValue(stripHtmlString(attr.eligibility_requirements)),
          scholarship_or_financial_aid_opportunities: emptyStringOrValue(
            stripHtmlString(attr.scholarship_or_financial_aid_opportunities),
          ),
          public_program_url : stripHtmlString(`${window.location.host}/program_brochure/${attr.program_id}`),
          countries: locArray.countries,
          cities: locArray.cities,
          term_starts: emptyStringOrValue(attr.program_term_start_dates),
          term_ends: emptyStringOrValue(attr.program_term_end_dates),
          sync_required: attr.sync_required,
          synced_at: emptyStringOrValue(formatSyncAt),
        };
        return buildData;
      });

      if (tableState) {
        // update older term names to new structure
        const termNameItem = _find(tableState.buildSaveFilter, item => item.name === 'term_names');
        const termNameItemIndex = _findIndex(tableState.buildSaveFilter, item => item.name === 'term_names');

        if (
          termNameItem &&
          termNameItem.filterList &&
          termNameItem.filterList.length > 0 &&
          !['is', 'is not'].includes(termNameItem.filterList[0].toLowerCase())
        ) {
          const oldItems = termNameItem.filterList;
          tableState.buildSaveFilter[termNameItemIndex].filterList = [];
          tableState.buildSaveFilter[termNameItemIndex].filterList.push('is');
          tableState.buildSaveFilter[termNameItemIndex].filterList.push(oldItems);
        }
      }

      const savedFilters = _get(tableState, 'buildSaveFilter') || tableState;
      const savedSort = _get(tableState, 'sortOption') || tableSort;

      setFilterPrograms(formatData);
      applyFilterToTableData(savedFilters, savedSort);
      setTableData(formatData);
    }
  }, [programs]);

  const buildTermTimings = dates => {
    let timings = [];
    let timingsDisplay = '';
    if (dates) {
      let arrDateRanges = dates.split(';');
      arrDateRanges.forEach(range => {
        let arrRange = range.split(' - ');
        const programTiming = calcTiming(arrRange[0], arrRange[1]);
        timings.push(programTiming);
        timings = _uniq(timings);
        timings = _sortBy(timings);
      });
    }

    timingsDisplay = timings.join('; ');
    return timingsDisplay;
  };

  const applyFilterToTableData = (filter, sort = tableSort) => {
    if (filter) {
      // update older term names to new structure
      const termNameItem = _find(filter, item => item.name === 'term_names');
      const termNameItemIndex = _findIndex(filter, item => item.name === 'term_names');

      if (
        termNameItem &&
        termNameItem.filterList &&
        termNameItem.filterList.length > 0 &&
        !['is', 'is not'].includes(termNameItem.filterList[0].toLowerCase())
      ) {
        const oldItems = termNameItem.filterList;
        filter[termNameItemIndex].filterList = [];
        filter[termNameItemIndex].filterList.push('is');
        filter[termNameItemIndex].filterList.push(oldItems);
      }
    }

    const filteredCols = cols || tableColumns;
    const buildSaveFilter = [];

    if (filteredCols && filter && filter.length > 0) {
      filter.forEach(filterCol => {
        filteredCols.forEach(tblCol => {
          if (tblCol.name === filterCol.name) {
            if (filterCol.filterList !== undefined) {
              tblCol.options.filterList = filterCol.filterList;
            }
            if (filterCol.display !== undefined) {
              tblCol.options.display = filterCol.display;
            }
          }
        });
      });
    }

    filteredCols.forEach(tblCol => {
      buildSaveFilter.push({
        name: tblCol.name,
        display: tblCol.options.display,
        filterList: tblCol.options.filterList,
      });
    });

    const saveData = {
      buildSaveFilter: buildSaveFilter,
      sortOption: sort,
    };

    setTableSort(sort);
    setSavedFilters(saveData);
    localStorage.setItem(FILTER_TYPE_NAME, JSON.stringify(saveData));
    setCols(filteredCols);
  };

  const setMessage = data => {
    setAppCount(data.length);
    setLoadingMsg(data.length < 1 ? `No internal ${customAliases.alias_programs.toLowerCase()} found` : loadingMsg);
  };

  const getFilterSettings = () => {
    return savedFilters;
  };

  const tableColumns = [
    {
      name: 'program_id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'program_settings',
      label: 'Settings',
      options: {
        filter: false,
        sort: false,
        display: permissions.includes('view_as_admin') ? true : false,
        customBodyRender: (program_name, tableMeta, updateValue) => {
          return (
            <div className={sInternalPrograms.gearWrapper}>
              <a href={`/client/programs/${tableMeta.rowData[0]}/program_settings`}>
                <ViaIcon name="gear" color="secondary" size="small" />
              </a>
            </div>
          );
        },
      },
    },
    {
      name: 'program_name',
      label: `Name`,
      options: {
        filter: false,
        display: true,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length !== 2 || typeof filters[1] !== 'object' || filters[1].length < 1) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'program_name');
            const columnValues = column[index] && column[index].length ? column[index] : '';

            let arrNames = columnValues.split(';');
            let isFound = false;

            if (filters[0].toLowerCase() === 'is') {
              filters[1].forEach(filter => {
                isFound = isFound ? isFound : arrNames.includes(filter);
              });

              return !isFound;
            } else {
              filters[1].forEach(filter => {
                isFound = isFound ? isFound : arrNames.includes(filter);
              });

              return isFound;
            }
          },
        },
        customBodyRender: (program_name, tableMeta, updateValue) => {
          return (
            <a href={`/client/programs/${tableMeta.rowData[0]}`} style={{ color: '#007bff' }}>
              {program_name}
            </a>
          );
        },
      },
    },
    {
      name: 'locations',
      label: 'Locations',
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: 'program_contact',
      label: `${customAliases.alias_program} Contact`,
      options: {
        filter: false,
        display: true,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'program_contact');

            if (column[index] && column[index].length) {
              let arrContacts = column[index].split(';');
              let isFound = false;
              arrContacts = arrContacts.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrContacts.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'program_contact_email',
      label: `${customAliases.alias_program} Contact Email`,
      options: {
        filter: false,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'program_contact_email');

            if (column[index] && column[index].length) {
              let arrContacts = column[index].split(';');
              let isFound = false;
              arrContacts = arrContacts.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrContacts.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'program_administrators',
      label: `${customAliases.alias_program} Administrators`,
      options: {
        filter: true,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'program_administrators');

            if (column[index] && column[index].length) {
              let arrAdmins = column[index].split(';');
              let isFound = false;
              arrAdmins = arrAdmins.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrAdmins.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'program_administrator_emails',
      label: `${customAliases.alias_program} Administrator Emails`,
      options: {
        filter: true,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'program_administrator_emails');

            if (column[index] && column[index].length) {
              let arrAdmins = column[index].split(';');
              let isFound = false;
              arrAdmins = arrAdmins.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrAdmins.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'types',
      label: `Type`,
      options: {
        filter: true,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'types');

            if (column[index] && column[index].length) {
              let arrTypes = column[index].split(';');
              let isFound = false;
              arrTypes = arrTypes.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrTypes.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'status',
      label: `State`,
      options: {
        filter: true,
        display: true,
        filterList: [],
      },
    },
    {
      name: 'term_names',
      label: 'Term Names',
      options: {
        filter: true,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length !== 2 || typeof filters[1] !== 'object' || filters[1].length < 1) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'term_names');
            const columnValues = column[index] && column[index].length ? column[index] : 'Blank';
            let arrTermNames = columnValues.split(';');
            let isFound = false;
            arrTermNames = arrTermNames.map(string => string.trim());

            if (filters[0].toLowerCase() === 'is') {
              filters[1].forEach(filter => {
                isFound = isFound ? isFound : arrTermNames.includes(filter);
              });

              return !isFound;
            } else {
              filters[1].forEach(filter => {
                isFound = isFound ? isFound : arrTermNames.includes(filter);
              });

              return isFound;
            }
          },
        },
      },
    },
    {
      name: 'term_dates',
      label: 'Term Dates',
      options: {
        filter: true,
        display: false,
        filterList: [],
        sortCompare: order => ({ data: date1 }, { data: date2 }) => {
          const aDate = moment(moment(date1.split(';')[0].split('-')[0])).isValid()
            ? moment(date1.split(';')[0].split('-')[0])
            : null;
          const bDate = moment(moment(date2.split(';')[0].split('-')[0])).isValid()
            ? moment(date2.split(';')[0].split('-')[0])
            : null;

          if (aDate === bDate) return 0;
          if (!moment(aDate).isValid()) return -1 * (order === 'asc' ? 1 : -1);
          if (!moment(bDate).isValid()) return 1 * (order === 'asc' ? 1 : -1);
          return (aDate.isBefore(bDate) ? -1 : 1) * (order === 'asc' ? 1 : -1);
        },
      },
    },
    {
      name: 'term_timing',
      label: 'Term Timing',
      options: {
        filter: true,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'term_timing');

            if (column[index] && column[index].length) {
              let arrTimings = column[index].split(';');
              let isFound = false;
              arrTimings = arrTimings.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrTimings.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'term_tags',
      label: 'Term Tags',
      options: {
        filter: true,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'term_tags');

            if (column[index] && column[index].length) {
              let arrTags = column[index].split(';');
              let isFound = false;
              arrTags = arrTags.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrTags.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },

    {
      name: 'application_total',
      label: 'Application Total',
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: 'program_notes',
      label: `Notes`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'authorizable',
      label: 'Authorizable',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'program_review',
      label: `Review`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'program_reviewer_name',
      label: `Reviewer Name`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'program_reviewer_details',
      label: `Reviewer Details`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'language_immersion',
      label: 'Language Immersion',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'instructional_languages',
      label: 'Instructional Languages',
      options: {
        filter: false,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'instructional_languages');

            if (column[index] && column[index].length) {
              let arrLanguages = column[index].split(';');
              let isFound = false;
              arrLanguages = arrLanguages.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrLanguages.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'language_and_location_information',
      label: 'Language and Location Information',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'program_housing_types',
      label: `Housing Types`,
      options: {
        filter: false,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'program_housing_types');

            if (column[index] && column[index].length) {
              let arrProgHousingTypes = column[index].split(';');
              let isFound = false;
              arrProgHousingTypes = arrProgHousingTypes.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrProgHousingTypes.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'housing_information',
      label: 'Housing Information',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'program_subject_areas',
      label: `Subject Areas`,
      options: {
        filter: false,
        display: false,
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'program_subject_areas');

            if (column[index] && column[index].length) {
              let arrSubjectAreas = column[index].split(';');
              let isFound = false;
              arrSubjectAreas = arrSubjectAreas.map(string => string.trim());
              filters.forEach(filter => {
                isFound = isFound ? isFound : arrSubjectAreas.includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'program_courses',
      label: `Courses`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'academics_information',
      label: `Academics Information`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'host_organization',
      label: `Host Organization`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'host_organization_information',
      label: `Host Organization Information`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'activities',
      label: `Activities`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'excursions',
      label: `Excursions`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'add_ons',
      label: `Add-ons`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'activities_information',
      label: `Activities Information`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'whats_included',
      label: `What's Included`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'amenities',
      label: `What's Not Included`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'not_displayed_on_brochure',
      label: `Not Displayed on Brochure`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'eligibility_requirements',
      label: `Eligibility Requirements`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'scholarship_or_financial_aid_opportunities',
      label: `${customAliases.alias_program} Cost Information`,
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'public_program_url',
      label: `Public program URL`,
      options: {
        filter: false,
        display: false,
        filterList: [],
      },
    },
    {
      name: 'countries',
      label: `countries`,
      options: {
        filter: false,
        display: 'excluded',
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'countries');

            if (column[index] && column[index].length) {
              let isFound = false;
              filters.forEach(filter => {
                isFound = isFound ? isFound : column[index].includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'cities',
      label: `cities`,
      options: {
        filter: false,
        display: 'excluded',
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'cities');

            if (column[index] && column[index].length) {
              let isFound = false;
              filters.forEach(filter => {
                isFound = isFound ? isFound : column[index].includes(filter);
              });

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'term_starts',
      label: `term_starts`,
      options: {
        filter: false,
        display: 'excluded',
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const filterType = filters[0];
            const filterSingle = filters[1];
            const filterFrom = filters[2];
            const filterTo = filters[3];

            let validFilter =
              (filterType === 'is_between' &&
                moment(filterFrom || null).isValid() &&
                moment(filterTo || null).isValid()) ||
              moment(filterSingle || null).isValid()
                ? true
                : false;

            if (!validFilter) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'term_starts');

            if (column[index] && column[index].length) {
              let isFound = false;

              if (filterType === 'is') {
                isFound = isFound ? isFound : column[index].includes(filterSingle);
              }
              if (filterType === 'is_between') {
                let arrStarts = column[index].split('; ');
                arrStarts.forEach(date => {
                  isFound = isFound
                    ? isFound
                    : moment(date).isBetween(moment(filterFrom), moment(filterTo), undefined, '[]');
                });
              }
              if (filterType === 'is_greater') {
                let arrStarts = column[index].split('; ');
                arrStarts.forEach(date => {
                  isFound = isFound ? isFound : moment(date).isSameOrAfter(moment(filterSingle));
                });
              }
              if (filterType === 'is_less') {
                let arrStarts = column[index].split('; ');
                arrStarts.forEach(date => {
                  isFound = isFound ? isFound : moment(date).isSameOrBefore(moment(filterSingle));
                });
              }

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    {
      name: 'term_ends',
      label: `term_ends`,
      options: {
        filter: false,
        display: 'excluded',
        filterList: [],
        filterType: 'custom',
        filterOptions: {
          names: [],
          logic(count, filters, column) {
            if (!filters || filters.length === 0) {
              return false;
            }

            const filterType = filters[0];
            const filterSingle = filters[1];
            const filterFrom = filters[2];
            const filterTo = filters[3];

            let validFilter =
              (filterType === 'is_between' &&
                moment(filterFrom || null).isValid() &&
                moment(filterTo || null).isValid()) ||
              moment(filterSingle || null).isValid()
                ? true
                : false;

            if (!validFilter) {
              return false;
            }

            const index = tableColumns.findIndex(item => item.name === 'term_ends');

            if (column[index] && column[index].length) {
              let isFound = false;

              if (filterType === 'is') {
                isFound = isFound ? isFound : column[index].includes(filterSingle);
              }
              if (filterType === 'is_between') {
                let arrStarts = column[index].split('; ');
                arrStarts.forEach(date => {
                  isFound = isFound
                    ? isFound
                    : moment(date).isBetween(moment(filterFrom), moment(filterTo), undefined, '[]');
                });
              }
              if (filterType === 'is_greater') {
                let arrStarts = column[index].split('; ');
                arrStarts.forEach(date => {
                  isFound = isFound ? isFound : moment(date).isSameOrAfter(moment(filterSingle));
                });
              }
              if (filterType === 'is_less') {
                let arrStarts = column[index].split('; ');
                arrStarts.forEach(date => {
                  isFound = isFound ? isFound : moment(date).isSameOrBefore(moment(filterSingle));
                });
              }

              return !isFound;
            } else {
              return true;
            }
          },
        },
      },
    },
    { name: 'sync_required', label: 'sync_required', options: { filter: false, display: 'excluded' } },
    {
      name: 'synced_at',
      label: 'Row Data Status',
      options: {
        filter: false,
        display: false,
        setCellProps: () => ({
          style: {
            minWidth: '350px',
            maxWidth: '350px',
          },
        }),
        customBodyRender: (synced_at, tableMeta) => {
          const index = tableColumns.findIndex(item => item.name === 'sync_required');
          return <div style={tableMeta.rowData[index] ? { fontStyle: 'italic' } : null}>{synced_at}</div>;
        },
      },
    },
  ];

  const options = {
    customSearchRender: debounceSearchRenderDashboard(DEBOUNCE_VALUE),
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    responsive: 'scroll',
    fixedHeader: true,
    filterOptions: { display: false },
    sortOrder: tableSort,
    downloadOptions: {
      filename: `internal_${customAliases.alias_programs}.csv`,
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    disableToolbarSelect: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    search: false,
    searchPlaceholder: `Enter keyword to search internal ${customAliases.alias_programs.toLowerCase()}...`,
    print: false,
    textLabels: {
      body: {
        noMatch: loadingMsg,
      },
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      setSelectedPrograms(allRowsSelected);
    },
    filter: false,
    onTableChange: (action, tableState) => {
      const keyUpdates = ['filterChange', 'search', 'resetFilters', 'viewColumnsChange', 'sort'];
      if (keyUpdates.includes(action)) {
        const saveState = {
          columns: tableState.columns,
          filters: tableState.filterList,
          search: tableState.searchText,
          sortOrder: tableState.sortOrder,
        };

        saveState.columns.forEach((col, index) => {
          col.filterList = saveState.filters[index];
        });

        applyFilterToTableData(saveState.columns, saveState.sortOrder);
      }

      if (
        action === 'propsUpdate' &&
        selectedPrograms.length === 0 &&
        tableState.selectedRows.data.length !== selectedPrograms.length
      ) {
        setSelectedPrograms([]);
        tableState.previousSelectedRow = null;
        tableState.selectedRows.data = [];
        tableState.selectedRows.lookup = {};
      }

      if (!loading) {
        setMessage(tableState.displayData);
      }
    },
    setRowProps: (row, index) => {
      index = tableColumns.findIndex(item => item.name === 'sync_required');
      return {
        style: {
          backgroundColor: row[index] ? '#f0ecd9' : '',
        },
      };
    },
  };

  return (
    <div className={sInternalPrograms['internal-programs']}>
      <UserPilotScript />
      <div className={sInternalPrograms['filter-container']}>
        <FilterNav>
          <LeftNavFilters
            change={(filter, value) => {
              onFilter(filter, value);
            }}
            programs={filterPrograms}
            savedFilters={savedFilters}
            filterType={FILTER_TYPE_NAME}
            filters={getFilterSettings}
            selectedFilter={applyFilterToTableData}
          />
        </FilterNav>
      </div>
      <div className={sInternalPrograms['content-wrapper']}>
        <GlobalSubHeader traveler={false} />
        <div className={sInternalPrograms['main-content']}>
          <div className={sInternalPrograms['header']}>
            <Headline tag="h1" as="h1">
              {`Internal ${customAliases.alias_programs}`}
            </Headline>
            <div className={sInternalPrograms['count']}>
              <Paragraph italic>
                {appCount} {appCount === 1 ? `${customAliases.alias_program}` : `${customAliases.alias_programs}`}
              </Paragraph>
            </div>
          </div>
          <div className={sInternalPrograms['button-wrapper']}>
            <div className={sInternalPrograms['action-select']}>
              <GenericActionsSelect
                value={selectDefault}
                options={_uniq(selectOptions)}
                isDisabled={!selectedPrograms || selectedPrograms.length < 1}
                onChange={e => {
                  selectedAction(e.value);
                }}
                placeholder="Actions"
              />
            </div>
            {permissions.includes('view_as_admin') && permissions.includes('create_slash_edit_slash_duplicate') ? (
              <div className={sInternalPrograms['button-content']}>
                {/* <a href={`/client/programs/new`}> */}
                <Button
                  display="dashboardActionDisplay"
                  kind="solid"
                  size="dashboardActionSize"
                  ariaLabel={`Create new ${customAliases.alias_program}`}
                  onClick={() => {
                    handleTryNewClick();
                  }}
                >
                  <ViaIcon
                    name="plus"
                    color="white"
                    size="xsmall"
                    ariaLabel={`Create new ${customAliases.alias_program}`}
                  />
                  &nbsp;
                  {`Create ${customAliases.alias_program}`}
                </Button>
                {/* </a> */}
              </div>
            ) : null}
          </div>
        </div>
        <div className={sInternalPrograms['table']}>
          <MuiThemeProvider theme={viaMuiTheme(customBranding, true)}>
            <MUIDataTable title="" data={tableData} columns={cols} options={options} responsive="scrollFullHeight" />
          </MuiThemeProvider>
        </div>
      </div>
      {/* <TryNew
        customAliases={customAliases}
        show={showTryNew}
        onClose={() => {
          setShowTryNew(false);
        }}
      /> */}
      <BatchAddTerm
        show={showBatchAddTerm}
        onClose={() => {
          setShowBatchAddTerm(false);
        }}
        selectedPrograms={programIds}
        programIds={programIds}
        customAliases={customAliases}
        setSelectedPrograms={() => {
          setProgramIds([]);
          setSelectedPrograms([]);
        }}
      />
      <BatchUpdateProgramState
        show={showPublishModal}
        onClose={() => {
          setShowPublishModal(false);
        }}
        paragraphOne={
          source === 'publish'
            ? `You are about to publish ${selectedPrograms.length}  ${
                selectedPrograms.length > 1
                  ? customAliases.alias_programs.toLowerCase()
                  : customAliases.alias_program.toLowerCase()
              }. Published ${customAliases.alias_programs.toLowerCase()} are visible to your ${customAliases.alias_travelers.toLowerCase()}. To add or edit ${customAliases.alias_program.toLowerCase()} settings so that ${customAliases.alias_travelers.toLowerCase()} can apply, go to ${customAliases.alias_program.toLowerCase()} settings or add terms in batch.`
            : `You are about to unpublish ${selectedPrograms.length}  ${
                selectedPrograms.length > 1
                  ? customAliases.alias_programs.toLowerCase()
                  : customAliases.alias_program.toLowerCase()
              }. Unpublished ${customAliases.alias_programs.toLowerCase()} are not visible to your  ${customAliases.alias_travelers.toLowerCase()}.
        `
        }
        paragraphTwo={`Are you sure you want to ${source === 'publish' ? 'publish' : 'unpublish'}?`}
        cancelMessage="Cancel"
        confirmActionMessage={source === 'publish' ? 'Publish' : 'Unpublish'}
        headline={source === 'publish' ? 'Publish' : 'Unpublish'}
        source={source}
        selectedPrograms={programIds}
        actionState={source === 'publish' ? publishInternalPrograms : unpublishInternalPrograms}
        publishSuccessMessage={`${selectedPrograms.length} ${
          selectedPrograms.length > 1
            ? `${customAliases.alias_programs.toLowerCase()}`
            : `${customAliases.alias_program.toLowerCase()}`
        } ${source === 'publish' ? 'published' : 'unpublished'}`}
        publishFailureMessage={`Hmmm... Looks like one or more of your selected ${customAliases.alias_programs.toLowerCase()} is not ready to be published. Please confirm that your selected ${
          selectedPrograms.length > 1
            ? `${customAliases.alias_programs.toLowerCase()} have`
            : `${customAliases.alias_program.toLowerCase()} has`
        } all required fields completed prior to publishing.`}
        failureMessage={`Unable to unpublish ${
          selectedPrograms.length > 1
            ? `${customAliases.alias_programs.toLowerCase()}`
            : `${customAliases.alias_program.toLowerCase()}`
        }`}
        setSelectedPrograms={() => {
          setProgramIds([]);
          setSelectedPrograms([]);
        }}
      />
    </div>
  );
}

export default enhance(InternalPrograms);
