import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import { Paragraph } from '../../../../../styledComponents/styles/Paragraph.styled';
import EmailCheck from '../../../../../styledComponents/styles/EmailCheck.styled';
import RecommendationRequestionModal from '../../../pages/admin/modals/RecommendationRequest.modal';
import CancelRecommendationRequestModal from '../../../pages/admin/modals/CancelRecommendationRequest.modal';
import ResendRecommendationRequestModal from '../../../pages/admin/modals/ResendRecommendationRequest.modal';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';

const Container = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 13px 0;
  text-align: left;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textInput.label.fontColor};
  font-family: ${({ theme }) => theme.textInput.label.fontFamily};
  font-size: ${({ theme }) => theme.textInput.label.fontSize};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const ResponseList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const Col1 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Col2 = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: end;
`;

const EmailRow = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #373737;
`;

const SentRow = styled.div`
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-style: italic;
  color: #555555;
`;

const OptionsRow = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

const OptionItem = styled.span`
  color: #446372;
  cursor: pointer;
  text-decoration: underline;

  a {
    color: #446372;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const OptionItemCancel = styled.span`
  color: #8b1313;
  cursor: pointer;
  text-decoration: underline;
`;

const OptionItemCancelled = styled.span`
  color: #555555;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;
  svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }
`;

export default function QuestionRecommendationDisplayForm({
  question,
  formState,
  errors = {},
  setValue,
  value,
  control,
  defaultValue,
  response,
  formId,
  isReadonly,
  isAdmin = false,
  ...rest
}) {
  const sendRecommendationMailer = useSelector(state => state.forms.sendRecommendationMailer);
  const cancelRecommendationMailer = useSelector(state => state.forms.cancelRecommendationMailer);
  const resendRecommendationMailer = useSelector(state => state.forms.resendRecommendationMailer);
  const [recommenderEmail, setRecommenderEmail] = useState(null);
  const [showCancelRequest, setShowCancelRequest] = useState(false);
  const [showRecommendationRequest, setShowRecommendationRequest] = useState(false);
  const [showResendRecommendationRequest, setShowResendRecommendationRequest] = useState(false);
  const [cancelEmail, setCancelEmail] = useState(null);
  const [resendEmail, setResendEmail] = useState(null);
  const [emailResponses, setEmailResponses] = useState(null);
  const id = question.question_id.toString();
  const errorMsg = _get(errors[id], 'message') || '';

  useEffect(
    () => {
      if (response && response.recommendation_requests) {
        let requests = response.recommendation_requests;

        requests.sort(function(a, b) {
          return new Date(b.sent_at) - new Date(a.sent_at);
        });

        setEmailResponses(requests);

        if (response !== defaultValue) {
          setTimeout(() => {
            setValue(id, requests, {
              shouldValidate: false,
              shouldTouch: false,
              shouldDirty: false,
            });
          }, 1);
        }
      }
    },
    [response],
  );

  useEffect(
    () => {
      let newResponses = [];
      if (
        sendRecommendationMailer?.data &&
        !sendRecommendationMailer.error &&
        sendRecommendationMailer.data.attributes?.question_id === question.question_id
      ) {
        sendRecommendationMailer.data.attributes.recommendation_requests.sort(function(a, b) {
          return new Date(b.sent_at) - new Date(a.sent_at);
        });
        setEmailResponses(sendRecommendationMailer.data.attributes.recommendation_requests);

        setTimeout(() => {
          setValue(id, sendRecommendationMailer.data.attributes.recommendation_requests, {
            shouldValidate: true,
            shouldTouch: false,
            shouldDirty: false,
          });
        }, 1);
      }
      if (cancelRecommendationMailer?.data && !cancelRecommendationMailer.error) {
        newResponses = _cloneDeep(emailResponses);

        const index = _findIndex(newResponses, { id: cancelRecommendationMailer.data.attributes.id });

        if (index > -1) {
          newResponses.splice(index, 1, cancelRecommendationMailer.data.attributes);
          newResponses.sort(function(a, b) {
            return new Date(b.sent_at) - new Date(a.sent_at);
          });

          setEmailResponses(newResponses);

          setTimeout(() => {
            setValue(id, newResponses, {
              shouldValidate: true,
              shouldTouch: false,
              shouldDirty: false,
            });
          }, 1);
        }
      }
      if (resendRecommendationMailer?.data && !resendRecommendationMailer.error) {
        newResponses = _cloneDeep(emailResponses);

        const index = _findIndex(newResponses, { id: resendRecommendationMailer.data.attributes.id });

        if (index > -1) {
          newResponses.splice(index, 1, resendRecommendationMailer.data.attributes);
          newResponses.sort(function(a, b) {
            return new Date(b.sent_at) - new Date(a.sent_at);
          });
          setEmailResponses(newResponses);
          setTimeout(() => {
            setValue(id, newResponses, {
              shouldValidate: false,
              shouldTouch: false,
              shouldDirty: false,
            });
          }, 1);
        }
      }
    },
    [sendRecommendationMailer, cancelRecommendationMailer, resendRecommendationMailer],
  );

  useEffect(
    () => {
      if (defaultValue?.recommendation_requests) {
        setTimeout(() => {
          setValue(id, defaultValue.recommendation_requests, {
            shouldValidate: false,
            shouldTouch: false,
            shouldDirty: false,
          });
        }, 1);
      }
    },
    [defaultValue],
  );

  const buildResponseList = (request, index) => {
    const isComplete = !!request.completed_at;
    //Default to sent string
    let emailSentBy = `Request sent to: ${request.user_email}`;
    if (request.resent) {
      //default resent message
      emailSentBy = `Resent to ${request.user_email} at ${moment(new Date(request.sent_at)).format('MMM D, YYYY')}`;
      if (request.cancelled_at) {
        //if resent canceled, show canceled message
        emailSentBy = `Canceled resend request to ${request.user_email} at ${moment(new Date(request.sent_at)).format(
          'MMM D, YYYY',
        )}`;
      }
    }

    return (
      <ResponseList key={index}>
        <Col1>
          {isComplete ? (
            <>
              <EmailRow>
                <StyledIcon type="Form_multiple_choice" color="#0E5B2F" size="14px" />
                {`  Completed by: ${request.user_email}`}
              </EmailRow>
              <SentRow>Completed on {moment(new Date(request.completed_at)).format('MMM D, YYYY')}</SentRow>
            </>
          ) : (
            <>
              <EmailRow>{emailSentBy}</EmailRow>
              {!request.cancelled_at &&
                !request.resent && <SentRow>Sent on {moment(new Date(request.sent_at)).format('MMM D, YYYY')}</SentRow>}
            </>
          )}
        </Col1>
        <Col2>
          <OptionsRow>
            {!request.cancelled_at &&
              !request.completed_at && (
                <>
                  <OptionItem
                    tabIndex={0}
                    role="button"
                    aria-label="Resend Recommendation"
                    onClick={() => {
                      setResendEmail(request);
                      setShowResendRecommendationRequest(true);
                    }}
                  >
                    Resend
                  </OptionItem>{' '}
                  {!isReadonly && (
                    <>
                      |{' '}
                      <OptionItemCancel
                        tabIndex={0}
                        role="button"
                        aria-label="Cancel Recommendation"
                        onClick={() => {
                          setCancelEmail(request);
                          setShowCancelRequest(true);
                        }}
                      >
                        Cancel
                      </OptionItemCancel>
                    </>
                  )}
                </>
              )}
            {!!request.completed_at &&
              request.token?.length > 0 &&
              isAdmin && (
                <OptionItem>
                  <a href={`/visitor/recommendation_submissions/${request.token}?admin=${isAdmin}`} target="_blank">
                    View
                  </a>
                </OptionItem>
              )}
            {!!request.cancelled_at && !request.completed_at && <OptionItemCancelled>Cancelled</OptionItemCancelled>}
          </OptionsRow>
        </Col2>
      </ResponseList>
    );
  };
  const updatedInstruction = !!question.applicant_instructions ? question.applicant_instructions.replace(/<p><\/p>/g, '<br />') : '';
  return (
    <Container>
      <InputContainer>
        <Label htmlFor={question.id}>
          {question.label}
          {question.required && <Required>*</Required>}
        </Label>
        <Paragraph
          //aria-label={question.label}
          id="recommendation_instructions_applicant"
          openSans
          dangerouslySetInnerHTML={{
            __html: updatedInstruction,
          }}
        />
        <EmailCheck
          aria-label={question.label}
          id="recommendation-email"
          label={`Recommender's Email`}
          placeholder={`Enter recommender's email`}
          buttonText="Send"
          readOnly={isReadonly}
          openSans
          onClick={value => {
            if (value && value.searchedEmail) {
              setRecommenderEmail(value.searchedEmail);
              setShowRecommendationRequest(!showRecommendationRequest);
            }
          }}
        />
        {errorMsg?.length > 0 && (
          <Error>
            <StyledIcon type="Warning" size="16px" />
            <span aria-live="assertive" role="alert">{errorMsg}</span>
          </Error>
        )}
      </InputContainer>
      {emailResponses?.map((request, index) => buildResponseList(request, index))}
      {showRecommendationRequest && formId ? (
        <RecommendationRequestionModal
          question={question}
          showing={showRecommendationRequest}
          requestedRecommender={recommenderEmail}
          setShowRecommendationRequest={() => setShowRecommendationRequest(false)}
          formId={formId}
        />
      ) : null}
      {showCancelRequest ? (
        <CancelRecommendationRequestModal
          showing={showCancelRequest}
          cancelEmail={cancelEmail}
          setShowCancelRequest={() => setShowCancelRequest(false)}
        />
      ) : null}
      {showResendRecommendationRequest ? (
        <ResendRecommendationRequestModal
          showing={showResendRecommendationRequest}
          cancelEmail={resendEmail}
          setShowResendRecommendationRequest={() => setShowResendRecommendationRequest(false)}
        />
      ) : null}
    </Container>
  );
}
