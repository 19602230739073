import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioInput from '../../base/inputs/RadioInput';
import { RadioGrid } from '../../base/inputs/InputGrids.styled';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import userId from '../../../../../shared/helpers/userId';
import passportStatuses from '../../../utils/intake/passportStatuses';
import { addIntakePassport, addAdminIntakeFormData } from '../../../actions/intakeFormActions';

const RadioGridColumn = styled.div`
  grid-column: span 2 / span 2;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default function IntakePassport(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const question = {
    overline: 'Passport',
    heading: 'What is the current status of your passport?',
    subheading: 'Select one option.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="passport").required ?? false,
  };

  useEffect(
    () => {
      if(props?.isAdmin) {
        const initialValue = adminIntakeFormData?.passport === 'not_provided' ? '' : adminIntakeFormData?.passport;
        setValue(initialValue);
      } else {
        const initialValue = intake.passport?.status === 'not_provided' ? '' : intake.passport.status;
        setValue(initialValue);
      }
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    
    setError("");
    if (!value && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "passport", value));
      props.handleAdminStep();
      return;
    }
    dispatch(addIntakePassport(userId, { status: value }));
  };

  return (
    <TravelerFormQuestionContainer 
      isAdmin={props.isAdmin} 
      question={question} 
      handleBackStep={()=>props.handleAdminBackStep()} 
      adminIntakeStep={props.adminIntakeStep}
      handleNext={handleNext} 
      error={error} 
      moreErrorMargin
    >
      <RadioGrid role="radiogroup" aria-labelledby="role-label">
        <label id="role-label" style={{display: "none"}}>Select one passport option that applies Required</label>
        {passportStatuses.map(status => (
          <RadioGridColumn key={`passport-status-${status.id}`}>
            <RadioInput
              value={status.value}
              fullWidth={true}
              label={status.label}
              icon={status.icon}
              selected={value}
              handleOnChange={() => {
                setValue(status.value);
              }}
            />
          </RadioGridColumn>
        ))}
      </RadioGrid>
    </TravelerFormQuestionContainer>
  );
}
