import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from '../../base/inputs/SelectInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeQuestions, addAdminIntakeFormData } from '../../../actions/intakeFormActions';

export default function IntakeFirstGenerationStatus(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const question = {
    overline: 'First-Generation College Student',
    heading: 'Are you a first-generation college student?',
    subheading: 'Select one option.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="first_generation_status").required ?? false,
  };

  const options = [
    {label:"Yes",value:"yes"},
    {label:"No",value:"no"},
    {label:"I’m Not Certain",value:"not_certain"},
    {label:"Prefer Not to Answer",value:"prefer_not_answer"},
  ]

  useEffect(
    () => {
      if(props?.isAdmin) {
        const initialValue = options.filter(item => adminIntakeFormData?.first_generation_status===item.value);
        setValue(initialValue);
      } else {
        const initialValue = options.filter(item => intake.first_generation_status===item.value);
        setValue(initialValue);
      }
      setDisabled(intake.ssoTraveler && intake.readonly.includes('first_generation_status'));
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
   
    setError("");
    if (!disabled && value.length === 0 && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "first_generation_status", Array.isArray(value) ? value[0]?.value : value?.value));
      props.handleAdminStep();
      return;
    }
    if (!disabled) {
       dispatch(addIntakeQuestions(userId, { first_generation_status: Array.isArray(value) ? value[0]?.value : value?.value },"first_generation_status"));
    }
  };

  const handleOnChange = event => {
    if (event) {
      setValue(event);
    } else {
      setValue([]);
    }
  };

  return (
    <TravelerFormQuestionContainer isAdmin={props.isAdmin} question={question} 
      handleBackStep={()=>props.handleAdminBackStep()} 
      adminIntakeStep={props.adminIntakeStep}
      handleNext={handleNext} 
      disabled={disabled} canBeSynced>
      <SelectInput
        value={value}
        defaultValue={value}
        id="first_generation_status"
        label="First-Generation Status"
        placeholder="Select first-generation status"
        options={options}
        error={error}
        disabled={disabled}
        required = {question.isRequired}
        handleOnChange={handleOnChange}
      />
    </TravelerFormQuestionContainer>
  );
}
