import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';
import ScreenReaderHeading from '../../../travelerProfile/components/base/ScreenReaderHeading.styled';
const QuestionOverline = styled.span`
  color: var(--secondary-vt-darkest, #446372);
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.005em;

  display: block;
  margin-bottom: 0.75rem;
  
  @media ${breakpoints.tablet} {
    //margin-bottom: 2.6875rem;
    font-size: 1rem;
  }
`;

const QuestionHeading = styled.div`

  color: var(--black, #373737);
  font-family: Nunito;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  margin-bottom: 0.375rem;
  @media ${breakpoints.tablet} {
    font-size: 1.563rem;
  }
`;

const QuestionSubheading = styled.p`
  color: var(--gray-dark, #474747);
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.005em;  
`;

const RequiredField = styled.span`
  color: #770711;
  align-self: flex-start;
`;

export default function TravelerFormQuestion({ overline, heading, subheading, isRequired,isSubheadingRead }) {
  useEffect(()=>{
    document.getElementById("headingIntakeForm").focus();
  },[])
  return (
    <div>
      <ScreenReaderHeading  id="headingIntakeForm" tabIndex="0">
        {`${heading} ${isSubheadingRead ? subheading : ""} ${isSubheadingRead && isRequired ? "Required" : ""}`}
      </ScreenReaderHeading>
      <QuestionOverline>{overline}</QuestionOverline>
      <QuestionHeading>{heading}
        {isRequired && <RequiredField role="status" aria-label='Required'>&#42;</RequiredField>}
      </QuestionHeading>
      <QuestionSubheading>
        {subheading}
      </QuestionSubheading>
    </div>
  );
}

TravelerFormQuestion.propTypes = {
  isRequired: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  overline: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};

TravelerFormQuestion.defaultProps = {
  isRequired: false,
};
