import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import PageWrapper from './base/PageWrapper';
import CommitModal from './modals/CommitModal';
import ExternalLinkIcon from './icons/ExternalLinkIcon';
import CommitBanner from './my-programs/details/CommitBanner';
import DeferApplicationModal from './modals/DeferApplicationModal';
import WithdrawApplicationModal from './modals/WithdrawApplicationModal';
import RequestToWithdrawApplicationModal from './modals/RequestToWithdrawApplicationModal';
import ProgramDetailsTerms from './my-programs/details/ProgramDetailsTerms';
import ProgramDetailsActions from './my-programs/details/ProgramDetailsActions';
import ProgramDetailsDocuments from './my-programs/details/ProgramDetailsDocuments';
import ProgramDetailsBreadcrumbs from './my-programs/details/ProgramDetailsBreadcrumbs';
import RequestToWithdrawPending from './my-programs/details/RequestToWithdrawPending';

import { breakpoints } from '../utils/breakpoints';
import RequestToWithdraw from '../../../components/RequestToWithdraw';

const HeaderContainer = styled.div`
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;

  @media ${breakpoints.tablet} {
    padding: 0;
  }

  @media ${breakpoints.desktop} {
    margin-bottom: 0;

    .program-details-terms {
      display: none;
    }
  }
`;

const ContainerDiv = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.72125rem;
  margin-top: 1.16125rem;
  margin-bottom: 0.58875rem;

  @media ${breakpoints.desktop} {
    margin-bottom: 1.9375rem;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1.5625rem;
  color: #373737;
  line-height: 120%;
`;

const ProgramLink = styled.a`
  @media ${breakpoints.desktop} {
    display: none;
  }
`;

const ProgramImage = styled.img`
  height: 120px;
  object-fit: cover;
  object-position: center;

  @media ${breakpoints.desktop} {
    height: 305px;
  }
`;

const TravelerProgramDetails = ({ legacyWrapper, isAdmin }) => {
  const travelerSubmission = useSelector(state => state.forms.travelerSubmission);
  const [submission, setSubmission] = useState(travelerSubmission?.data);
  const [status, setStatus] = useState('');
  const [showRequestToWithdrawPending, setShowRequestToWithdrawPending] = useState(false);
  const { showDeferModal, showWithdrawModal } = useSelector(state => state.travelerApplicationSubmissions);
  const [travelerWithdrawRequest, setTravelerWithdrawRequest] = useState({
    id: '',
    status: '',
    reason: '',
    date: '',
  });

  useEffect(() => {
    if (travelerSubmission && travelerSubmission?.requestToWithdrawData) {
      if (travelerSubmission?.requestToWithdrawData?.active) {
        if (
          travelerSubmission?.submissionWithdrawalRequests &&
          Array.isArray(travelerSubmission.submissionWithdrawalRequests) &&
          travelerSubmission.submissionWithdrawalRequests?.length > 0
        ) {
          let requestStatus =
            travelerSubmission?.submissionWithdrawalRequests[
              travelerSubmission?.submissionWithdrawalRequests.length - 1
            ];

          setTravelerWithdrawRequest({
            id: requestStatus?.id,
            status: requestStatus?.status,
            reason: requestStatus?.reason,
            date: requestStatus?.created_at,
          });
        }
      }
    }
  }, [travelerSubmission]);

  useEffect(() => {
    if (!travelerSubmission.loading && travelerSubmission.data) {
      setStatus(travelerSubmission.data.submission_status.name);
      setSubmission(travelerSubmission.data);

      if (travelerSubmission?.requestToWithdrawData?.active) {
        if (travelerSubmission?.submissionWithdrawalRequests?.length > 0) {
          let withdrawalRequests = travelerSubmission?.submissionWithdrawalRequests;
          let requestStatus = withdrawalRequests[withdrawalRequests.length - 1]?.status;

          if (requestStatus === 'pending') {
            setShowRequestToWithdrawPending(true);
          }
        }
      }
    }
  }, [travelerSubmission]);

  return (
    submission && (
      <ContainerDiv>
        <PageWrapper routeSegment="my-programs" legacyWrapper={legacyWrapper}>
          <HeaderContainer>
            <ProgramDetailsBreadcrumbs isAdmin={isAdmin} programName={submission.program_range.program_title} />
            <HeadingContainer >
              <Heading tabIndex={0}>{submission.program_range.program_title}</Heading>
              <ProgramLink
                href={`${isAdmin ? '/client' : ''}/program_brochure/${submission.program_range.program_id}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="View Program"
                title="View Program"
              >
                <ExternalLinkIcon />
              </ProgramLink>
            </HeadingContainer>
            <ProgramDetailsTerms />
          </HeaderContainer>
          {['Accepted', 'Committed'].includes(status) && <CommitBanner isAdmin={isAdmin} />}
          {!isAdmin && showRequestToWithdrawPending && <RequestToWithdrawPending />}

          {isAdmin && travelerWithdrawRequest.status === 'pending' && (
            <RequestToWithdraw
              submissionId={travelerSubmission?.data?.id}
              submissionWithdrawalRequestId={travelerWithdrawRequest.id}
              reason={travelerWithdrawRequest.reason}
              date={travelerWithdrawRequest.date}
            />
          )}
          <ProgramImage src={submission.program_range.program_image.url} alt="Program Image" aria-hidden="true" />
          <ProgramDetailsActions isAdmin={isAdmin} />
          {submission && <ProgramDetailsDocuments />}
          <CommitModal isAdmin={isAdmin} />
          {showWithdrawModal && 
            <WithdrawApplicationModal />
          }
          {showDeferModal && 
            <DeferApplicationModal />
          }
          <RequestToWithdrawApplicationModal />
        </PageWrapper>
      </ContainerDiv>
    )
  );
};

TravelerProgramDetails.propTypes = {
  legacyWrapper: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

TravelerProgramDetails.defaultProps = {
  legacyWrapper: false,
  isAdmin: false,
};

export default TravelerProgramDetails;
