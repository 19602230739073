import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioInput from '../../base/inputs/RadioInput';
import TravelerFormQuestionContainer from '../../base/TravelerFormQuestionContainer';

import { breakpoints } from '../../../utils/breakpoints';
import userId from '../../../../../shared/helpers/userId';
import { addIntakeFinancialAid, addAdminIntakeFormData } from '../../../actions/intakeFormActions';
import financialAidOptions from '../../../utils/intake/financialAidOptions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  @media ${breakpoints.tablet} {
    margin-top: 1.75rem;
  }
`;

export default function IntakeFinancialAid(props) {
  const dispatch = useDispatch();
  const intake = useSelector(state => state.intakeForm);
  const adminIntakeFormData = intake.adminIntakeFormData;
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const question = {
    overline: 'Financial Aid',
    heading: 'Do you receive financial aid or scholarships from the government or an organization?',
    subheading: 'Select one option.',
    isRequired: intake?.intakeQuestions?.find(item=>item.identifier=="traveler_goals").required ?? false,
  };

  useEffect(
    () => {
      if(props?.isAdmin) {
        setValue(adminIntakeFormData?.financial_aid);   
      } else {
        setValue(intake.receives_federal_financial_aid);
      }
      
    },
    [intake, adminIntakeFormData],
  );

  const handleNext = () => {
    
    setError("");
    if ((!value || value === 'not_provided') && question.isRequired) {
      setTimeout(()=>{
        setError('Required, please select an option.');
      },0) 
      if(props.isAdmin) {
        return;
      }
      throw new Error();
    }
    if(props.isAdmin) {
      dispatch(addAdminIntakeFormData( "financial_aid", value));
      props.handleAdminStep();
      return;
    }
    dispatch(addIntakeFinancialAid(userId, value));
  };

  return (
    <TravelerFormQuestionContainer
      question={question}
      handleNext={handleNext}
      error={error}
      moreErrorMargin
      desktopErrorMargin
      isAdmin={props.isAdmin}
      handleBackStep={()=>props.handleAdminBackStep()}
      adminIntakeStep={props.adminIntakeStep}
    >
      <Container role="radiogroup" aria-labelledby="role-label">
        <label id="role-label" style={{display: "none"}}>Select one option Required</label>
        {financialAidOptions.map(option => (
          <RadioInput
            key={`financial-aid-option-${option.id}`}
            value={option.value}
            label={option.label}
            icon={option.icon}
            selected={value}
            handleOnChange={() => {
              setValue(option.value);
            }}
          />
        ))}
      </Container>
    </TravelerFormQuestionContainer>
  );
}
