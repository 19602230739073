export function getIntakeStepIndicators(intakeState, currentStep, intakeQuestions, isAdmin) {
  const activeIntakeQuestions = intakeQuestions.filter((item)=>item.enabled===true);
  const adminIntakeFormData = intakeState.adminIntakeFormData;
  return activeIntakeQuestions.map((question, index) => {
    const step = index + 1;
    let completed = false;

    switch (question.identifier) {
      case 'first_name':
        if (isAdmin) {
          completed = !!adminIntakeFormData.first_name;
        } else {
          completed = !!intakeState.first_name;
        }
        break;
      case 'last_name':
        if (isAdmin) {
          completed = !!adminIntakeFormData.last_name;
        } else {
          completed = !!intakeState.last_name;
        }
        break;
      case 'traveler_type':
        if (isAdmin) {
          completed = !!adminIntakeFormData.traveler_type;
        } else {
          completed = !!intakeState.traveler_type;
        }
        break;
      case 'home_institution':
        if (isAdmin) {
          completed = !!adminIntakeFormData.school_name;
        } else {
          completed = !!intakeState.school_name;
        }
        break;
      case 'traveler_goals':
        if (isAdmin) {
          completed = !!adminIntakeFormData.travel_goal;
        } else {
          completed = !!intakeState.travel_goals;
        }
        break;
      case 'previous_travel_experience':
        if (isAdmin) {
          completed = !!adminIntakeFormData.travel_experience;
        } else {
          completed = !!intakeState.travel_experience;
        }
        break;
      case 'passport':
        if (isAdmin) {
          completed = !!adminIntakeFormData.passport && adminIntakeFormData.passport !== 'not_provided';
        } else {
          completed = intakeState.passport.status !== 'not_provided';
        }
        break;
      case 'countries_of_citizenship':
        if (isAdmin) {
          completed = adminIntakeFormData?.countries_of_citizenship && adminIntakeFormData.countries_of_citizenship.length > 0;
        } else {
          completed = intakeState.countries_of_citizenship.length > 0;
        }
        break;
      case 'questions_or_concerns':
        if (isAdmin) {
          completed = !!adminIntakeFormData.advising;
        } else {
          completed = !!intakeState.advising;
        }
        break;
      case 'financial_information':
        if (isAdmin) {
          completed = !!adminIntakeFormData.financial_info;
        } else {
          completed = !!intakeState.financial_info;
        }
        break;
      case 'financial_aid':
        if (isAdmin) {
          completed = !!adminIntakeFormData.financial_aid && adminIntakeFormData.financial_aid !== 'not_provided';
        } else {
          completed = !!intakeState.receives_federal_financial_aid && intakeState.receives_federal_financial_aid !== 'not_provided';
        }
        break;
      case 'particiation_certainty':
        if (isAdmin) {
          completed = (!!adminIntakeFormData.travel_certainty && adminIntakeFormData.travel_certainty !== null && adminIntakeFormData.travel_certainty !== "not_provided") && adminIntakeFormData.travel_certainty !== 5;
        } else {
          completed = (intakeState.travel_certainty !== null && intakeState.travel_certainty !== "not_provided") && intakeState.travel_certainty !== 5;
        }
        break;
      case 'honors_program':
        if (isAdmin) {
          completed = !!adminIntakeFormData.honors_status;
        } else {
          completed = !!intakeState.honors_status;
        }
        break;
      case 'preferred_pronouns':
        if (isAdmin) {
          completed = !!adminIntakeFormData.pronoun;
        } else {
          completed = !!intakeState.pronoun;
        }
        break;
      case 'academic_major':
        if (isAdmin) {
          completed = !!adminIntakeFormData.primary_major;
        } else {
          completed = !!intakeState.primary_major;
        }
        break;
      case 'academic_minor':
        if (isAdmin) {
          completed = !!adminIntakeFormData.primary_minor;
        } else {
          completed = !!intakeState.primary_minor;
        }
        break;
      case 'anticipated_graduation_year':
        if (isAdmin) {
          completed = !!adminIntakeFormData.grad_year;
        } else {
          completed = !!intakeState.grad_year;
        }
        break;
      case 'cumulative_gpa':
        if (isAdmin) {
          completed = !!adminIntakeFormData.cumulative_gpa;
        } else {
          completed = !!intakeState.cumulative_gpa;
        }
        break;
      case 'year_in_school':
        if (isAdmin) {
          completed = !!adminIntakeFormData.year_in_school;
        } else {
          completed = !!intakeState.year_in_school;
        }
        break;
      case 'first_generation_status':
        if (isAdmin) {
          completed = !!adminIntakeFormData.first_generation_status;
        } else {
          completed = !!intakeState.first_generation_status;
        }
        break;
      case 'veteran_status':
        if (isAdmin) {
          completed = !!adminIntakeFormData.veteran_status;
        } else {
          completed = !!intakeState.veteran_status;
        }
        break;
      default:
        break;
    }
    
    return {
      step,
      current: currentStep === step,
      completed
    };
  });




  // const steps = [
  //   {
  //     step: 1,
  //     current: currentStep === 1,
  //     completed: !!state.first_name,
  //   },
  //   {
  //     step: 2,
  //     current: currentStep === 2,
  //     completed: !!state.last_name,
  //   },
  //   {
  //     step: 3,
  //     current: currentStep === 3,
  //     completed: !!state.traveler_type,
  //   },
  //   {
  //     step: 4,
  //     current: currentStep === 4,
  //     completed: !!state.school_name,
  //   },
  //   {
  //     step: 5,
  //     current: currentStep === 5,
  //     completed: !!state.travel_goals,
  //   },
  //   {
  //     step: 6,
  //     current: currentStep === 6,
  //     completed: !!state.travel_experience,
  //   },
  //   {
  //     step: 7,
  //     current: currentStep === 7,
  //     completed: state.passport.status !== 'not_provided',
  //   },
  //   {
  //     step: 8,
  //     current: currentStep === 8,
  //     completed: state.countries_of_citizenship.length > 0,
  //   },
  //   {
  //     step: 9,
  //     current: currentStep === 9,
  //     completed: !!state.advising,
  //   },
  //   {
  //     step: 10,
  //     current: currentStep === 10,
  //     completed: !!state.financial_info,
  //   },
  //   {
  //     step: 11,
  //     current: currentStep === 11,
  //     completed: !!state.receives_federal_financial_aid && state.receives_federal_financial_aid !== 'not_provided',
  //   },
  //   {
  //     step: 12,
  //     current: currentStep === 12,
  //     // The API returns travel_certainty: 5 by default.
  //     // Submitted values are in the range 0-4.
  //     completed: (state.travel_certainty !== null && state.travel_certainty !== "not_provided")  && state.travel_certainty !== 5,
  //   },
  //   {
  //     step: 13,
  //     current: currentStep === 13,
  //     completed: state.honur_status,
  //   },
  //   {
  //     step: 14,
  //     current: currentStep === 14,
  //     completed: state.preferred_pronouns,
  //   },
  //   {
  //     step: 15,
  //     current: currentStep === 15,
  //     completed: state.academic_major,
  //   },
  //   {
  //     step: 16,
  //     current: currentStep === 16,
  //     completed: state.academic_minor,
  //   },
  //   {
  //     step: 17,
  //     current: currentStep === 17,
  //     completed: state.anticipated_graduation_year,
  //   },
  //   {
  //     step: 18,
  //     current: currentStep === 18,
  //     completed: state.comulative_gpa,
  //   },
  //   {
  //     step: 19,
  //     current: currentStep === 19,
  //     completed: state.current_year_in_school,
  //   },
  //   {
  //     step: 20,
  //     current: currentStep === 20,
  //     completed: state.first_generation_college_student,
  //   },
  //   {
  //     step: 21,
  //     current: currentStep === 21,
  //     completed: state.veteran_status,
  //   },
  // ];

  // return steps;
}

export function getProgramMatchStepIndicators(state, currentStep) {
  const steps = [
    {
      step: 1,
      current: currentStep === 1,
      completed: state.subjectAreas.length > 0,
    },
    {
      step: 2,
      current: currentStep === 2,
      completed: state.programCountries.length > 0,
    },
    {
      step: 3,
      current: currentStep === 3,
      completed: state.programHousing.length > 0,
    },
    {
      step: 4,
      current: currentStep === 4,
      completed: !!state.programLength && !!state.programLength.minWeeks && !!state.programLength.maxWeeks,
    },
    {
      step: 5,
      current: currentStep === 5,
      completed: state.programTerms.length > 0,
    },
    {
      step: 6,
      current: currentStep === 6,
      completed: !!state.languageImmersion,
    },
    {
      step: 7,
      current: currentStep === 7,
      completed: state.programTypes.length > 0,
    },
    {
      step: 8,
      current: currentStep === 8,
      completed: state.priorities.length > 0,
    },
  ];

  return steps;
}
