import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ProfileFormContainer from '../base/ProfileFormContainer';
import ProfileFormSubheading from '../base/ProfileFormSubheading';
import ToastMessage from '../../../viaGlobal/components/toastMessage';
import CurrentAcademicInfoForm from '../forms/academic/CurrentAcademicInfoForm';
import AdditionalAcademicInfoForm from '../forms/academic/AdditionalAcademicInfoForm';
import { ProfileFormButton, ProfileFormButtonContainer } from '../base/ProfileFormButton.styled';

import userId from '../../../../shared/helpers/userId';
import { updateTravelerProfile } from '../../actions/travelerProfileActions';

export default function AcademicTab() {
  const dispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);

  const [currentInfoForm, setCurrentInfoForm] = useState({
    advisor: '',
    grad_year: '',
    student_id: '',
    school_name: '',
    hours_earned: '',
    traveler_type: '',
    cumulative_gpa: '',
    year_in_school: '',
    places_of_study: '',
  });

  const [additionalInfoForm, setAdditionalInfoForm] = useState({
    major_gpa: '',
    third_minor: '',
    honors_status: '',
    primary_major: '',
    primary_minor: '',
    judicial_status: '',
    secondary_major: '',
    secondary_minor: '',
  });

  const handleSubmit = event => {
    event.preventDefault();

    const currentInfoPayload = {
      ...currentInfoForm,
      grad_year: currentInfoForm.grad_year.value,
      places_of_study: [currentInfoForm.places_of_study],
      traveler_type: currentInfoForm.traveler_type.value,
      year_in_school: currentInfoForm.year_in_school.value,
    };

    const additionalInfoPayload = {
      ...additionalInfoForm,
      honors_status: additionalInfoForm.honors_status.value,
      first_generation_status: additionalInfoForm.first_generation_status.value,
    };

    dispatch(
      updateTravelerProfile(userId, {
        travelerProfile: { ...currentInfoPayload, ...additionalInfoPayload },
      }),
    );

    setShowToast(true);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <ProfileFormContainer heading="Academic Information">
        <div>
          <div>
            <ProfileFormSubheading text="Current Academic Information" />
            <CurrentAcademicInfoForm form={currentInfoForm} setForm={setCurrentInfoForm} />
          </div>
          <div>
            <ProfileFormSubheading text="Additional Academic Information" />
            <AdditionalAcademicInfoForm form={additionalInfoForm} setForm={setAdditionalInfoForm} />
          </div>
        </div>
        <ProfileFormButtonContainer>
          <ProfileFormButton type="submit">Save</ProfileFormButton>
        </ProfileFormButtonContainer>
        {showToast && (
          <ToastMessage
            message="All changes saved"
            isSuccess
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}
          />
        )}
      </ProfileFormContainer>
    </form>
  );
}
