import IntakeLastName from '../components/intakeForm/steps/IntakeLastName';
import IntakePassport from '../components/intakeForm/steps/IntakePassport';
import IntakeFirstName from '../components/intakeForm/steps/IntakeFirstName';
import IntakeTravelGoals from '../components/intakeForm/steps/IntakeTravelGoals';
import IntakeFinancialAid from '../components/intakeForm/steps/IntakeFinancialAid';
import IntakeTravelerType from '../components/intakeForm/steps/IntakeTravelerType';
import IntakeFinancialInfo from '../components/intakeForm/steps/IntakeFinancialInfo';
import IntakeHomeInstitution from '../components/intakeForm/steps/IntakeHomeInstitution';
import IntakePreviousExperience from '../components/intakeForm/steps/IntakePreviousExperience';
import IntakeQuestionsAndConcerns from '../components/intakeForm/steps/IntakeQuestionsAndConcerns';
import IntakeCountriesOfCitizenship from '../components/intakeForm/steps/IntakeCountriesOfCitizenship';
import IntakeParticipationCertainty from '../components/intakeForm/steps/IntakeParticipationCertainty';

// New question added
import IntakeHonorStatus from '../components/intakeForm/steps/IntakeHonorStatus';
import IntakePreferredPronouns from '../components/intakeForm/steps/IntakePreferredPronouns';
import IntakeAcedemicMajor from '../components/intakeForm/steps/IntakeAcademicMajor';
import IntakeAcedemicMinor from '../components/intakeForm/steps/IntakeAcademicMinor';
import IntakeCurrentYearInSchool from '../components/intakeForm/steps/IntakeCurrentYearInSchool';
import IntakeAnticipatedGradYear from '../components/intakeForm/steps/IntakeAnticipatedGradYear';
import IntakeComulativeGPA from '../components/intakeForm/steps/IntakeComulativeGPA';
import IntakeFirstGenerationStatus from '../components/intakeForm/steps/IntakeFirstGenerationStatus';
import IntakeVeteranStatus from '../components/intakeForm/steps/IntakeVeteranStatus';

import ProgramMatchTerm from '../components/programMatchForm/steps/ProgramMatchTerm';
import ProgramMatchType from '../components/programMatchForm/steps/ProgramMatchType';
import ProgramMatchLength from '../components/programMatchForm/steps/ProgramMatchLength';
import ProgramMatchCountry from '../components/programMatchForm/steps/ProgramMatchCountry';
import ProgramMatchHousing from '../components/programMatchForm/steps/ProgramMatchHousing';
import ProgramMatchPriorities from '../components/programMatchForm/steps/ProgramMatchPriorities';
import ProgramMatchSubjectAreas from '../components/programMatchForm/steps/ProgramMatchSubjectAreas';
import ProgramMatchLanguageImmersion from '../components/programMatchForm/steps/ProgramMatchLanguageImmersion';

export function getIntakeComponent(intakeQuestions) {
  const componentMapping = {
    first_name: IntakeFirstName,
    last_name: IntakeLastName,
    traveler_type: IntakeTravelerType,
    home_institution: IntakeHomeInstitution,
    traveler_goals: IntakeTravelGoals,
    previous_travel_experience: IntakePreviousExperience,
    passport: IntakePassport,
    countries_of_citizenship: IntakeCountriesOfCitizenship,
    questions_or_concerns: IntakeQuestionsAndConcerns,
    financial_information: IntakeFinancialInfo,
    financial_aid: IntakeFinancialAid,
    particiation_certainty: IntakeParticipationCertainty,
    honors_program: IntakeHonorStatus,
    preferred_pronouns: IntakePreferredPronouns,
    academic_major: IntakeAcedemicMajor,
    academic_minor: IntakeAcedemicMinor,
    year_in_school: IntakeCurrentYearInSchool,
    anticipated_graduation_year: IntakeAnticipatedGradYear,
    cumulative_gpa: IntakeComulativeGPA,
    first_generation_status: IntakeFirstGenerationStatus,
    veteran_status: IntakeVeteranStatus,
  };
  const activeIntakeQuestions = intakeQuestions.filter((item)=>item.enabled===true);
  const intakeObject = activeIntakeQuestions.reduce((acc, item, index) => {
    if (componentMapping[item.identifier]) {
      acc[index+1] = componentMapping[item.identifier];
    }
    return acc;
  }, {});
  return intakeObject;
}


export const intakeStepsComponentMap = {
  1: IntakeFirstName,
  2: IntakeLastName,
  3: IntakeTravelerType,
  4: IntakeHomeInstitution,
  5: IntakeTravelGoals,
  6: IntakePreviousExperience,
  7: IntakePassport,
  8: IntakeCountriesOfCitizenship,
  9: IntakeQuestionsAndConcerns,
  10: IntakeFinancialInfo,
  11: IntakeFinancialAid,
  12: IntakeParticipationCertainty,
  13: IntakeHonorStatus,
  14: IntakePreferredPronouns,
  15: IntakeAcedemicMajor,
  16: IntakeAcedemicMinor,
  17: IntakeAnticipatedGradYear,
  18: IntakeComulativeGPA,
  19: IntakeCurrentYearInSchool,
  20: IntakeFirstGenerationStatus,
  21: IntakeVeteranStatus,

};

export function getIntakeSteps(intakeQuestions) {
  const activeIntakeQuestions = intakeQuestions.filter((item)=>item.enabled===true);
  const intakeObject = activeIntakeQuestions.reduce((acc, item, index) => {
    acc[index+1] = item.name;
    return acc;
  }, {});
  return intakeObject;
}


export const intakeSteps = {
  1: "First Name",
  2: "Last Name",
  3: "Traveler Type",
  4: "Home Institution",
  5: "Travel Goals",
  6: "Previous Experience",
  7: "Passport",
  8: "Countries Of Citizenship",
  9: "Questions And Concerns",
  10: "Financial Info",
  11: "Financial Aid",
  12: "Participation Certainty",
  13: "Honors Program",
  14: "Preferred Pronouns",
  15: "Academic Major",
  16: "Academic Minor",
  17: "Anticipated Graduation Year",
  18: "Comulative GPA",
  19: "Current Year in School",
  20: "First-Generation College Student",
  21: "Veteran Status",
};


export const programMatchStepsComponentMap = {
  1: ProgramMatchSubjectAreas,
  2: ProgramMatchCountry,
  3: ProgramMatchHousing,
  4: ProgramMatchLength,
  5: ProgramMatchTerm,
  6: ProgramMatchLanguageImmersion,
  7: ProgramMatchType,
  8: ProgramMatchPriorities,
};

export const programMatchSteps = {
  1: "Program Match Subject Areas",
  2: "Program Match Country",
  3: "Program Match Housing",
  4: "Program Match Length",
  5: "Program Match Term",
  6: "Program Match Language Immersion",
  7: "Program Match Type",
  8: "Program Match Priorities",
};