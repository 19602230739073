import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import LoadingScreen from  '../../../styledComponents/styles/LoadingScreen';
import token from '../../../shared/helpers/token';
import userId from '../../../shared/helpers/userId';
import loadedFonts from '../../../shared/fonts/loadFonts.css';
import { themeVia } from '../../../styledComponents/settings/theme';
import { getTravelerEvents } from '../actions/travelerEventsActions';
import { useThemeColors } from '../../../shared/hooks/useThemeColors';
import GlobalStyles from '../../../styledComponents/styles/GlobalStyles';
import { getCurrentOrganization } from '../actions/currentOrganizationActions';
import { getCurrentUser, getTravelerProfile,getInboundOrganization } from '../actions/travelerProfileActions';
import { getUserDetails } from '../../../actions/profileActions';

function Layout({ children, allowNoUser }) {
  const dispatch = useDispatch();
  const isBrandingSet = useThemeColors();
  const { travelerProfileLoading } = useSelector(state => state.travelerProfile);
  const { travelerEventsLoading, events } = useSelector(state => state.travelerEvents);
  const { currentOrganizationLoading } = useSelector(state => state.currentOrganization);
  const { clientFeatureList } = useSelector(state => state.profile);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unmounted = false;

    if (!userId && !allowNoUser) {
     window.location.assign('/clear_cookies');
    }

    if (!unmounted && token && userId) {
      dispatch(getTravelerProfile(userId, 'traveler'));
      dispatch(getCurrentOrganization());
      dispatch(getCurrentUser(userId));
      dispatch(getUserDetails(userId));
      
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(()=>{
    let unmounted = false;
    if(!unmounted && token && userId && clientFeatureList?.inbound) {
      dispatch(getInboundOrganization(userId));
    }
    return () => {
      unmounted = true;
    };
  },[clientFeatureList]) 


  useEffect(
    () => {
      if (window.location.pathname.includes('events') && events.length === 0) {
        dispatch(getTravelerEvents(userId));
      }
    },
    [window.location.pathname],
  );

  useEffect(
    () => {
      const authLoadingCheck = token && userId;
      const baseInfoLoadingCheck = travelerProfileLoading || currentOrganizationLoading;

      if (window.location.pathname.includes('events')) {
        setLoading(authLoadingCheck ? baseInfoLoadingCheck || travelerEventsLoading : false);

        return;
      }

      setLoading(authLoadingCheck ? baseInfoLoadingCheck : false);
    },
    [travelerProfileLoading, currentOrganizationLoading, travelerEventsLoading, window.location.pathname],
  );

  return (
    <ThemeProvider theme={themeVia}>
      <GlobalStyles />
      <div>{isBrandingSet && !loading ? children : <LoadingScreen displayText='Loading...' />}</div>
    </ThemeProvider>
  );
}

export default compose(withStyles(loadedFonts))(Layout);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  allowNoUser: PropTypes.bool,
};
