import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import TravelerFormFooter from './TravelerFormFooter';
import TravelerFormStepper from './TravelerFormStepper';

import history from '../../../../history';
import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import SkipLink from '../../../travelerProfile/components/navigation/SkipLink';

const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  min-height: ${props => props.isAdmin ? '' : '100vh'};

  overflow: ${props => props.isAdmin ? 'hidden' : ''};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-top: 1.85125rem;
  padding-left: 2.625rem;
  padding-right: 2.625rem;
  
  @media ${breakpoints.tablet} {
    padding-top: 4.75rem;
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  @media ${breakpoints.desktop} {
    padding-top: ${props => props.isAdmin ? '2.78875rem' : '4.78875rem'} ;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: ${props => props.isAdmin ? '4.78875rem' : ''};
  }
`;

const CloseContainer = styled.div`
  align-self: end;

  button {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 2px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    color: #474747;
    line-height: 120%;
    letter-spacing: 0.004em;
    text-transform: uppercase;

    span {
      // display: none;
      margin-right:5px;
    }

    @media ${breakpoints.tablet} {
      margin-right: 3.3rem;

      span {
        display: inline;
        margin-right: 0.97625rem;
      }
    }

    @media ${breakpoints.desktop} {
      margin-right: 8.10125rem;
    }

    ${buttonFocus};
  }
`;

export default function TravelerFormContainer({ children, step, isAdmin }) {
  const { show_program_match: showProgramMatch, program_match_complete: programMatchComplete } = useSelector(
    state => state.profile,
  );

  const forProgramMatch = history.location.pathname.includes('/traveler/program-match');

  const handleClose = () => {
    if (document.referrer.includes('/traveler?tab=preferences')) {
      history.push('/traveler/program-dashboard-v2');
    } else if (forProgramMatch) {
      history.push('/traveler/program-dashboard-v2');
      return;
    }

    if (showProgramMatch && !programMatchComplete) {
      history.push('/traveler/program-match');
    } else if (document.referrer.includes('/traveler?tab=preferences')) {
      history.push('/traveler/program-dashboard-v2');
    } else {
      history.push('/traveler/program-dashboard-v2');
    }
  };

  return (
    <>
    {!isAdmin && 
      <SkipLink id="step-form-container" />
    }
    <Container isAdmin={isAdmin}>
      <Content>
        {!isAdmin &&  
          <CloseContainer>
            <button type="button" aria-label={forProgramMatch ? "Close program match process" : "Close onboarding process"} onClick={handleClose}>
              <span>Close</span>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.59094 7L13.0441 2.54687C13.2554 2.3359 13.3743 2.04962 13.3745 1.75099C13.3748 1.45237 13.2564 1.16587 13.0455 0.95453C12.8345 0.743185 12.5482 0.624305 12.2496 0.624041C11.951 0.623778 11.6645 0.742152 11.4531 0.953123L7 5.40625L2.54687 0.953123C2.33553 0.741779 2.04888 0.623047 1.75 0.623047C1.45111 0.623047 1.16447 0.741779 0.953123 0.953123C0.741779 1.16447 0.623047 1.45111 0.623047 1.75C0.623047 2.04888 0.741779 2.33553 0.953123 2.54687L5.40625 7L0.953123 11.4531C0.741779 11.6645 0.623047 11.9511 0.623047 12.25C0.623047 12.5489 0.741779 12.8355 0.953123 13.0469C1.16447 13.2582 1.45111 13.3769 1.75 13.3769C2.04888 13.3769 2.33553 13.2582 2.54687 13.0469L7 8.59375L11.4531 13.0469C11.6645 13.2582 11.9511 13.3769 12.25 13.3769C12.5489 13.3769 12.8355 13.2582 13.0469 13.0469C13.2582 12.8355 13.3769 12.5489 13.3769 12.25C13.3769 11.9511 13.2582 11.6645 13.0469 11.4531L8.59094 7Z"
                  fill="#373737"
                />
              </svg>
            </button>
          </CloseContainer>
        }
        {!!step && <TravelerFormStepper isAdmin={isAdmin} step={step} forProgramMatch={forProgramMatch} />}
        <div id="step-form-container">
        {children}
        </div>
      </Content>
      <TravelerFormFooter useBackgroundOpacity={!!step} />
    </Container>
    </>
  );
}

TravelerFormContainer.propTypes = {
  step: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TravelerFormContainer.defaultProps = {
  step: '',
};
